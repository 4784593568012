import React               from "react";
import { Route, Routes }   from "react-router-dom";
import { OrdersProvider }  from "~/contexts/OrdersContext";
import { OrderEditScreen } from "~/screens/OrderEditScreen/index.web";
import { OrdersScreen }    from "~/screens/OrdersScreen/index.web";

export const OrdersNavigator = () => {
  return (
    <OrdersProvider>
      <Routes>
        <Route index element={<OrdersScreen />} />
        <Route path="/:order_id/*" element={<OrderEditScreen />} />
      </Routes>
    </OrdersProvider>
  );
};
