import React, { useCallback, useContext } from "react";
import { View, StyleSheet }               from "react-native";
import { Screen }                         from "~/components/layouts/Screen/index.web";
import { ContactsTable }                  from "~/components/contacts/ContactsTable/index.web";
import { Button, ButtonGroup, Row, Col }  from "react-bootstrap";
import { ContactCreateModal }             from "~/components/contacts/ContactCreateModal/index.web";
import { ContactsContext }                from "~/contexts/ContactsContext";
import { ContactsTypeAhead }              from "~/components/contacts/ContactsTypeAhead/index.web";
import { useNavigate }                    from "react-router-dom";

export const ContactsScreen = ({ style = {} }) => {
  const navigate = useNavigate();
  return (
    <Screen noPadding fluid>
      <View style={[styles.container]}>
        <ContactsBar />
        <ContactsTable />
        <ContactCreateModal open onClose={() => navigate("/contacts")} />
      </View>
    </Screen>
  );
};

const ContactsBar = () => {
  const navigate = useNavigate();

  const onTypeAheadSelect = useCallback(
    (contact) => {
      navigate(`/contacts/${contact?.id}`, { state: { contact } });
    },
    [navigate]
  );

  return (
    <>
      <div className="row pb-3">
        <div className="col col-8">
          <ContactsFilterBar />
        </div>
      </div>
      <div className="row pb-3">
        <div className="col col-sm-12 col-md-4 ">
          <ContactsTypeAhead
            onChange={onTypeAheadSelect}
            placeholder="Search contacts by name"
          />
        </div>
        <div className="col text-right">
          <Button
            size="sm"
            onClick={() => navigate("/contacts?modal=new-contact")}
          >
            + New Contact
          </Button>
        </div>
      </div>
    </>
  );
};

const ContactsFilterBar = () => {
  const { filter, setFilter, getContacts } = useContext(ContactsContext);

  const onToggle = useCallback(
    (f) => () => {
      setFilter(f);
      getContacts({ filter: f });
    },
    [setFilter, getContacts]
  );

  return (
    <div>
      <Row className="pl-2">
        <ButtonGroup className="ml-2">
          <Button
            size="sm"
            onClick={onToggle("all")}
            variant={filter == "all" ? "primary" : " btn-outline-secondary"}
          >
            All
          </Button>
          <Button
            size="sm"
            onClick={onToggle("new")}
            variant={filter == "new" ? "primary" : " btn-outline-secondary"}
          >
            New Leads
          </Button>
          <Button
            size="sm"
            onClick={onToggle("pending_quotes")}
            variant={
              filter == "pending_quotes" ? "primary" : " btn-outline-secondary"
            }
          >
            Pending Quotes
          </Button>
          <Button
            size="sm"
            onClick={onToggle("pending_orders")}
            variant={
              filter == "pending_orders" ? "primary" : " btn-outline-secondary"
            }
          >
            Pending Orders
          </Button>
        </ButtonGroup>
      </Row>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
});
