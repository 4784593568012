import React, { useContext } from "react";
import { QuotesList }        from "~/components/quotes/QuotesList/index.web";
import { QuotesTable }       from "~/components/quotes/QuotesTable/index.web";
import { PartContext }       from "~/contexts/PartContext";
import { QuotesProvider }    from "~/contexts/QuotesContext";

export function PartQuotesTab() {
  const { part, loading } = useContext(PartContext);
  return (
    <QuotesProvider>
      <div className="part-quotes-tab">
        <QuotesTable product_id={part?.id} />
      </div>
    </QuotesProvider>
  );
}
