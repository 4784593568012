import axios                                     from "../axios";
import { responseHandler, errorHandler }         from "../../utils/handlers";

import { MANUFACTURERS_URI }                     from "../../constants/uri";
import { PAGINATION }                            from "../../constants/query";
import { buildParams }                           from "../../utils/params";

export async function getManufacturers(params) {
  const {
    page = 1,
    per_page = 50,
    sort = "name",
    order = "asc",
    term = "",
  } = params || PAGINATION;

  const res = await axios
    .request(
      `${MANUFACTURERS_URI}?${buildParams({
        page,
        per_page,
        sort,
        order,
        term,
      })}`,
      {
        method: "GET",
      }
    )
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}
