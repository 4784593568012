import React, { useContext } from "react";
import { QuoteContext }      from "~/contexts/QuoteContext";

const STATUS = [
  {
    value: "draft",
    label: "Draft",
  },
  {
    value: "open",
    label: "Open",
  },
  {
    value: "voided",
    label: "Voided",
  },
  {
    value: "sent",
    label: "Sent",
  },
  {
    value: "viewed",
    label: "Viewed",
  },
  {
    value: "accepted",
    label: "Accepted",
  },
];

export const QuoteStatus = () => {
  const { quote, dispatch } = useContext(QuoteContext);

  const onChange = ({ currentTarget: { value } }) => {
    dispatch({
      type: "STATUS_UPDATED",
      payload: value,
    });
  };

  return (
    <select
      className="form-control d-inline-block"
      value={quote?.attributes?.status}
      onChange={onChange}
    >
      <option>Choose invoice status</option>
      {STATUS.map((status, x) => (
        <option key={x} value={status.value}>
          {status.label}
        </option>
      ))}
    </select>
  );
};
