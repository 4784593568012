import React                 from "react";
import { Row, Col, Badge }   from "react-bootstrap";
import { ActivityIndicator } from "react-native-web";
import { dayjs }             from "~/helpers/day";

const STATUS_COLORS = {
  accepted:  'info',
  voided: "danger",
  opened: "secondary light",
  sent: "info  light",
  draft: "secondary",
  refunded: "danger",
  shipped: "success light",
  paid: "success",
  partially_shipped: "warning"
}

export const QuotesList = ({ data = [], loading=false }) => {
 
  if (!data?.length) {
    return (
      <div className="quotes-list py-5 flex-align-center">
        {loading ? (
          <ActivityIndicator />
        ) : (
          <span className="text-dark">No records found.</span>
        )}
      </div>
    );
  }

  return (
    <div className="quotes-list py-2">
      <QuotesListHeader />
      {data?.map((q, i) => {
        const quote = { ...q, ...q.attributes };
        return (
          <div
            className="quote-item px-4 py-4 border-bottom c-pointer  bg-white"
            key={quote.id}
          >
            <Row>
              <Col>
                <span className="text-bold text-md">{quote.quote_no}</span>
              </Col>
              <Col>
                <span className="text-bold text-md">
                  {quote.contact?.full_name}
                </span>
              </Col>
              <Col>
                <span className="text-bold text-md">
                  {quote.author?.full_name}
                </span>
              </Col>
              <Col className="text-center">
                <Badge variant={STATUS_COLORS[quote.status.value]}>{quote.status?.label}</Badge>
              </Col>
              <Col className="text-center">
                {quote.total_paid && (
                  <span className="text-bold text-success text-md">
                    ${parseFloat(quote.total_paid)?.toLocaleString()}
                  </span>
                )}
              </Col>
              <Col className="text-center">
                {quote.balance && (
                  <span className="text-bold text-danger text-md">
                    ${parseFloat(quote.balance)?.toLocaleString()}
                  </span>
                )}
              </Col>
              <Col className="text-center">
                {quote.total_due && (
                  <span className="text-bold text-md">
                    ${parseFloat(quote.total_due)?.toLocaleString()}
                  </span>
                )}
              </Col>
              <Col className="text-right text-md text-bold">
                <span>{dayjs(quote.updated_at).format("LL hh:mm a")}</span>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

const QuotesListHeader = () => {
  return (
    <div className="quote-list-header px-4 py-2 border-bottom bg-primary text-white">
      <Row>
        <Col>
          <span className="text-bold text-md">Quote #</span>
        </Col>
        <Col>
          <span className="text-bold text-md">Contact</span>
        </Col>
        <Col>
          <span className="text-bold text-md">Created By</span>
        </Col>
        <Col className="text-center">
          <span className="text-bold text-md">Status</span>
        </Col>
        <Col className="text-center">
          <span className="text-bold text-md">Total Paid</span>
        </Col>
        <Col className="text-center">
          <span className="text-bold text-md">Balance</span>
        </Col>
        <Col className="text-center">
          <span className="text-bold text-md">Total Due</span>
        </Col>
        <Col className="text-right text-bold text-md">
          <span>Updated</span>
        </Col>
      </Row>
    </div>
  );
};
