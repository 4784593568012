import React, { useContext } from "react";
import { OrderContext }      from "~/contexts/OrderContext";

const STATUS = [
  {
    value: "draft",
    label: "Draft",
  },
  {
    value: "open",
    label: "Open",
  },
  {
    value: "sent",
    label: "Sent",
  },
  {
    value: "accepted",
    label: "Accepted",
  },
  {
    value: "partially_shipped",
    label: "Partially Shipped",
  },
  {
    value: "shipped",
    label: "Shipped",
  },
  {
    value: "paid",
    label: "Paid",
  },
  {
    value: "refunded",
    label: "Refunded",
  },
  {
    value: "voided",
    label: "Voided",
  },
];

export const OrderStatus = () => {
  const { order, dispatch } = useContext(OrderContext);

  const onChange = ({ currentTarget: { value } }) => {
    dispatch({
      type: "STATUS_UPDATED",
      payload: value,
    });
  };

  return (
    <select
      className="form-control"
      value={order?.attributes?.status}
      onChange={onChange}
    >
      <option>Choose invoice status</option>
      {STATUS.map((status, x) => (
        <option key={x} value={status.value}>
          {status.label}
        </option>
      ))}
    </select>
  );
};
