import React               from "react";
import { Route, Routes }   from "react-router-dom";
import { DashboardScreen } from "~/screens/DashboardScreen/index.web";

export const DashboardNavigator = () => {
  return (
    <Routes>
      <Route index element={<DashboardScreen />} />
    </Routes>
  );
}
