import React, { useCallback, useContext } from "react";
import { useLocation, useNavigate }       from "react-router-dom";
import { PurchaseOrdersModal }            from "~/components/purchase_orders/PurchaseOrderModal/index.web";
import { PurchaseOrdersTable }            from "~/components/purchase_orders/PurchaseOrdersTable/index.web";
import { PartContext }                    from "~/contexts/PartContext";
import { PurchaseOrderProvider }          from "~/contexts/PurchaseOrderContext";
import { PurchaseOrdersProvider }         from "~/contexts/PurchaseOrdersContext";
import { QueryRoute }                     from "~/routers/QueryRoute/index.web";

export function PartPurchaseOrdersTab() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { part, loading } = useContext(PartContext);

  const onRowClickHandler = useCallback(
    (purchaseOrder) => {
      navigate(`/parts/${part?.id}?modal=edit-purchase-order`, {
        state: {
          purchaseOrder,
        },
      });
    },
    [navigate]
  );

  return (
    <PurchaseOrdersProvider>
      <div className="part-orders-tab">
        <PurchaseOrdersTable
          onRowClick={onRowClickHandler}
          product_id={part?.id}
        />
      </div>
      <QueryRoute queryKey="modal" queryValue="edit-purchase-order">
        <PurchaseOrderProvider
          value={{
            ...state?.purchaseOrder,
          }}
        >
          <PurchaseOrdersModal
            title="Edit Purchase Order"
            onToggle={() => navigate(-1)}
          />
        </PurchaseOrderProvider>
      </QueryRoute>
    </PurchaseOrdersProvider>
  );
}
