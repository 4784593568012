import React, { useCallback, useState } from "react";
import { Button }                       from "react-bootstrap";
import { ActivityIndicator }            from "react-native";
import styled                           from "styled-components";

const BottomBar = styled.div`
  position: absolute;
  padding: 8px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 54px;
  z-index: 50;

  &.bottom-fixed {
    position: fixed !important;
  }

  .loader {
    width: 50px;
  }

  @media print {
    display: none;
  }
`;

export const SaveFooterBar = ({ onSubmit, fixed = true, visible = false }) => {
  const [loading, setLoading] = useState(false);

  const onSubmitHandler = useCallback(async () => {
    if (onSubmit && !loading) {
      setLoading(true);
      await onSubmit();
      setLoading(false);
    }
  }, [loading, setLoading, onSubmit]);

  if (!visible) {
    return null;
  }

  return (
    <BottomBar className={`${fixed ? "bottom-fixed" : ""}`}>
      {loading ? (
        <div className="loader">
          <ActivityIndicator size="small" color="#aaa" />
        </div>
      ) : (
        <Button size="sm" variant="success"  onClick={onSubmitHandler}>
          Save
        </Button>
      )}
    </BottomBar>
  );
};
