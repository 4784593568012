import axios                             from "../axios";
import { responseHandler, errorHandler } from "../../utils/handlers";
import { USERS_URI }                     from "../../constants/uri";

export async function registerUser(user) {
  const res = await axios
    .request(`${USERS_URI}`, {
      method: "POST",
      data: user,
    })
    .then(responseHandler)
    .catch(errorHandler);

  return res;
}

export async function getUser(id = "me") {
  const res = await axios
    .request(`${USERS_URI}/${id}`, {
      method: "GET",
    })
    .then(responseHandler)
    .catch(errorHandler);

  return res;
}

export async function checkUsernameAvailability({ username = "", email = "" }) {
  const res = await axios
    .request(`/v1/validate`, {
      method: "POST",
      data: {
        email,
        name: username,
      },
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}
