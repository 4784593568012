const initialState = {
  id: null,
  type: "quote",
  attributes: {
    author: null,
    contact: null,
    balance: 0,
    shipping_fees: 0,
    subtotal: 0,
    tax_rate: 0,
    total_due: 0,
    // total_paid: 0,
    quote_items: [],
    quote_no: "",
    shipping_addr_state: "",
    shipping_addr_city: "",
    shipping_addr_street: "",
    shipping_addr_suite: "",
    shipping_addr_zip_code: "",
    shipping_address: "",
    notes: "",
    terms: "",
    status: "draft",
    created_at: null,
    updated_at: null,
  },
};

export const quoteReducer = (state = initialState, action = {}) => {
  const { type, payload, internalId } = action || {};

  if (type) {
    console.log(`[quoteReducer:${type}]`, payload);
  }

  switch (type) {
    case "QUOTE_FETCH_SUCCESS": {
      return {
        ...state,
        ...payload,
        dirty: false,
      };
    }

    case "QUOTE_SAVE_SUCCESS": {
      return {
        ...state,
        ...payload,
        dirty: false,
      };
    }

    case "QUOTE_SAVE_FAILED": {
      return {
        ...state,
        ...payload,
        dirty: true,
      };
    }

    case "STATUS_UPDATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          status: payload,
        },
        dirty: true,
      };
    }

    case "NOTES_UPDATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          notes: payload,
        },
        dirty: true,
      };
    }

    case "TERMS_UPDATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          terms: payload,
        },
        dirty: true,
      };
    }

    case "ADDRESS_UPDATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          ...payload,
        },
        dirty: true,
      };
    }

    case "TAX_UPDATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          tax_rate: payload || 0,
        },
        dirty: true,
      };
    }

    case "ITEM_ADDED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          quote_items: [...state?.attributes?.quote_items, payload],
        },
        dirty: true,
      };
    }

    case "ITEM_DELETED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          quote_items: state?.attributes.quote_items.filter(
            (item) => item.id !== payload.id
          ),
        },
        // dirty: state?.id ? false : true,
      };
    }

    case "ITEM_UPDATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          quote_items: state?.attributes.quote_items.map((item) => {
            if (item.id === payload.id) {
              return {
                ...item,
                ...payload,
              };
            } else {
              return item;
            }
          }),
        },
        dirty: true,
      };
    }

    case "ITEM_ADD_SUCCESS": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          quote_items: state?.attributes.quote_items.map((item) => {
            if (item.id === internalId) {
              const { pending, saving, id, ...itemData } = item;
              return {
                ...itemData,
                ...payload,
              };
            } else {
              return item;
            }
          }),
        },
      };
    }

    case "ITEM_UPDATE_SUCCESS": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          quote_items: state?.attributes.quote_items.map((item) => {
            if (item.id === payload.id) {
              const { saving, id, ...itemData } = item;
              return {
                ...itemData,
                ...payload,
              };
            } else {
              return item;
            }
          }),
        },
      };
    }

    case "ITEM_UPDATE_FAILED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          quote_items: state?.attributes.quote_items.map((item) => {
            if (item.id === payload.id) {
              const { saving, id, ...itemData } = item;
              return {
                ...itemData,
                ...payload,
              };
            } else {
              return item;
            }
          }),
        },
      };
    }

    default: {
      return state;
    }
  }
};
