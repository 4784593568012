import React, { useCallback, useContext }  from "react";
import styled                              from "styled-components";

import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { Screen }                          from "~/components/layouts/Screen/index.web";
import { ContactTabs }                     from "~/components/contacts/ContactTabs/index.web";
import { ContactContext, ContactProvider } from "~/contexts/ContactContext";
import { ContactDetails }                  from "~/components/contacts/ContactDetails/index.web";

export const ContactsEditScreen = ({ style = {} }) => {
  const { state, ...rest } = useLocation();
  const { contact_id } = useParams();

  return (
    <Screen fluid noPadding>
      <ContactProvider
        value={{
          id: contact_id,
          ...state?.contact,
        }}
      >
        <ContactContaner>
          <ContactView />
        </ContactContaner>
      </ContactProvider>
    </Screen>
  );
};

const ContactContaner = styled.div`
  padding: 16px;
  height: 100%;
  // background: white;
`;

export const ContactNav = () => {
  const { contact } = useContext(ContactContext);

  return (
    <div className="mb-3 fs-12">
      <Link to="/contacts" className="text-primary">
        Contacts
      </Link>
      <span className="px-2">{">"}</span>
      <span className="text-bold">{contact?.attributes?.full_name}</span>
    </div>
  );
};

const ContactView = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onTabChange = useCallback(
    (t) => {
      setSearchParams({ ...searchParams, t });
    },
    [setSearchParams, searchParams]
  );

  return (
    <div>
      <ContactNav />
      <ContactDetails />
      <ContactTabs
        onTabChange={onTabChange}
        activeKey={searchParams.get("t") || "details"}
      />
    </div>
  );
};
