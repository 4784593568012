import React, {
  useEffect,
  createContext,
  useState,
  useCallback,
} from "react";
import { toast }      from "react-toastify";
import { ProcessCRM } from "~/services";

const DEFAULT = { id: null, type: "purchase_order", attributes: {} };

export const PurchaseOrderContext = createContext({
  purchaseOrder: DEFAULT,
});

export const PurchaseOrderProvider = ({ children, value = DEFAULT }) => {
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState(value);

  const getPurchaseOrder = useCallback(
    async (id) => {
      if (loading) return;
      setLoading(true);
      const res = await ProcessCRM.getPurchaseOrder(id);
      if (!res.error && res.data) {
        setPurchaseOrder({ ...res.data });
      } else if (res?.error && res?.message) {
        toast.error(res.message);
      }
      setLoading(false);
      return res;
    },
    [loading, setLoading, setPurchaseOrder]
  );

  const createPurchaseOrder = useCallback(
    async (data) => {
      if (saving) return;
      setSaving(true);
      const res = await ProcessCRM.createPurchaseOrder(data);
      if (res?.data?.id && !res.error) {
        setPurchaseOrder(res.data);
      }
      setSaving(false);
      return res;
    },
    [saving, setSaving, setPurchaseOrder]
  );

  const updatePurchaseOrder = useCallback(
    (data) => {
      setPurchaseOrder({
        ...purchaseOrder,
        attributes: {
          ...purchaseOrder.attributes,
          ...data,
        },
      });
    },
    [purchaseOrder, setPurchaseOrder]
  );

  const savePurchaseOrder = useCallback(
    async (data) => {
      if (saving) return;
      setSaving(true);
      const res = await ProcessCRM.updatePurchaseOrder(purchaseOrder?.id, data);
      if (res?.data?.id && !res.error) {
        setPurchaseOrder(res?.data);
      } else if (res?.error && res?.message) {
        toast.error(res.message);
      }
      setSaving(false);
      return res;
    },
    [saving, setSaving, setPurchaseOrder]
  );

  const deletePurchaseOrder = useCallback(
    async (id) => {
      if (loading) return;
      setLoading(true);
      const res = await ProcessCRM.deletePurchaseOrder(id);
      if (res?.success) {
        setPurchaseOrder(null);
      } else if (res?.error && res?.message) {
        toast.error(res.message);
      }
      setLoading(false);
      return res;
    },
    [loading, setLoading, setPurchaseOrder]
  );

  useEffect(() => {
    if (purchaseOrder?.id) {
      getPurchaseOrder(purchaseOrder?.id);
    }
  }, [purchaseOrder?.id]);

  return (
    <PurchaseOrderContext.Provider
      value={{
        saving,
        loading,
        purchaseOrder,
        getPurchaseOrder,
        setPurchaseOrder,
        createPurchaseOrder,
        updatePurchaseOrder,
        savePurchaseOrder,
        deletePurchaseOrder,
      }}
    >
      {children}
    </PurchaseOrderContext.Provider>
  );
};
