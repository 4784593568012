import React, { useContext, useEffect } from "react";
import { StyleSheet }                   from "react-native";
import { useLocation, useNavigate }     from "react-router-dom";

import { LIGHT }                        from "~/themes/colors";
import { DataTableBase }                from "~/components/tables/DataTableBase/index.web";

import { dayjs }                        from "~/helpers/day";
import { toDollars }                    from "~/helpers/currency";
import { PurchaseOrdersContext }        from "~/contexts/PurchaseOrdersContext";

export const PurchaseOrdersTable = ({
  style = {},
  onRowClick,
  order_id,
  product_id,
}) => {
  const { key } = useLocation();

  const {
    loading,
    total,
    per_page,
    page,
    order,
    sort,
    purchaseOrders,
    getPurchaseOrders,
  } = useContext(PurchaseOrdersContext);

  useEffect(() => {
    getPurchaseOrders({
      page,
      per_page,
      sort,
      order,
      order_id,
      product_id,
    });
  }, [key]);

  return (
    <div className=" mb-4">
      <DataTableBase
        heightOffset={150}
        sortServer
        progressPending={loading}
        pagination={total > per_page}
        paginationDefaultPage={page}
        paginationTotalRows={total}
        paginationPerPage={per_page}
        persistTableHead
        defaultSortField={sort}
        defaultSortAsc={order !== "asc" || order !== "ascending"}
        columns={OrdersTableColumns}
        data={purchaseOrders}
        onSort={(column, order) =>
          getPurchaseOrders({
            page: 1,
            sort: column.id,
            order,
            order_id,
            product_id,
          })
        }
        onChangeRowsPerPage={(per_page, current_page) =>
          getPurchaseOrders({
            per_page,
            page: current_page,
            order,
            sort,
            order_id,
            product_id,
          })
        }
        onChangePage={(current_page) => {
          if (current_page !== page) {
            getOrders({
              per_page,
              page: current_page,
              order,
              sort,
              quote_id,
              contact_id,
              product_id,
            });
          }
        }}
        onRowClicked={onRowClick}
      />
    </div>
  );
};

export const OrdersTableColumns = [
  {
    id: "ref_id",
    name: "Ref #",
    selector: (row) => row.attributes?.ref_id,
    cell: (row, index, c) => (
      <div>
        <span data-tag="allowRowEvents" className="mb-0">
          {row.attributes?.ref_id || "-"}
        </span>
      </div>
    ),
  },
  // {
  //   id: "product_id",
  //   name: "Part #",
  //   selector: (row) => row.attributes?.product?.part_number,
  //   sortable: true,
  //   cell: (row, index, c) => (
  //     <div>
  //       <span data-tag="allowRowEvents" className="mb-0">
  //         {row.attributes?.product?.part_number || "-"}
  //       </span>
  //     </div>
  //   ),
  // },
  {
    id: "tracking_number",
    name: "Tracking #",
    selector: (row) => row.attributes?.tracking_number,
    cell: (row, index, c) => (
      <div>
        <span data-tag="allowRowEvents" className="mb-0">
          {row.attributes?.tracking_number || "-"}
        </span>
      </div>
    ),
  },
  {
    id: "quantity",
    name: "Qty",
    selector: (row) => row.attributes?.quantity,
    cell: (row, index, c) => (
      <div>
        <span data-tag="allowRowEvents" className="mb-0">
          {row.attributes?.quantity || "-"}
        </span>
      </div>
    ),
  },
  {
    id: "price",
    name: "Unit Cost",
    selector: (row) => row.attributes?.price,
    cell: (row, index, c) => (
      <div>
        <span data-tag="allowRowEvents" className="mb-0">
          {toDollars(row.attributes?.price) || "-"}
        </span>
      </div>
    ),
  },
  {
    id: "status",
    name: "Status",
    selector: (row) => row.attributes?.status,
    sortable: true,
    cell: (row, index, c) => (
      <div>
        <span data-tag="allowRowEvents" className="mb-0">
          {row.attributes?.status_label || "-"}
        </span>
      </div>
    ),
  },
  {
    id: "total",
    name: "Total",
    selector: (row) => row.attributes?.total,
    sortable: true,
    cell: (row, index, c) => (
      <div>
        <span data-tag="allowRowEvents" className="mb-0 text-bold">
          {toDollars(c.selector(row)) || "-"}
        </span>
      </div>
    ),
  },
  {
    id: "updated_at",
    name: "Updated",
    selector: (row) => row.attributes?.updated_at,
    sortable: true,
    cell: (row, index, c) => (
      <div>
        <span data-tag="allowRowEvents" className="mb-0">
          {dayjs(c.selector(row)).format("MM-DD-YY") || "-"}
        </span>
      </div>
    ),
  },
];

const styles = StyleSheet.create({
  container: {},
  nameStyledText: {
    color: LIGHT.black,
    fontWeight: 500,
  },
  descriptionStyledText: {
    color: LIGHT.grey,
  },
  cellStyledText: {
    color: LIGHT.black,
  },
});
