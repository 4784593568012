import { createClient } from "../utils/axios";
import { BASE_URI }     from "../constants/uri";
// import Cookies          from "js-cookie";

const axiosClient = createClient({
  baseUrl: BASE_URI[process.env.APP_ENV],
  withCreds: true,
});

axiosClient.interceptors.request.use(function (config) {
  if (process.env.NODE_ENV !== "production") {
    const requestLog = `${config.method} ${config.baseURL}${config.url}`;
    console.debug(requestLog);
  }
  // Work around since axios doesnt set content-type if data is undefined
  config.headers["Accept"] = "application/json";
  config.headers["Content-Type"] = "application/json";
  if (!config?.data) {
    config.data = {};
  }

  // Do something before request is sent
  return config;
});

// axiosClient.interceptors.response.use(function (response) {
//   if(response.status ===  401){
//     delete axiosClient.defaults.headers.common["access-token"]
//     delete axiosClient.defaults.headers.common["token-type"]
//     delete axiosClient.defaults.headers.common["uid"]
//     delete axiosClient.defaults.headers.common["client"]
//     delete axiosClient.defaults.headers.common["expiry"]
//     Cookies.remove("authHeaders");
//   }
//   if (response.headers["access-token"]) {
//     const creds = {
//       "access-token": response.headers["access-token"],
//       "token-type": response.headers["token-type"],
//       uid: response.headers["uid"],
//       client: response.headers["client"],
//       expiry: response.headers["expiry"],
//     };

//     if(!Cookies.get("authHeaders")){
//       Cookies.set("authHeaders", JSON.stringify(creds));
//     }

//     for (const key in creds) {
//       if (Object.hasOwnProperty.call(creds, key)) {
//         axiosClient.defaults.headers.common[key] = creds[key];
//       }
//     }
//   }
//   // Do something before request is sent
//   return response;
// });

export default axiosClient;