import { useContext }   from "react";
import { useLocation }  from "react-router-dom";

import { ROOT_NAV }     from "../constants/navigation";

export const useNavTitle = () => {
  const location = useLocation();

  const route = ROOT_NAV.find((item) =>
    location?.pathname.includes(item.path)
  );

  return {
    title: location?.state?.page_title || route?.label,
    path: route?.path,
  };
};
