import React, { createContext, useState, useCallback } from "react";
import { ProcessCRM }                                  from "~/services";

export const ContactsContext = createContext({});

export const ContactsProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const [filter, setFilter] = useState("all");

  const [pagination, setPagination] = useState({
    per_page: 50,
    page: 1,
    total: 0,
    sort: "contacts.updated_at",
    order: "desc",
  });

  const [contacts, setContacts] = useState([]);

  const setContact = useCallback(() => {
    (id, data) => {
      if (!contacts || contacts.length == 0) return;
      let _contacts = contacts?.concat();
      const idx = _contacts.findIndex((c) => {
        return c.id && c.id === id;
      });

      if (idx && _contacts[idx]) {
        _contacts[idx] = {
          ..._contacts[idx],
          ...data,
        };
        setContacts(_contacts);
      }
    };
  }, [contacts, setContacts]);

  const removeContact = useCallback(() => {
    (id) => {
      let contact_id = id;
      if (!id || !contacts || contacts.length == 0) return;

      if (contact_id instanceof String) {
        contact_id = parseInt(contact_id);
      }

      setContacts(contacts.filter((f) => f.id !== contact_id));
    };
  }, [contacts, setContacts]);

  const findById = useCallback(
    (id) => {
      let contact_id = id;
      if (contact_id instanceof String) {
        contact_id = parseInt(contact_id);
      }
      const filtered = contacts.filter((f) => f.id == contact_id);
      if (filtered?.length) {
        return filtered[0];
      }
      return null;
    },
    [contacts]
  );

  const getContacts = useCallback(
    async (query) => {
      if (loading) return;
      setLoading(true);

      const res = await ProcessCRM.getContacts({
        ...pagination,
        filter,
        ...query,
      });

      if (res && !res.error && res.data) {
        setContacts(res.data);
        setPagination({
          total: res.total_count,
          per_page: res.per_page,
          page: res.page,
          sort: res.sort,
          order: res.order,
        });
      } else {
        setError(res.error);
      }
      setLoading(false);
      return res;
    },
    [
      loading,
      setLoading,
      setError,
      filter,
      pagination,
      setPagination,
      setContacts,
    ]
  );

  const searchContacts = useCallback(
    async (query) => {
      if (loading) return;
      setLoading(true);

      const res = await ProcessCRM.searchContacts({
        ...pagination,
        ...query,
      });

      if (res && !res.error && res.data) {
        setContacts(res.data?.map((c) => ({ ...c, ...c.attributes })));
        setPagination({
          total: res.total_count,
          per_page: res.per_page,
          page: res.page,
          sort: res.sort,
          order: res.order,
        });
      } else {
        setError(res.error);
      }
      setLoading(false);
      return res;
    },
    [
      loading,
      setLoading,
      setError,
      pagination,
      setPagination,
      setContacts,
    ]
  );

  const updateContact = useCallback(
    async (data) => {
      if (saving) return;
      setSaving(true);
      // const res = await RentratoData.updateContact(data);
      // if (!res.error) {
      //  if(res.result?.id){
      //    setContact(res.result);
      //  }
      // } else {
      //   setError(res.error);
      // }
      setSaving(false);
      // return res
    },
    [saving, setSaving, setError, setContact]
  );

  const deleteContact = useCallback(
    async (id) => {
      if (loading) return;
      setLoading(true);
      const res = await RentratoData.deleteContact(id);
      if (!res.error) {
        removeContact(id);
      } else {
        setError(res.error);
      }
      setLoading(false);
      // return res
    },
    [loading, setLoading, setError, setContact]
  );

  return (
    <ContactsContext.Provider
      value={{
        error,
        saving,
        loading,
        filter,
        setFilter,
        ...pagination,
        contacts,
        removeContact,
        setContact,
        getContacts,
        searchContacts,
        updateContact,
        deleteContact,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};
