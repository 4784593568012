import React                       from "react";
import { useLocation, useParams }  from "react-router-dom";
import styled                      from "styled-components";
import { Screen }                  from "~/components/layouts/Screen/index.web";
import { QuoteEditor }             from "~/components/quotes/QuoteEditor/index.web";
import { QuoteProvider }           from "~/contexts/QuoteContext";

export const QuoteEditScreen = ({ style = {} }) => {
  const { state, ...rest } = useLocation();
  const { quote_id } = useParams();

  return (
    <QuoteProvider
      value={{
        id: quote_id,
        ...state?.quote,
      }}
    >
      <Screen fluid noPadding>
        <QuoteContaner>
          <QuoteEditor />
        </QuoteContaner>
        
      </Screen>
    </QuoteProvider>
  );
};

const QuoteContaner = styled.div`
  padding: 16px;
`;
