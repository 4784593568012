import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { toast }        from "react-toastify";

import { orderReducer } from "~/reducers/orderReducer";
import { ProcessCRM }   from "~/services";

export const OrderContext = createContext({});

export const OrderProvider = ({ children, value }) => {
  const [order, dispatch] = useReducer(orderReducer, value);
  const [error] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const getOrder = useCallback(async () => {
    if (!order?.id) return;
    setLoading(true);
    const res = await ProcessCRM.getOrder(order.id);
    if (res?.data) {
      dispatch({ type: "ORDER_FETCH_SUCCESS", payload: res.data });
    } else if (res?.error && res?.message) {
      toast.error(res?.message);
    }
    setLoading(false);
    return res;
  }, [dispatch, order?.id, loading, setLoading]);

  const updateOrder = useCallback(async () => {
    if (saving || !order?.id) return;
    setSaving(true);

    const {
      notes,
      status,
      date,
      recipient_name,
      order_no,
      external_ref_no,
      freight_account,
      terms,
      contact_id,
      quote_id,
      billing_addr_city,
      billing_addr_state,
      billing_addr_street,
      billing_addr_suite,
      billing_addr_zip_code,
      shipping_addr_city,
      shipping_addr_state,
      shipping_addr_street,
      shipping_addr_suite,
      shipping_addr_zip_code,
      shipping_fees,
      shipping_method,
      tax_rate,
      order_items,
    } = order?.attributes || {};

    const res = await ProcessCRM.updateOrder(order?.id, {
      notes,
      status,
      date,
      recipient_name,
      freight_account,
      terms,
      contact_id,
      quote_id,
      order_no,
      external_ref_no,
      billing_addr_city,
      billing_addr_state,
      billing_addr_street,
      billing_addr_suite,
      billing_addr_zip_code,
      shipping_addr_city,
      shipping_addr_state,
      shipping_addr_street,
      shipping_addr_suite,
      shipping_addr_zip_code,
      shipping_fees,
      shipping_method,
      tax_rate,
      order_items: order_items.map(({ product, ...item }) => ({ ...item })),
    });

    if (res?.data) {
      dispatch({ type: "ORDER_SAVE_SUCCESS", payload: res.data });
      toast.success("Order successfully saved!");
    } else if (res?.error && res?.message) {
      toast.error(res.message);
      dispatch({
        type: "ORDER_SAVE_FAILED",
        payload: res,
      });
    }
    setSaving(false);

    return res;
  }, [dispatch, order, setSaving]);

  /** createShipment()
   *
   *
   */
  const createShipment = useCallback(
    async (data) => {
      if (!order?.id) return;
      const res = await ProcessCRM.createShipment(order?.id, data);
      if (res?.data?.id) {
        dispatch({
          type: "SHIPMENT_CREATED",
          payload: res?.data?.attributes,
        });
        toast.success("Shipment successfully created!");
      } else if (res?.error && res?.message) {
        toast.error(res?.message);
      }
      return res;
    },
    [saving, order]
  );

  /** updateShipment()
   *
   *
   */
  const updateShipment = useCallback(
    async (data) => {
      if (!order?.id) return;
      const res = await ProcessCRM.updateShipment(order?.id, data);
      if (res?.error && res?.message) {
        toast.error(res?.message);
      }
      return res;
    },
    [saving, order]
  );

  /** deleteShipment()
   *
   *
   */
  const deleteShipment = useCallback(
    async (shipment_id) => {
      if (!order?.id) return;
      const res = await ProcessCRM.deleteShipment(order?.id, shipment_id);
      if (res?.success) {
        dispatch({
          type: "SHIPMENT_DELETED",
          payload: {
            id: shipment_id,
          },
        });
        toast.success("Shipment succesfully deleted!");
      } else if (res?.error && res?.message) {
        toast.error(res?.message);
      }
      return res;
    },
    [saving, order]
  );

  const updateItem = useCallback(
    async (id, item) => {
      dispatch({
        type: "ITEM_UPDATED",
        payload: {
          id,
          ...item,
          saving: !!order?.id,
        },
      });

      if (order?.id) {
        const res = await ProcessCRM.updateOrderItem(order?.id, { id, item });
        if (res?.data?.id) {
          dispatch({
            type: "ITEM_UPDATE_SUCCESS",
            payload: {
              ...res.data,
            },
          });
        } else if (res?.error && res?.message) {
          toast.error(res.message);
          dispatch({
            type: "ITEM_UPDATE_FAILED",
            payload: {
              id,
              error: res.message,
            },
          });
        }
      }
    },
    [order?.id, dispatch]
  );

  const deleteItem = useCallback(
    async (id) => {
      if (order?.id) {
        const res = await ProcessCRM.deleteOrderItem(order?.id, id);
        if (res?.success) {
          dispatch({
            type: "ITEM_DELETED",
            payload: {
              id,
            },
          });
        } else if (res?.error && res?.message) {
          toast.error(res.message);
        }
      } else {
        dispatch({
          type: "ITEM_DELETED",
          payload: {
            id,
          },
        });
      }
    },
    [dispatch, order?.id]
  );

  const ledger = useMemo(
    () => getLedgerFromOrder(order),
    [
      order?.attributes?.order_items,
      order?.attributes?.tax_rate,
      order?.attributes?.total_paid,
    ]
  );

  useEffect(() => {
    if (order?.id) {
      getOrder(order?.id);
    }
  }, [order?.id]);

  return (
    <OrderContext.Provider
      value={{
        error,
        loading,
        ledger,
        order,
        getOrder,
        updateOrder,
        createShipment,
        deleteShipment,
        updateShipment,
        dispatch,
        deleteItem,
        updateItem,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

const getLedgerFromOrder = (order = {}) => {
  const {
    order_items = [],
    tax_rate = 0,
    total_paid,
  } = order?.attributes || {};

  const ledger = {
    balance: 0,
    subtotal: 0,
    total_due: 0,
  };

  if (order_items?.length == 0) return ledger;

  order_items?.forEach(({ price, quantity }) => {
    ledger.subtotal += price * quantity;
  });

  if (tax_rate && parseFloat(tax_rate)) {
    ledger.total_due = ledger.subtotal * (parseFloat(tax_rate) / 100);
  } else {
    ledger.total_due = ledger.subtotal;
  }

  ledger.balance = ledger.total_due - parseFloat(total_paid);

  return { ...ledger };
};
