import {
  faChevronDown,
  faChevronUp,
  faCopy,
  faGlobe,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { OrderContext } from "~/contexts/OrderContext";
import { toDollars } from "~/helpers/currency";
import { dayjs } from "~/helpers/day";

export const OrderDetails = () => {
  return (
    <OrderDetailsContainer>
      <OrderNumbers />
      <OrderContact />
      <OrderQuote />
      <OrderShipments />
      <OrderProducts />
      <OrderAuthor />
    </OrderDetailsContainer>
  );
};

const DetailSection = ({ children, label = "" }) => {
  const [open, setOpen] = useState(true);
  return (
    <OrderDetailsSection className="mb-2">
      <div
        className={`detail-header  ${open ? "open" : ""}`}
        onClick={() => setOpen(!open)}
      >
        <div className="row">
          <div className="col">{label}</div>
          <div className="col text-right">
            {open ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </div>
        </div>
      </div>
      {open && <>{children}</>}
    </OrderDetailsSection>
  );
};

const OrderNumbers = () => {
  const { order, dispatch } = useContext(OrderContext);

  console.log(order)

  const onChangeHandler = (e) => {
    const {
      currentTarget: { value, name },
    } = e;

    switch (name) {
      case "date":
        dispatch({
          type: "DATE_UPDATED",
          payload: value,
        });
        break;

      case "order_no":
        dispatch({
          type: "ORDER_NUMBER_UPDATED",
          payload: value,
        });
        break;

      case "external_ref_no":
        dispatch({
          type: "PO_NUMBER_UPDATED",
          payload: value,
        });
        break;

      case "recipient_name":
        dispatch({
          type: "RECIPIENT_UPDATED",
          payload: value,
        });
        break;
    }
  };


  return (
    <div className="my-2">
      <label className="text-xs text-dark" labelFor="date">
        Date #:
      </label>
      <input
        className="form-control mb-2"
        name="date"
        type="date"
        onChange={onChangeHandler}
        defaultValue={order?.attributes?.date}
      />
      <label className="text-xs text-dark" labelFor="order_no">
        Order #:
      </label>
      <input
        className="form-control mb-2"
        name="order_no"
        placeholder="Order Number"
        onChange={onChangeHandler}
        defaultValue={order?.attributes?.order_no}
      />
      <label className="text-xs  text-dark" labelFor="external_ref_no">
        PO #:
      </label>
      <input
        className="form-control mb-2"
        name="external_ref_no"
        placeholder="PO Number"
        onChange={onChangeHandler}
        defaultValue={order?.attributes?.external_ref_no}
      />
      <label className="text-xs  text-dark" labelFor="recipient_name">
        Recipient:
      </label>
      <input
        className="form-control mb-2"
        name="recipient_name"
        placeholder="Optional recipient"
        onChange={onChangeHandler}
        defaultValue={order?.attributes?.recipient_name}
      />
      <hr />
    </div>
  );
};

const OrderContact = () => {
  const { order } = useContext(OrderContext);
  const { attributes } = order || {};

  const {
    title,
    full_name,
    company,
    email,
    phone,
    alt_phone,
    address_city,
    address_state,
    address_street,
    address_unit,
    address_zip,
  } = attributes?.contact || {};

  const full_address =
    address_street && address_city && address_state && address_zip
      ? `${address_street} ${address_unit} ${address_city}, ${address_state} ${address_zip}`
      : "";

  return (
    <DetailSection label="Contact">
      <div className="inner">
        <div className="text-bold">{full_name}</div>
        <div className="fs-12 mb-1">
          <div>{title}</div>
          <div className="text-bold">{company?.name}</div>
        </div>
        <div className="fs-12">
          <div>{email}</div>
          <div>{phone}</div>
          <div>{alt_phone}</div>
        </div>
      </div>
    </DetailSection>
  );
};

const OrderProducts = () => {
  const { order } = useContext(OrderContext);
  const { attributes } = order || {};

  const { order_items } = attributes || {};

  if (!order_items?.length) return null;

  return (
    <DetailSection label="Parts">
      {order_items?.map((item) => (
        <div className="part" key={item?.id}>
          <Link className="link" to={`/parts/${item?.product?.id}`}>
            {item?.product?.part_number}
          </Link>
          {item?.product?.url && (
            <a
              className="float-right text-primary"
              target="blank"
              href={item?.product?.url}
            >
              <FontAwesomeIcon icon={faGlobe} />
            </a>
          )}
        </div>
      ))}
    </DetailSection>
  );
};

const OrderShipments = () => {
  const { order, deleteShipment } = useContext(OrderContext);
  const { attributes } = order || {};
  const { shipments } = attributes || {};

  if (!shipments?.length) return null;

  const deleteShipmentHandler = (shipment) => async () => {
    if (!shipment?.id) return;
    const confirmed = confirm("Are you sure you want to delete this shipment?");
    if (confirmed) {
      deleteShipment(shipment?.id);
    }
  };

  return (
    <DetailSection label="Shipments">
      {shipments?.map((shipment) => (
        <div className="shipment row no-gutters mx-0" key={shipment?.id}>
          <div className="col">
            <div className="row mx-0">
              <div
                className="d-inline-block text-clip"
                style={{ maxWidth: 200 }}
                title={shipment?.tracking_number}
              >
                <Link
                  className="link"
                  to={`shipments/${shipment?.id}`}
                  state={{ shipment }}
                >
                  {shipment?.tracking_number}
                </Link>
              </div>
              <div className="d-inline-block">
                <FontAwesomeIcon
                  className="ml-2 text-gray fs-12 c-pointer"
                  icon={faCopy}
                  onClick={() => {
                    navigator.clipboard
                      .writeText(shipment?.tracking_number)
                      .then(() => {
                        toast.info("Copied tracking number to clipboard.");
                      });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col flex-align-right fs-12  col-3">
            <span>{dayjs(shipment?.shipped_at).format("M/D/YY")}</span>
            <span className="ml-2 text-danger  c-pointer">
              <FontAwesomeIcon
                icon={faTrashAlt}
                onClick={deleteShipmentHandler(shipment)}
              />
            </span>
          </div>
        </div>
      ))}
    </DetailSection>
  );
};

const OrderQuote = () => {
  const { order } = useContext(OrderContext);
  const { attributes } = order || {};

  const { quote } = attributes || {};

  return (
    <DetailSection className="fs-12" label="Quote">
      <div className="py-1 px-3 row  ">
        <div className="col fs-12">Quote #</div>
        <div className="col">
          <Link to={`/quotes/${quote?.id}`} className="link text-secondary">
            {quote?.quote_no}
          </Link>
        </div>
      </div>
      <div className="py-1 px-3 row  ">
        <div className="col fs-12">Total ($)</div>
        <div className="col">
          <span className="text-bold fs-12">{toDollars(quote?.total_due)}</span>
        </div>
      </div>
      <div className="py-1 px-3 row  ">
        <div className="col  fs-12">Status</div>
        <div className="col">
          <span className="text-bold fs-12">{quote?.status_label}</span>
        </div>
      </div>
    </DetailSection>
  );
};

const OrderAuthor = () => {
  const { order } = useContext(OrderContext);
  return <div></div>;
};

const OrderDetailsContainer = styled.div`
  position: sticky;
  top: 0.5rem;
  @media print {
    display: none;
  }
`;

const OrderDetailsSection = styled.div`
  border: 1px solid #ddd;
  background-color: #fafafa;

  .inner {
    padding: 8px 12px;
  }

  .detail-header {
    padding: 8px 12px;
    background: white;
    font-weight: 500;
  }

  .detail-header.open {
    border-bottom: 1px solid #ddd;
  }

  .part,
  .shipment {
    padding: 8px 12px;
    border-bottom: 1px solid #ddd;
  }

  .part:last-child {
    border-bottom: none;
  }
`;
