import React               from "react";
import { Route, Routes }   from "react-router-dom";
import { VendorsScreen }   from "~/screens/VendorsScreen/index.web";

export const VendorsNavigator = () => {
  return (
    <Routes>
      <Route index element={<VendorsScreen />} />
    </Routes>
  );
}
