import React, { useContext } from "react";
import styled                from "styled-components";
import { QuoteContext }      from "~/contexts/QuoteContext";

const QuoteTermsContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  textarea {
    width: 100%;
    min-height: 200px;
    padding: 8px;
    font-size: 12px;
  }
  p {
    display: none;
  }

  @media print {
    textarea {
      display: none;
    }
    p {
      display: block;
      font-size: 12px;
    }
  }
`;

export const QuoteTerms = () => {
  const { quote, dispatch } = useContext(QuoteContext);

  const onChange = ({ currentTarget: { value } }) => {
    dispatch({
      type: "TERMS_UPDATED",
      payload: value,
    });
  };

  return (
    <QuoteTermsContainer>
      <div className="mb-4">Terms</div>
      <textarea onChange={onChange} value={quote?.attributes?.terms || ""} />
      <p
        dangerouslySetInnerHTML={{
          __html: quote?.attributes?.terms?.replace(/\n/g, "<br>"),
        }}
      />
    </QuoteTermsContainer>
  );
};
