import React, { useContext }             from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext }                   from "~/contexts/AuthContext";

export const AuthRoute = ({ redirectPath = "/login", children }) => {
  const { isAuthed } = useContext(AuthContext);
  const { pathname } = useLocation();

  if (!isAuthed) {
    console.log("Not logged in. Redirecting to login.", pathname);
    return <Navigate to={redirectPath} state={{ from: pathname }} replace />;
  } else {
    return children ? children : <Outlet />;
  }
};
