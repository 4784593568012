import React, { useContext, useRef, useState }   from "react";
import { ActivityIndicator }                     from "react-native-web";
import { Link, Navigate, useLocation }           from "react-router-dom";
import { AuthContext }                           from "~/contexts/AuthContext";
import { LIGHT }                                 from "~/themes/colors";

export const LoginScreen = () => {
  const ref = useRef();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { isAuthed, login } = useContext(AuthContext);

  const submitHandler = async (e) => {
    e.preventDefault();
    setError(null)
    let creds = {};
    let formData = new FormData(ref.current);
    formData.forEach((value, key) => (creds[key] = value));
    setLoading(true);
    const res = await login(creds);
    if(res.error){
      setError(res.details)
    }
    setLoading(false);
  };

  if (isAuthed) {
    return <Navigate to={state?.from || "/contacts"} />;
  }

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <h4 className="text-center mb-4 text-white">
                      { error || "Sign into your account" }
                    </h4>
                    <form
                      ref={(formRef) => (ref.current = formRef)}
                      onSubmit={(e) => submitHandler(e)}
                    >
                      <div className="form-group">
                        <label className="mb-1 text-white">
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          defaultValue=""
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-1 text-white">
                          <strong>Password</strong>
                        </label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          defaultValue=""
                        />
                      </div>
                      <div className="form-row d-flex justify-content-between mt-4 mb-2">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox ml-1 text-white">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="basic_checkbox_1"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="basic_checkbox_1"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <Link
                            className="text-white"
                            to="page-forgot-password"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn bg-white text-primary btn-block"
                        >
                          {loading ? <ActivityIndicator color={LIGHT.primary} /> : "Sign In"}
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p className="text-white">
                        Don't have an account?{" "}
                        <Link className="text-white" to="./page-register">
                          Sign up
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
