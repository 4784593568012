import React                    from "react";
import { Route, Routes }        from "react-router-dom";
import { PurchaseOrdersScreen } from "~/screens/PurchaseOrdersScreen/index.web";

export const PurchaseOrdersNavigator = () => {
  return (
    <Routes>
      <Route index element={<PurchaseOrdersScreen />} />
    </Routes>
  );
}
