import React, { useContext, useEffect } from "react";
import { Navigate, useLocation }        from "react-router-dom";
import { AuthContext }                  from "~/contexts/AuthContext";

export const LogoutScreen = ({ history }) => {
  const { state } = useLocation();
  const { isAuthed, logout } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthed) {
      logout();
    }
  }, []);

  if (!isAuthed) {
    return <Navigate to="/login" />;
  } else {
    return <div />;
  }
};
