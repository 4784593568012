import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Typeahead, withAsync }                             from "react-bootstrap-typeahead";
import { faSearch }                                         from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon }                                  from "@fortawesome/react-fontawesome";
import styled                                               from "styled-components";

import { ProcessCRM }                                       from "~/services";

const AsyncTypeAhead = withAsync(Typeahead);

const Placeholder = styled.div`
  position: relative;
`;

const ChangeButton = styled.span`
  position: absolute;
  top: 12px;
  right: 10px;
  font-size: 10px;
  cursor: pointer;
`;

export const CompanyTypeAhead = ({
  onChange,
  defaultValue,
  icon = false,
  placeholder,
}) => {
  const [loading, setLoading] = useState(false);
  
  const [companies, setCompanies] = useState(
    defaultValue ? [defaultValue] : []
  );

  const searchCompanies = useCallback(
    async (payload) => {
      setLoading(true);
      const res = await ProcessCRM.searchCompanies(payload);
      if (res?.data) {
        setCompanies(res.data);
      }
      setLoading(false);
      return res;
    },
    [setLoading, setCompanies]
  );

  // if (defaultValue) {
  //   return (
  //     <Placeholder>
  //       <input
  //         type="text"
  //         className="form-control"
  //         style={{ cursor: "not-allowed" }}
  //         defaultValue={defaultValue?.name}
  //         disabled
  //       />
  //       {/* <ChangeButton>Change</ChangeButton> */}
  //     </Placeholder>
  //   );
  // }

  return (
    <div className="companies-type-ahead" style={{ position: "relative" }}>
      <AsyncTypeAhead
        id="companies-input"
        labelKey={(option) => `${option?.attributes?.name}`}
        isLoading={loading}
        defaultInputValue={defaultValue?.name}
        searchText={<span>Searching...</span>}
        placeholder={placeholder}
        onInputChange={(value) => {
          if (!value?.length) {
            searchCompanies({
              per_page: 20,
              page: 1,
            });
          }
        }}
        onSearch={(value) => {
          onChange({ company_name: value, company_id: null });
          searchCompanies({
            name: value,
            per_page: 20,
            page: 1,
          });
        }}
        onChange={(selected) => {
          if (selected?.length && selected[0]?.id) {
            onChange &&
              onChange({
                company_name: null,
                company_id: selected[0]?.id,
                company: selected[0],
              });
          }
        }}
        options={companies}
      />

      {icon && (
        <FontAwesomeIcon
          icon={faSearch}
          className="text-gray"
          style={{ position: "absolute", top: 11, right: 15 }}
        />
      )}
    </div>
  );
};
