import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { toast }        from "react-toastify";
import { quoteReducer } from "~/reducers/quoteReducer";
import { ProcessCRM }   from "~/services";

export const QuoteContext = createContext({});

export const QuoteProvider = ({ children, value }) => {
  const [quote, dispatch] = useReducer(quoteReducer, value);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const getQuote = useCallback(async () => {
    if (!quote?.id) return;
    setLoading(true);
    const res = await ProcessCRM.getQuote(quote.id);
    if (res?.data) {
      dispatch({ type: "QUOTE_FETCH_SUCCESS", payload: res.data });
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
    return res;
  }, [dispatch, quote?.id, loading, setLoading, setError]);

  const updateQuote = useCallback(async () => {
    if (saving || !quote?.id) return;
    setSaving(true);
    setError(false);

    const {
      status,
      notes,
      terms,
      tax_rate,
      shipping_addr_city,
      shipping_addr_state,
      shipping_addr_street,
      shipping_addr_suite,
      shipping_addr_zip_code,
      shipping_fees,
      quote_items,
    } = quote?.attributes || {};

    const res = await ProcessCRM.updateQuote(quote?.id, {
      status,
      notes,
      terms,
      tax_rate,
      quote_items: quote_items.map(({ product, ...item }) => ({ ...item })),
      shipping_addr_city,
      shipping_addr_state,
      shipping_addr_street,
      shipping_addr_suite,
      shipping_addr_zip_code,
      shipping_fees,
    });

    if (res?.data) {
      dispatch({ type: "QUOTE_SAVE_SUCCESS", payload: res.data });
      toast.success("Quote sucessfully saved!");
    } else {
      dispatch({
        type: "QUOTE_SAVE_FAILED",
        payload: { error: res.message },
      });
      setError(res.message);
    }

    setSaving(false);

    return res;
  }, [dispatch, quote, saving, setSaving, setError]);

  const updateItem = useCallback(
    async (id, item) => {
      dispatch({
        type: "ITEM_UPDATED",
        payload: {
          id,
          ...item,
          saving: !!quote?.id,
        },
      });

      if (quote?.id) {
        const res = await ProcessCRM.updateQuoteItem(quote?.id, { id, item });
        if (res?.data?.id) {
          dispatch({
            type: "ITEM_UPDATE_SUCCESS",
            payload: {
              ...res.data,
            },
          });
        } else if (res?.error && res?.message) {
          toast.error(res.message);
          dispatch({
            type: "ITEM_UPDATE_FAILED",
            payload: {
              id,
              error: res.message,
            },
          });
        }
      }
    },
    [quote?.id, dispatch, setError]
  );

  const deleteItem = useCallback(
    async (id) => {
      if (quote?.id) {
        const res = await ProcessCRM.deleteQuoteItem(quote?.id, id);
        if (res?.success) {
          dispatch({
            type: "ITEM_DELETED",
            payload: {
              id,
            },
          });
        } else if (res?.error && res?.message) {
          toast.error(res.message);
        }
        return res;
      } else {
        dispatch({
          type: "ITEM_DELETED",
          payload: {
            id,
          },
        });
      }
    },
    [dispatch, quote?.id, setError]
  );

  const ledger = useMemo(
    () => getLedgerFromQuote(quote),
    [
      quote?.attributes?.quote_items,
      quote?.attributes?.tax_rate,
      quote?.attributes?.total_paid,
    ]
  );

  useEffect(() => {
    if (quote?.id) {
      getQuote(quote?.id);
    }
  }, [quote?.id]);

  return (
    <QuoteContext.Provider
      value={{
        error,
        loading,
        ledger,
        quote,
        getQuote,
        updateQuote,
        dispatch,
        deleteItem,
        updateItem,
      }}
    >
      {children}
    </QuoteContext.Provider>
  );
};

const getLedgerFromQuote = (quote = {}) => {
  const {
    quote_items = [],
    tax_rate = 0,
    total_paid,
  } = quote?.attributes || {};

  const ledger = {
    balance: 0,
    subtotal: 0,
    total_due: 0,
  };

  if (quote_items?.length == 0) return ledger;

  quote_items?.forEach(({ price, quantity }) => {
    ledger.subtotal += price * quantity;
  });

  if (tax_rate && parseFloat(tax_rate)) {
    ledger.total_due = ledger.subtotal * (parseFloat(tax_rate) / 100);
  } else {
    ledger.total_due = ledger.subtotal;
  }

  ledger.balance = ledger.total_due - parseFloat(total_paid);

  return { ...ledger };
};
