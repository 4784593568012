import React                      from "react";
import { StyleSheet, View, Text } from "react-native";
import { Screen }                 from "~/components/layouts/Screen/index.web";

export const VendorsScreen = ({ style = {} }) => {
  return (
    <Screen fluid>
      <View style={[styles.container]}>
        <Text>Vendors</Text>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    display: "flex",
  },
});
