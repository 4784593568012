import React                                   from "react";
import { View, StyleSheet, ActivityIndicator } from "react-native";
import DataTable                               from "react-data-table-component";
import { LIGHT }                               from "~/themes/colors";
import { useWindowSize }                       from "~/hooks/useWindowSize";

export const DataTableBase = ({
  customStyles = {},
  card,
  heightOffset = 200,
  ...props
}) => {
  const { height } = useWindowSize();

  const scrollHeight = height - 200 < 300 ? 300 : height - heightOffset;

  return (
    <View
      style={[
        styles.container,
        {
          boxShadow: "0px 12px 23px 0px rgb(112 112 112 / 4%)",
        },
        card && {
          borderTopLeftRadius: "1.25rem",
          borderTopRightRadius: "1.25rem",
        },
      ]}
    >
      <DataTable
        onRowClicked={(row) => {
          console.log(row);
        }}
        {...DefaultTableConfig}
        {...props}
        customStyles={{
          ...DefaultTableStyles,
          ...customStyles,
        }}
        paginationRowsPerPageOptions={[10, 25, 30, 50, 75, 100]}
        fixedHeaderScrollHeight={`${scrollHeight}px`}
        progressComponent={<LoadingIndicator />}
      />
    </View>
  );
};

const LoadingIndicator = () => {
  return (
    <View style={{ padding: 20 }}>
      <ActivityIndicator size="large" />
    </View>
  );
};

const DefaultTableStyles = {
  headRow: {
    style: {
      minHeight: "50px",
      paddingLeft: 16,
      paddingRight: 16,
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: LIGHT.border_lighter,
    },
    denseStyle: {
      minHeight: "32px",
    },
  },
  headCells: {
    style: {
      font: '14px "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontWeight: 600,
      paddingLeft: 12,
      paddingRight: 12,
      color: LIGHT.grey,
    },
  },
  rows: {
    style: {
      paddingLeft: 8,
      paddingRight: 8,
      minHeight: "52px",
      "&:not(:last-of-type)": {
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: LIGHT.border_lighter,
      },
    },
  },
  pagination: {
    style: {
      fontSize: "13px",
      borderTopColor: LIGHT.border_lighter,
      // borderBottomRightRadius: "1.25rem",
      // borderBottomLeftRadius: "1.25rem",
      font: '14px "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    },
  },
};

const DefaultTableConfig = {
  // selectableRows: true,
  // selectableRowsNoSelectAll: false,
  fixedHeader: true,
  fixedHeaderScrollHeight: "300px",
  pagination: true,
  paginationServer: true,
  paginationPerPage: 30,
  selectableRowsVisibleOnly: false,
  selectableRowsHighlight: false,
  selectableRowsSingle: false,
  expandableRows: false,
  expandOnRowClicked: false,
  expandOnRowDoubleClicked: false,
  expandableRowsHideExpander: false,
  highlightOnHover: true,
  striped: false,
  pointerOnHover: true,
  dense: false,
  persistTableHead: false,
  noHeader: false,
  progressPending: false,
  noTableHead: false,
  noContextMenu: false,
  subHeader: false,
  // direction: Direction.AUTO,
  // subHeaderAlign: Alignment.RIGHT,
  subHeaderWrap: true,
  responsive: true,
  disabled: false,
};

const styles = StyleSheet.create({
  container: {
    // borderTopLeftRadius: "1.25rem",
    // borderTopRightRadius: "1.25rem",
    overflow: "hidden",
  },
});
