export const ROOT_NAV = [
  // {
  //   label: "Dashboard",
  //   path: "/dashboard",
  //   icon: "la-chart-pie"
  // },
  {
    label: "Contacts",
    path: "/contacts",
    icon: "la-user-friends"
  },
  {
    label: "Quotes",
    path: "/quotes",
    icon: "la-receipt"
  },
  {
    label: "Orders",
    path: "/orders",
    icon: "la-receipt"
  },
  {
    label: "Parts",
    path: "/parts",
    icon: "la-box"
  },
  // {
  //   label: "Purchase Orders",
  //   path: "/purchase-orders",
  //   icon: "la-receipt"
  // },
  // {
  //   label: "Vendors",
  //   path: "/vendors",
  //   icon: "la-user-friends"
  // },
  
  // {
  //   label: "Settings",
  //   path: "/settings",
  //   icon: "la-sliders-h"
  // },
]