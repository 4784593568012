export const LIGHT = {
  light: "#f4f5f9",
  dark: "#b1b1b1",

  primary:  "#fe634e",
  primary_light: "#ffd3cd",
  primary_transparent: "rgba(254,99,78,.05)",
  
  secondary: "#707070",
  
  info: "#2421da",
  info_light: "#d0cff8",

  success: "#2bc155",
  danger: "#ff4847",
  warning: "#ff6d4d",
  
  orange: "#f9690e",
  coral: "#ff7f50",
  purple: "#6610f2",
  white: "#ffffff",
  yellow: "#fffa6f",
  teal: "#20c997",
  green: "#297f00",
  navy: '#04014d',
  gray: "#6c757d",
  dark_gray: "#343a40",
  black: "#000000",

  

  bg_light: "rgb(223, 228, 234)",
  bg_lighter: "#f4f5f9",
  bg_lightest: "#f5f5f5",

  border_light: "rgb(223, 228, 234)",
  border_lighter: "#f4f5f9",
  border_lightest: "#f5f5f5",
  
  transparent: 'transparent'
};


export const DARK = {
  primary:  "#fe634e",
  primary_light: "#ffd3cd",
  primary_transparent: "rgba(254,99,78,.05)",
  secondary: "#707070",

  light: "#f4f5f9",
  dark: "#b1b1b1",
  info: "#2421da",
  success: "#2bc155",
  danger: "#ff4847",
  warning: "#ff6d4d",
  
  orange: "#f9690e",
  coral: "#ff7f50",
  purple: "#6610f2",
  white: "#ffffff",
  yellow: "#fffa6f",
  teal: "#20c997",
  green: "#297f00",
  navy: '#04014d',
  gray: "#6c757d",
  dark_gray: "#343a40",
  black: "#000000",

  bg_light: "rgb(223, 228, 234)",
  bg_lighter: "#f4f5f9",
  bg_lightest: "#f5f5f5",
  
  transparent: 'transparent'
};
