import React                            from "react";
import { Route, Routes, useNavigate }   from "react-router-dom";
import { QuotesProvider }               from "~/contexts/QuotesContext";
import { QuoteEditScreen }              from "~/screens/QuoteEditScreen/index.web";
import { QuotesScreen }                 from "~/screens/QuotesScreen/index.web";

import { QueryRoute }                   from "~/routers/QueryRoute/index.web";
import { PartProvider }                 from "~/contexts/PartContext";
import { PartsModal }                   from "~/components/parts/PartsModal/index.web";

export const QuotesNavigator = () => {
  const navigate = useNavigate();
  return (
    <QuotesProvider>
      <Routes>
        <Route index element={<QuotesScreen />} />
        <Route path="/:quote_id/*" element={<QuoteEditScreen />} />
      </Routes>
      <QueryRoute queryKey="modal" queryValue="new-part">
        <PartProvider>
          <PartsModal
            title="Create Part"
            onToggle={() => navigate(-1)}
            onSuccess={() =>navigate(-1)}
          />
        </PartProvider>
      </QueryRoute>
    </QuotesProvider>
  );
};
