import React, { useContext }        from "react";
import { Button, Col, Row }         from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { PartContext }              from "~/contexts/PartContext";

export const PartDetails = () => {
  const navigate = useNavigate();
  const { part, loading } = useContext(PartContext);

  return (
    <div className={`part-details  ${loading ? "d-none" : ""}`}>
      <Row>
        <Col md={5} className="mb-3">
          <div className="part-number h5 text-primary link">
            {part?.attributes?.manufacturer?.name}
          </div>
          <div className="part-number h4 mb-3">
            {part?.attributes?.part_number}
          </div>
          <PartStats />
        </Col>
        <Col>
          <Row className="flex-align-right py-3">
            <Button
              size="sm"
              onClick={() => {
                navigate(`/parts/${part?.id}?modal=new-purchase-order`, {
                  state: {
                    purchase_order: {
                      product_id: part?.id,
                      product: {
                        ...part,
                      },
                    },
                  },
                });
              }}
            >
              + Create Purchase Order
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const PartStats = () => {
  const { part } = useContext(PartContext);

  return (
    <div className="px-3">
      <Row className="border border-gray">
        <Col className="px-0 border-right border-gray">
          <div className="text-center border-bottom border-gray text-bold">
            AV
          </div>
          <div className="text-center h5 mb-0 bg-white py-2">
            {part?.attributes?.av_count}
          </div>
        </Col>
        <Col className="px-0 border-right border-gray">
          <div className="text-center border-bottom border-gray text-bold">
            QC
          </div>
          <div className="text-center h5 mb-0 bg-white py-2">
            {part?.attributes?.qc_count}
          </div>
        </Col>
        <Col className="px-0 border-right border-gray">
          <div className="text-center border-bottom border-gray text-bold">
            OC
          </div>
          <div className="text-center h5 mb-0 bg-white py-2">
            {part?.attributes?.oc_count}
          </div>
        </Col>
        <Col className="px-0">
          <div className="text-center border-bottom border-gray text-bold">
            POC
          </div>
          <div className="text-center h5 mb-0 bg-white py-2">
            {part?.attributes?.po_count}
          </div>
        </Col>
      </Row>
    </div>
  );
};
