import axios                             from "../axios";
import { responseHandler, errorHandler } from "../../utils/handlers";

import { CONTACTS_URI }                  from "../../constants/uri";
import { PAGINATION }                    from "../../constants/query";
import { buildParams }                   from "../../utils/params";

export async function getContacts(params) {
  const {
    page = 1,
    per_page = 50,
    sort = "last_name",
    order = "asc",
    filter = "",
  } = params || PAGINATION;

  const res = await axios
    .request(
      `${CONTACTS_URI}?${buildParams({ page, per_page, sort, order, filter })}`,
      {
        method: "GET",
      }
    )
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function searchContacts(params) {
  const {
    page = 1,
    per_page = 50,
    term = "",
    sort = "last_name",
    order = "asc",
  } = params || PAGINATION;

  const res = await axios
    .request(
      `${CONTACTS_URI}/search?${buildParams({ page, per_page, sort, order, term })}`,
      {
        method: "GET",
      }
    )
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function getContact(id) {
  const res = await axios
    .request(`${CONTACTS_URI}/${id}`, {
      method: "GET",
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function createContact(data) {
  const res = await axios
    .request(`${CONTACTS_URI}`, {
      method: "POST",
      data,
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function updateContact(id, data) {
  const res = await axios
    .request(`${CONTACTS_URI}/${id}`, {
      method: "PUT",
      data,
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function deleteContact(id) {
  const res = await axios
    .request(`${CONTACTS_URI}/${id}`, {
      method: "DELETE",
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}
