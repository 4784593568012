import axios                             from "../axios";
import { responseHandler, errorHandler } from "../../utils/handlers";

import { ORDERS_URI }                    from "../../constants/uri";
import { PAGINATION }                    from "../../constants/query";
import { buildParams }                   from "../../utils/params";

export async function getOrders(params) {
  const {
    page = 1,
    per_page = 50,
    sort = "created_at",
    order = "desc",
    filter = "",
    contact_id = "",
    quote_id = "",
    product_id = "",
  } = params || PAGINATION;

  const res = await axios
    .request(
      `${ORDERS_URI}?${buildParams({
        page,
        per_page,
        sort,
        order,
        quote_id,
        contact_id,
        product_id,
        filter,
      })}`,
      {
        method: "GET",
      }
    )
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function getOrder(id) {
  const res = await axios
    .request(`${ORDERS_URI}/${id}`, {
      method: "GET",
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function createOrderFromQuote(quote_id) {
  const res = await axios
    .request(`${ORDERS_URI}?quote_id=${quote_id}`, {
      method: "POST",
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function updateOrder(id, data) {
  const res = await axios
    .request(`${ORDERS_URI}/${id}`, {
      method: "PUT",
      data: { order: data },
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function deleteOrder(id) {
  const res = await axios
    .request(`${ORDERS_URI}/${id}`, {
      method: "DELETE",
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function addOrderItem(id, data) {
  const res = await axios
    .request(`${ORDERS_URI}/${id}/items`, {
      method: "POST",
      data: { item: data },
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function updateOrderItem(id, data) {
  const res = await axios
    .request(`${ORDERS_URI}/${id}/items/${data?.id}`, {
      method: "PUT",
      data,
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function deleteOrderItem(id, item_id) {
  const res = await axios
    .request(`${ORDERS_URI}/${id}/items/${item_id}`, {
      method: "DELETE",
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function createShipment(order_id, data) {
  const res = await axios
    .request(`${ORDERS_URI}/${order_id}/shipments`, {
      method: "POST",
      data: { shipment: data },
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}


export async function updateShipment(order_id, data) {
  const res = await axios
    .request(`${ORDERS_URI}/${order_id}/shipments/${data?.id}`, {
      method: "PUT",
      data: { shipment: data },
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function deleteShipment(order_id, shipment_id) {
  const res = await axios
    .request(`${ORDERS_URI}/${order_id}/shipments/${shipment_id}`, {
      method: "DELETE",
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}


