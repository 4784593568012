import axios                             from "../axios";
import { responseHandler, errorHandler } from "../../utils/handlers";

import { PARTS_URI }                     from "../../constants/uri";
import { PAGINATION }                    from "../../constants/query";
import { buildParams }                   from "../../utils/params";

export async function getParts(params) {
  const {
    page = 1,
    per_page = 50,
    sort = "last_name",
    order = "asc",
    term = "",
    manufacturer_id = "",
  } = params || PAGINATION;

  const res = await axios
    .request(
      `${PARTS_URI}?${buildParams({
        page,
        per_page,
        sort,
        order,
        term,
        manufacturer_id,
      })}`,
      {
        method: "GET",
      }
    )
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function getPart(id) {
  const res = await axios
    .request(`${PARTS_URI}/${id}`, {
      method: "GET",
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function createPart(data) {
  const res = await axios
    .request(`${PARTS_URI}`, {
      method: "POST",
      data,
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function updatePart(id, data) {
  const res = await axios
    .request(`${PARTS_URI}/${id}`, {
      method: "PUT",
      data,
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function deletePart(id) {
  const res = await axios
    .request(`${PARTS_URI}/${id}`, {
      method: "DELETE",
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}
