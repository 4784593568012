import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";

import { ProcessCRM }  from "~/services";
import { Storage }     from "~/helpers/storage";
import { AppContext }  from "~/contexts/AppContext";
import { AuthContext } from "~/contexts/AuthContext";

import { noopFn }      from "~/helpers/context";

export const UserContext = createContext({
  user: null,
  error: null,
  loading: false,
  resetUser: noopFn,
  updateUser: noopFn,
  saveUser: noopFn,
  getUser: noopFn,
});

export const UserProvider = ({ children }) => {
  const { prevState } = useContext(AppContext);
  const { logout } = useContext(AuthContext);

  const [user, setUser] = useState(prevState?.user);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const getUser = useCallback(async () => {
    if (loading || !user?.id) return;
    setLoading(true);
    const res = await ProcessCRM.getUser();
    if (res?.data) {
      setUser({ ...res.data, ...res.data?.attributes });
      persist({ ...res.data, ...res.data?.attributes });
    } else {
      resetUser();
    }
    setLoading(false);
    return res;
  }, [user, setUser, loading, setLoading, resetUser]);

  const saveUser = useCallback(
    async (data) => {
      if (saving) return;
      setSaving(true);
      const res = ProcessCRM.updateUser(data);
      if (res?.data) {
        setUser({ ...res.data, ...res.data?.attributes });
      }
      setSaving(false);
    },
    [saving, setSaving, setUser]
  );

  const resetUser = useCallback(() => {
    setUser(null);
    clear();
  }, [setUser]);

  const updateUser = useCallback(
    async (data) => {
      let _data = {};
      if (data?.attributes) {
        _data = {
          ...user,
          ...data,
        };
      } else {
        _data = {
          ...user,
          attributes: {
            ...user.attributes,
            ...data,
          },
        };
      }
      setUser(_data);
    },
    [user, setUser]
  );

  useEffect(() => {
    if (user?.id) {
      getUser();
    }
  }, [user?.id]);

  return (
    <UserContext.Provider
      value={{
        user,
        error,
        loading,
        resetUser,
        updateUser,
        saveUser,
        getUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const persist = async (data) => {
  console.log("saving to storage");
  return await Storage.setData("uu", data);
};

const clear = async (data) => {
  return await Storage.remove("uu");
};
