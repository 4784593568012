import React, { useCallback, useContext, useState } from "react";
import { Col, Row }                                 from "react-bootstrap";
import styled                                       from "styled-components";
import { faTrash }                                  from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon }                          from "@fortawesome/react-fontawesome";
import { PartsTypeAhead }                           from "~/components/parts/PartsTypeAhead/index.web";
import { PartsProvider }                            from "~/contexts/PartsContext";
import { OrderContext }                             from "~/contexts/OrderContext";
import { toDollars }                                from "~/helpers/currency";
import { guid }                                     from "~/helpers/guid";
import { ActivityIndicator }                        from "react-native";

export const OrderItems = () => {
  const { order, deleteItem, dispatch } = useContext(OrderContext);
  const [newRowVisible, toggleNewRow] = useState(false);

  const onChange =
    (item) =>
    ({ currentTarget: { name, value } }) => {
      dispatch({
        type: "ITEM_UPDATED",
        payload: {
          id: item?.id,
          [name]: value,
        },
      });
    };

  const onDelete = (item) => () => deleteItem(item?.id);

  return (
    <OrderItemContainer>
      <PartsProvider>
        <OrderItemsHeader />
        <div>
          {order?.attributes?.order_items?.map((item, x) => {
            return (
              <OrderItem
                key={item?.id || item?.internalId}
                item={item}
                onDelete={onDelete(item)}
                onChange={onChange(item)}
              />
            );
          })}
          {/* {newRowVisible && (
            <NewOrderItem onSubmit={() => toggleNewRow(false)} />
          )}
          {!newRowVisible && (
            <div className="py-2">
              <button
                className="btn btn-sm btn-success"
                onClick={() => toggleNewRow(true)}
              >
                + Add Line Item
              </button>
            </div>
          )} */}
        </div>
      </PartsProvider>
    </OrderItemContainer>
  );
};

const OrderItemsHeader = () => {
  return (
    <OrderListItem>
      <Row>
        <Col className="text-bold">
          <span>Product</span>
        </Col>
        <Col className="qty text-bold">
          <span>Quantity</span>
        </Col>
        <Col className="price text-bold">
          <span>Price</span>
        </Col>
        <Col className="count text-bold">
          <span>AV</span>
        </Col>
        {/* <Col className="count text-bold">
          <span>AL</span>
        </Col> */}
        <Col className="count text-bold">
          <span>PO</span>
        </Col>
        <Col className="count text-bold">
          <span>OC</span>
        </Col>
        <Col className="count text-bold">
          <span>QC</span>
        </Col>
        <Col className="total text-bold">
          <span>Total</span>
        </Col>
        <Col className="delete text-bold">
          <span></span>
        </Col>
      </Row>
    </OrderListItem>
  );
};

const OrderItem = ({ item, onChange, onDelete }) => {
  const [editDisplayName, toggleEdit] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const onEditDisplayName = (e) => {
    onChange(e);
    toggleEdit(false);
  };

  const onDeleteHandler = async () => {
    const confirmed = confirm("Are you sure you want to delete this item?");
    if (confirmed) {
      setDeleting(true);
      await onDelete();
      setDeleting(false);
    }
  };

  return (
    <OrderListItem>
      <Row>
        <Col>
          <div>
            <div>
              {editDisplayName && (
                <input
                  autoFocus
                  className="display_name"
                  name="display_name"
                  placeholder="Enter a display name"
                  defaultValue={item?.display_name}
                  onBlur={onEditDisplayName}
                />
              )}
            </div>
            <span className="d-inline-block py-2">
              {editDisplayName
                ? item?.product?.part_number
                : item?.display_name || item?.product?.part_number}
            </span>
            <span
              className="change ml-2 float-right text-secondary link"
              onClick={() => toggleEdit(true)}
            >
              Change
            </span>
          </div>
          {item?.display_name && !editDisplayName && (
            <div className="no-print">
              <small>{item?.product?.part_number}</small>
            </div>
          )}
          {item?.product?.description && (
            <div className="pr-5 py-3">{item?.product?.description}</div>
          )}
        </Col>
        <Col className="qty">
          <input
            type="number"
            name="quantity"
            defaultValue={item?.quantity || 1}
            onChange={onChange}
          />
        </Col>
        <Col className="price">
          {/* <input
            name="price"
            disabled
            className="border-0"
            type="number"
            defaultValue={item?.price || 0}
            onChange={onChange}
          /> */}
          <span className="text-bold">{toDollars(item.price)}</span>
        </Col>
        <Col className="count">
          <span>{item?.product?.av_count}</span>
        </Col>
        {/* <Col className="count">
          <span>{item?.product?.al_count}</span>
        </Col> */}
        <Col className="count">
          <span>{item?.product?.po_count}</span>
        </Col>
        <Col className="count">
          <span>{item?.product?.oc_count}</span>
        </Col>
        <Col className="count">
          <span>{item?.product?.qc_count}</span>
        </Col>
        <Col className="total">
          <span className="text-bold">
            {toDollars(item?.price * item?.quantity)}
          </span>
        </Col>
        <Col className="delete">
          {deleting ? (
            <ActivityIndicator size="small" color="#aaa" />
          ) : (
            <span>
              <FontAwesomeIcon
                icon={faTrash}
                color="red"
                onClick={onDeleteHandler}
              />
            </span>
          )}
        </Col>
      </Row>
    </OrderListItem>
  );
};

const initialState = {
  display_name: "",
  price: 0,
  quantity: 0,
  product_id: null,
  product: null,
};

const NewOrderItem = ({ onSubmit }) => {
  const { order, addItem, dispatch } = useContext(OrderContext);

  const [item, setItem] = useState({
    order_id: order?.id,
    ...initialState,
  });

  const onProductChange = useCallback(
    (product) => {
      setItem({
        ...item,
        product_id: product?.id,
        product,
      });
    },
    [item, setItem]
  );

  const onItemChange = useCallback(
    ({ currentTarget: { name, value } }) => {
      setItem({ ...item, [name]: value });
    },
    [item, setItem]
  );

  const onAddItem = useCallback(() => {
    if (order?.id && item?.product_id) {
      dispatch({
        type: "ITEM_ADDED",
        payload: {
          ...item,
          id: guid(),
          pending: true,
        },
      });
      onSubmit();
    }
  }, [item, order, onSubmit, dispatch]);

  return (
    <NewItemContainer>
      <Row>
        <Col>
          <PartsTypeAhead onChange={onProductChange} />
        </Col>
        <Col className="qty">
          <input
            name="quantity"
            value={item?.quantity}
            onChange={onItemChange}
          />
        </Col>
        <Col className="price">
          {/* <input name="price" value={item.price} onChange={onItemChange} /> */}
          <div>{toDollars(item.price)}</div>
        </Col>
        <Col className="count text-gray">
          <span>{item?.product?.av_count || "-"}</span>
        </Col>
        {/* <Col className="count text-gray">
          <span>{item?.product?.al_count || "-"}</span>
        </Col> */}
        <Col className="count text-gray">
          <span>{item?.product?.po_count || "-"}</span>
        </Col>
        <Col className="count text-gray">
          <span>{item?.product?.oc_count || "-"}</span>
        </Col>
        <Col className="count text-gray">
          <span>{item?.product?.qc_count || "-"}</span>
        </Col>
        <Col className="total text-gray">
          {toDollars(item?.price * item?.quantity)}
        </Col>
        <Col className="add">
          <button
            className="btn btn-sm btn-primary"
            style={{ paddingTop: 0, paddingBottom: 0, minHeight: 30 }}
            disabled={!item?.product_id}
            onClick={onAddItem}
          >
            <small className="text-bold">Add</small>
          </button>
        </Col>
      </Row>
    </NewItemContainer>
  );
};

const OrderItemContainer = styled.div`
  @media print {
    margin-top: 50px;

    .delete,
    .cancel,
    .change,
    .add,
    .no-print,
    .btn {
      display: none;
    }

    input {
      border: none !important;
    }
  }
`;

const OrderListItem = styled.div`
  padding: 8px;
  border-bottom: 1px solid #ddd;
  font-size: 12px;

  .row {
    justify-content: center;
    align-items: center;
  }

  input {
    border: 1px solid #ccc;
    padding: 4px;
    border-radius: 2px;
    max-width: 100%;
    font-size: 12px;
  }

  input:disabled {
    background: white;
    color: black;
    opacity: 1;
  }

  .display_name {
    width: 100%;
    margin-bottom: 8px;
  }

  .count {
    max-width: 65px;
  }

  .total {
    max-width: 125px;
  }

  .price,
  .qty {
    max-width: 100px;
  }

  .delete,
  .cancel {
    min-width: 100px;
    max-width: 100px;
    text-align: center;
  }

  .total {
    text-align: right;
  }

  @media print {
    .count {
      display: none;
    }

    input {
      border: none !important;
    }
  }
`;

const NewItemContainer = styled.div`
  padding: 8px;
  border-bottom: 1px solid #ddd;

  @media print {
    display: none;
  }

  .row {
    justify-content: center;
    align-items: center;
  }

  input {
    border: 1px solid #ccc;
    padding: 4px;
    border-radius: 2px;
    max-width: 100%;
    font-size: 12px;
  }

  .count {
    max-width: 65px;
  }

  .total {
    max-width: 125px;
  }

  .price,
  .qty {
    max-width: 100px;
  }

  .delete,
  .add {
    text-align: right;
    min-width: 100px;
    max-width: 100px;
  }

  .total {
    text-align: right;
  }
`;
