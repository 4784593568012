import React, { useContext } from "react";
import { OrdersList }        from "~/components/orders/OrdersList/index.web";
import { OrdersTable }       from "~/components/orders/OrdersTable/index.web";
import { OrdersProvider }    from "~/contexts/OrdersContext";
import { PartContext }       from "~/contexts/PartContext";

export function PartOrdersTab() {
  const { part, loading } = useContext(PartContext);
  return (
    <OrdersProvider>
      <div className="part-orders-tab">
        <OrdersTable product_id={part?.id} />
      </div>
    </OrdersProvider>
  );
}
