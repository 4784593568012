import React, { useState, useCallback } from "react";
import { faSearch }                     from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";
import { Typeahead, withAsync }         from "react-bootstrap-typeahead";

import { ProcessCRM }                   from "~/services";
import styled                           from "styled-components";

const AsyncTypeAhead = withAsync(Typeahead);

const Placeholder = styled.div`
  position: relative;
`;

export const ManufacturerTypeAhead = ({
  onChange,
  defaultValue,
  icon = false,
  placeholder,
}) => {
  const [loading, setLoading] = useState(false);
  const [manufacturers, setManufacturers] = useState(
    defaultValue ? [defaultValue] : []
  );

  const searchManufacturers = useCallback(
    async (payload) => {
      setLoading(true);
      const res = await ProcessCRM.getManufacturers(payload);
      if (res?.data) {
        setManufacturers(res.data);
      }
      setLoading(false);
      return res;
    },
    [setLoading, setManufacturers]
  );

  return (
    <div className="manufacturers-type-ahead" style={{ position: "relative" }}>
      <AsyncTypeAhead
        id="manufacturers-search-input"
        isLoading={loading}
        labelKey="name"
        defaultInputValue={defaultValue?.name}
        searchText={<span>Searching...</span>}
        placeholder={placeholder}
        onInputChange={(value) => {
          if (!value?.length) {
            searchManufacturers({
              per_page: 20,
              page: 1,
              sort: "name",
              order: "asc",
            });
          }
        }}
        onSearch={(value) => {
          onChange({ id: null, name: value });
          searchManufacturers({
            term: value,
            per_page: 20,
            page: 1,
            sort: "name",
          });
        }}
        onChange={(selected) => {
          if (selected?.length && selected[0]?.id) {
            onChange && onChange(selected[0]);
          }
        }}
        options={manufacturers}
      />
      {icon && (
        <FontAwesomeIcon
          icon={faSearch}
          className="text-gray"
          style={{ position: "absolute", top: 11, right: 15 }}
        />
      )}
    </div>
  );
};
