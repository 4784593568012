import React, { useCallback, useContext, useEffect } from "react";
import { Col, Row }                                  from "react-bootstrap";
import { ManufacturerTypeAhead }                     from "~/components/manufacturers/ManufacturerTypeAhead/index.web";
import { PartContext }                               from "~/contexts/PartContext";

export function PartsForm({}) {
  const { part, updatePart } = useContext(PartContext);
  const { attributes } = part || {};

  const onChangeHandler = useCallback(
    (data) => {
      updatePart && updatePart(data);
    },
    [updatePart]
  );

  // useEffect(() => {
  //   console.log("PartsForm:onChange", attributes);
  // }, [attributes]);

  return (
    <div className="px-3 py-3">
      <Row>
        <Col sm={12}>
          <div className="form-group">
            <label className="text-xs text-dark">Product Type*</label>
            <select
              className="form-control"
              name="product_type"
              defaultValue={attributes?.product_type}
              onChange={({ currentTarget: { value: product_type } }) => {
                if (product_type == "service") {
                  onChangeHandler({ product_type, manufacturer_name: null });
                } else {
                  onChangeHandler({ product_type });
                }
              }}
            >
              <option value="">Select a type</option>
              <option value="service">Service</option>
              <option value="product">Product</option>
            </select>
          </div>
        </Col>
        {attributes?.product_type !== "service" && (
          <Col sm={12}>
            <div className="form-group">
              <label className="text-xs text-dark">Manufacturer*</label>
              <ManufacturerTypeAhead
                onChange={({ id, name }) => {
                  if (id) {
                    onChangeHandler({
                      manufacturer_id: id,
                      manufacturer_name: null,
                    });
                  } else {
                    onChangeHandler({
                      manufacturer_name: name,
                    });
                  }
                }}
              />
             
            </div>
          </Col>
        )}
        <Col sm={12}>
          <div className="form-group">
            <label className="text-xs text-dark">Part Number*</label>
            <input
              type="text"
              name="part_number"
              className="form-control"
              defaultValue={attributes?.part_number}
              onChange={({ currentTarget: { value: part_number } }) =>
                onChangeHandler({ part_number })
              }
            />
          </div>
        </Col>
        <Col sm={12}>
          <div className="form-group">
            <label className="text-xs text-dark">Description</label>
            <textarea
              name="description"
              className="form-control"
              defaultValue={attributes?.description}
              onChange={({ currentTarget: { value: description } }) =>
                onChangeHandler({ description })
              }
            />
          </div>
        </Col>
        <Col sm={12}>
          <div className="form-group">
            <label className="text-xs text-dark">Notes</label>
            <textarea
              name="description"
              className="form-control"
              defaultValue={attributes?.notes}
              onChange={({ currentTarget: { value: notes } }) =>
                onChangeHandler({ notes })
              }
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}
