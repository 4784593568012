import React, { useContext }     from "react";
import { PartContext }           from "~/contexts/PartContext";
import { PartPriceHistoryTable } from "../../PartPriceHistoryTable/index.web";

export const PartPriceHistoryTab = () => {
  const { part, loading } = useContext(PartContext);
  return (
    <div className="part-info-tab">
      <PartPriceHistoryTable
        loading={loading}
        part={part}
        data={part?.attributes?.quote_items}
      />
    </div>
  );
};
