import React                      from "react";
import { StyleSheet, View, Text } from "react-native";
import { useLocation, useParams } from "react-router-dom";
import { Screen }                 from "~/components/layouts/Screen/index.web";
import { OrderEditor }            from "~/components/orders/OrderEditor/index.web";
import { OrderProvider }          from "~/contexts/OrderContext";

export const OrderEditScreen = ({ style = {} }) => {
  const { state, ...rest } = useLocation();
  const { order_id } = useParams();

  return (
    <OrderProvider
      value={{
        id: order_id,
        ...state?.order,
      }}
    >
      <Screen fluid noPadding>
        <View style={[styles.container]}>
          <OrderEditor />
        </View>
      </Screen>
    </OrderProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
});
