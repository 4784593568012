import React                   from "react";
import { Row, Col, Button }    from "react-bootstrap";
import { ContactBillingForm }  from "../../ContactBillingForm/index.web";
import { ContactShippingForm } from "../../ContactShippingForm/index.web";

export const ContactBillingTab = () => {
  return (
    <div className="contact-billing-tab py-4">
      <Row>
        <Col sm={12}>
          <ContactShippingForm />
        </Col>
        {/* <Col sm={12} md={6}>
          <ContactBillingForm />
        </Col> */}
      </Row>
    </div>
  );
};
