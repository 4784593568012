import React, { useContext, useCallback } from "react";
import { faClose }                        from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon }                from "@fortawesome/react-fontawesome";
import ModalHeader                        from "react-bootstrap/esm/ModalHeader";
import { SideModal }                      from "~/components/modals/SideModal/index.web";
import { PurchaseOrdersForm }             from "../PurchaseOrdersForm/index.web";
import { SaveFooterBar }                  from "~/components/bars/SaveFooterBar/index.web";
import { PurchaseOrderContext }           from "~/contexts/PurchaseOrderContext";
import { useNavigate }                    from "react-router-dom";
import { PartContext }                    from "~/contexts/PartContext";

export const PurchaseOrdersModal = ({
  title = "PurchaseOrders",
  onToggle,
  onSuccess,
  ...props
}) => {
  const navigate = useNavigate();

  const { part } = useContext(PartContext);
  const { purchaseOrder, createPurchaseOrder, savePurchaseOrder } = useContext(PurchaseOrderContext);

  const onSubmitHandler = useCallback(async () => {
    const { attributes } = purchaseOrder;
    let res;

    const payload = {
      ...attributes,
      product_id: part?.id,
    }
    
    if (purchaseOrder?.id) {
      res = await savePurchaseOrder(payload);
    } else {
      res = await createPurchaseOrder(payload);
    }
    if (res?.data && !res.error) {
      if (onSuccess) {
        onSuccess(res);
      } else {
        navigate(-1);
      }
    }
    return res;
  }, [savePurchaseOrder, navigate, createPurchaseOrder, purchaseOrder]);

  return (
    <SideModal open={true} width={500} onToggle={onToggle} {...props}>
      <PurchaseOrdersModalHeader title={title} onClose={onToggle} />
      <PurchaseOrdersForm part={part} />
      <SaveFooterBar visible fixed={false} onSubmit={onSubmitHandler} />
    </SideModal>
  );
};

const PurchaseOrdersModalHeader = ({ title = "Purchase Orders", onClose }) => {
  return (
    <ModalHeader className="bg-primary border-radius-0 px-3">
      <div className="text-white mb-0 ">{title}</div>
      <FontAwesomeIcon
        icon={faClose}
        size="lg"
        color="white"
        className="c-pointer"
        onClick={onClose}
      />
    </ModalHeader>
  );
};
