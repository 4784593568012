import React                          from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { QueryRoute }                 from "~/routers/QueryRoute/index.web";
import { PartsProvider }              from "~/contexts/PartsContext";
import { PartsEditScreen }            from "~/screens/PartsEditScreen/index.web";
import { PartsScreen }                from "~/screens/PartsScreen/index.web";
import { PartsModal }                 from "~/components/parts/PartsModal/index.web";
import { PartProvider }               from "~/contexts/PartContext";

export const PartsNavigator = () => {
  const navigate = useNavigate();
  return (
    <PartsProvider>
      <Routes>
        <Route path="/" element={<PartsScreen />} />
        <Route path="/:product_id" element={<PartsEditScreen />} />
      </Routes>
      <QueryRoute queryKey="modal" queryValue="new-part">
        <PartProvider>
          <PartsModal title="Create Part" onToggle={() => navigate(-1)} />
        </PartProvider>
      </QueryRoute>
    </PartsProvider>
  );
};
