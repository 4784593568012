import axios                                      from "../axios";
import { responseHandler, errorHandler }          from "../../utils/handlers";

import { PURCHASE_ORDERS_URI }                    from "../../constants/uri";
import { PAGINATION }                             from "../../constants/query";
import { buildParams }                            from "../../utils/params";

export async function getPurchaseOrders(params) {
  const {
    page = 1,
    per_page = 50,
    sort = "updated_at",
    order = "desc",
    filter = "",
    product_id = "",
  } = params || PAGINATION;

  const res = await axios
    .request(
      `${PURCHASE_ORDERS_URI}?${buildParams({
        page,
        per_page,
        sort,
        order,
        product_id,
        filter,
      })}`,
      {
        method: "GET",
      }
    )
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function getPurchaseOrder(id) {
  const res = await axios
    .request(`${PURCHASE_ORDERS_URI}/${id}`, {
      method: "GET",
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function createPurchaseOrder(data) {
  const res = await axios
    .request(`${PURCHASE_ORDERS_URI}`, {
      method: "POST",
      data: { purchase_order: data },
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function updatePurchaseOrder(id, data) {
  const res = await axios
    .request(`${PURCHASE_ORDERS_URI}/${id}`, {
      method: "PUT",
      data: { purchase_order: data },
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function deletePurchaseOrder(id) {
  const res = await axios
    .request(`${PURCHASE_ORDERS_URI}/${id}`, {
      method: "DELETE",
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}