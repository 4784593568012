import React, {
  useEffect,
  useContext,
  createContext,
  useState,
  useCallback,
} from "react";
import { toast }           from "react-toastify";

import { ProcessCRM }      from "~/services";

import { ContactsContext } from "./ContactsContext";

export const ContactContext = createContext({
  contact: {
    id: null,
    type: "contact",
    attributes: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      phone_e164: "",
      alt_phone: "",
      alt_phone_e164: "",
      new_lead: true,
    },
  },
});

export const ContactProvider = ({ children, value }) => {
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [contact, setContact] = useState(value);

  const { contacts } = useContext(ContactsContext);

  const getContact = useCallback(
    async (id) => {
      if (loading) return;
      setLoading(true);
      const res = await ProcessCRM.getContact(id);
      if (!res.error && res.data) {
        setContact({ ...res.data });
      } else {
        toast.error(res.message);
      }
      setLoading(false);
      return res;
    },
    [loading, setLoading, setContact]
  );

  const createContact = useCallback(
    async (data) => {
      if (saving) return;
      setSaving(true);
      const res = await ProcessCRM.createContact(data);
      if (res?.data && !res.error) {
        setContact(res?.data);
      } else if (res?.message) {
        toast(res?.error);
      }
      setSaving(false);
      return res
    },
    [saving, setSaving, setContact]
  );

  /** createQuote()
   *
   *
   */
  const createQuote = useCallback(
    async () => {
      if (!contact?.id) return;
      const res = await ProcessCRM.createQuoteForContact(contact?.id);
      if (res?.error && res?.message) {
        toast.error(res?.message);
      }
      return res;
    },
    [saving, contact, setContact]
  );

  const updateContact = useCallback(
    (data) => {
      setContact({
        ...contact,
        attributes: {
          ...contact.attributes,
          ...data,
        },
      });
      setDirty(true);
    },
    [contact, setContact, setDirty]
  );

  const saveContact = useCallback(
    async (data) => {
      if (saving && contact?.id) return;
      setSaving(true);
      const res = await ProcessCRM.updateContact(contact?.id, data);
      if (!res.error) {
        setContact(res.data);
        setDirty(false);
        toast.success("Successfully updated!");
      } else if (res?.message) {
        toast.error(res?.message);
      }
      setSaving(false);
      return res;
    },
    [saving, setSaving, contact, setContact, setDirty]
  );

  const deleteContact = useCallback(
    async (id) => {
      if (loading) return;
      setLoading(true);
      const res = await ProcessCRM.deleteContact(id);
      if (!res.error) {
        setContact(null);
      } else {
        toast.error(res?.message);
      }
      setLoading(false);
      // return res
    },
    [loading, setLoading, setContact]
  );

  const findById = useCallback(
    (id) => {
      let contact_id = id;

      if (contact_id instanceof String) {
        contact_id = parseInt(contact_id);
      }
      const filtered = contacts.filter((f) => f.id == contact_id);
      if (filtered?.length) {
        return filtered[0];
      }
      return null;
    },
    [contacts]
  );

  const setContactById = useCallback(
    async (id) => {
      setContact(findById(id));
      return getContact(id);
    },
    [setContact, getContact]
  );

  useEffect(() => {
    if (contact?.id) {
      getContact(contact?.id);
    }
  }, [contact?.id]);

  return (
    <ContactContext.Provider
      value={{
        dirty,
        saving,
        loading,
        contact,
        getContact,
        setContactById,
        setContact,
        createContact,
        createQuote,
        updateContact,
        saveContact,
        deleteContact,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};
