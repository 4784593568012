import React, { createContext, useState, useCallback } from "react";
import { ProcessCRM }                                  from "~/services";

export const PartsContext = createContext({});

export const PartsProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [term, setTerm] = useState(null);

  const [pagination, setPagination] = useState({
    per_page: 50,
    page: 1,
    total: 0,
    sort: "manufacturer_id",
    order: "asc",
  });

  const [parts, setParts] = useState([]);

  const setPart = useCallback(() => {
    (id, data) => {
      if (!parts || parts.length == 0) return;
      let _parts = parts?.concat();
      const idx = _parts.findIndex((c) => {
        return c.id && c.id === id;
      });

      if (idx && _parts[idx]) {
        _parts[idx] = {
          ..._parts[idx],
          ...data,
        };
        setParts(_parts);
      }
    };
  }, [parts, setParts]);

  const removePart = useCallback(() => {
    (id) => {
      let part_id = id;
      if (!id || !parts || parts.length == 0) return;

      if (part_id instanceof String) {
        part_id = parseInt(part_id);
      }

      setParts(parts.filter((f) => f.id !== part_id));
    };
  }, [parts, setParts]);

  const getParts = useCallback(
    async (query) => {
      setLoading(true);

      const res = await ProcessCRM.getParts({
        ...pagination,
        ...query,
      });

      setTerm(query?.term);

      if (res && !res.error && res.data) {
        setParts(res.data?.map((c) => ({ ...c, ...c.attributes })));
        setPagination({
          total: res.total_count,
          per_page: res.per_page,
          page: res.page,
          sort: res.sort,
          order: res.order,
        });
      } else {
        setError(res.error);
      }
      setLoading(false);
      return res;
    },
    [
      parts,
      term,
      loading,
      setLoading,
      setError,
      setTerm,
      pagination,
      setPagination,
      setParts,
    ]
  );

  const searchParts = useCallback(
    async (query) => {
      if (loading) return;
      setLoading(true);

      setTerm(query?.term);

      const res = await ProcessCRM.getParts({
        ...pagination,
        ...query,
      });

      if (res && !res.error && res.data) {
        setParts(res.data?.map((c) => ({ ...c, ...c.attributes })));
        setPagination({
          total: res.total_count,
          per_page: res.per_page,
          page: res.page,
          sort: res.sort,
          order: res.order,
        });
      } else {
        setError(res.error);
      }
      setLoading(false);
      return res;
    },
    [
      loading,
      term,
      setTerm,
      setLoading,
      setError,
      pagination,
      setPagination,
      setParts,
    ]
  );

  const updatePart = useCallback(
    async (data) => {
      if (saving) return;
      setSaving(true);
      // const res = await RentratoData.updatePart(data);
      // if (!res.error) {
      //  if(res.result?.id){
      //    setPart(res.result);
      //  }
      // } else {
      //   setError(res.error);
      // }
      setSaving(false);
      // return res
    },
    [saving, setSaving, setError, setPart]
  );

  const deletePart = useCallback(
    async (id) => {
      if (loading) return;
      setLoading(true);
      const res = await RentratoData.deletePart(id);
      if (!res.error) {
        removePart(id);
      } else {
        setError(res.error);
      }
      setLoading(false);
      // return res
    },
    [loading, setLoading, setError, setPart]
  );

  return (
    <PartsContext.Provider
      value={{
        error,
        saving,
        loading,
        term,
        ...pagination,
        parts,
        removePart,
        setPart,
        setTerm,
        getParts,
        searchParts,
        updatePart,
        deletePart,
      }}
    >
      {children}
    </PartsContext.Provider>
  );
};
