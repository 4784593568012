import React, { useCallback, useContext, useEffect } from "react";
import { Col, Row }                                  from "react-bootstrap";
import { ManufacturerTypeAhead }                     from "~/components/manufacturers/ManufacturerTypeAhead/index.web";
import { PurchaseOrderContext }                      from "~/contexts/PurchaseOrderContext";

export function PurchaseOrdersForm({ part = {} }) {
  const { purchaseOrder, updatePurchaseOrder } =
    useContext(PurchaseOrderContext);

  const { attributes } = purchaseOrder || {};

  const onChangeHandler = useCallback(
    (data) => {
      updatePurchaseOrder && updatePurchaseOrder(data);
    },
    [updatePurchaseOrder]
  );

  return (
    <div className="px-3 py-3">
      <Row>
        <Col sm={12}>
          <div className="form-group">
            <label className="text-xs text-dark">PO/Ref ID</label>
            <input
              type="text"
              name="ref_id"
              className="form-control"
              defaultValue={attributes?.ref_id}
              onChange={({ currentTarget: { value: ref_id } }) =>
                onChangeHandler({ ref_id })
              }
            />
          </div>
        </Col>

        <Col sm={12}>
          <div className="form-group">
            <label className="text-xs text-dark">Part Number*</label>
            <input
              type="text"
              disabled
              name="part_number"
              className="form-control"
              defaultValue={part?.attributes?.part_number}
              onChange={({ currentTarget: { value: part_number } }) =>
                onChangeHandler({ part_number })
              }
            />
          </div>
        </Col>

        <Col sm={12} md={6}>
          <div className="form-group">
            <label className="text-xs text-dark">Price</label>
            <input
              type="number"
              name="price"
              className="form-control"
              defaultValue={attributes?.price}
              onChange={({ currentTarget: { value: price } }) =>
                onChangeHandler({ price })
              }
            />
          </div>
        </Col>

        <Col sm={12} md={6}>
          <div className="form-group">
            <label className="text-xs text-dark">Quantity</label>
            <input
              type="number"
              name="quantity"
              min={1}
              className="form-control"
              defaultValue={attributes?.quantity}
              onChange={({ currentTarget: { value: quantity } }) =>
                onChangeHandler({ quantity })
              }
            />
          </div>
        </Col>

        <Col sm={12}>
          <div className="form-group">
            <label className="text-xs text-dark">Status*</label>
            <select
              className="form-control"
              name="status"
              defaultValue={attributes?.status}
              onChange={({ currentTarget: { value: status } }) => {
                onChangeHandler({ status });
              }}
            >
              <option value="draft">Draft</option>
              <option value="paid">Paid</option>
              <option value="received">Received</option>
            </select>
          </div>
        </Col>

        <Col sm={12}>
          <div className="form-group">
            <label className="text-xs text-dark">Shipping Tracking #</label>
            <input
              type="text"
              name="tracking_number"
              className="form-control"
              defaultValue={attributes?.tracking_number}
              onChange={({ currentTarget: { value: tracking_number } }) =>
                onChangeHandler({ tracking_number })
              }
            />
          </div>
        </Col>

        <Col sm={12}>
          <div className="form-group">
            <label className="text-xs text-dark">Notes</label>
            <textarea
              name="description"
              className="form-control"
              style={{ minHeight: 200 }}
              defaultValue={attributes?.notes}
              onChange={({ currentTarget: { value: notes } }) =>
                onChangeHandler({ notes })
              }
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}
