import React, { useContext } from "react";
import { ContactContext }    from "~/contexts/ContactContext";
import { Col, Row }          from "react-bootstrap";

export function ContactShippingForm({ onChange }) {
  const { contact } = useContext(ContactContext);
  const { attributes } = contact || {};

  return (
    <div>
      
      <div
        className="py-3 mb-3 flex-align-left"
        style={{ borderBottom: "1px solid #ececec" }}
      >
        <span className="text-bold text-primary">Shipping Address</span>
      </div>

      <Row>
        <Col md={9}>
          <div className="form-group">
            <label className="text-xs text-dark">Street Address</label>
            <input
              type="text"
              className="form-control"
              defaultValue={attributes?.address_street}
              onChange={({ currentTarget: { value: address_street } }) =>
                onChange({
                  address_street,
                })
              }
            />
          </div>
        </Col>
        <Col>
          <div className="form-group">
            <label className="text-xs text-dark">Unit/Suite #</label>
            <input
              type="text"
              className="form-control"
              defaultValue={attributes?.address_unit}
              onChange={({ currentTarget: { value: address_unit } }) =>
                onChange({
                  address_unit,
                })
              }
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="form-group">
            <label className="text-xs text-dark">City</label>
            <input
              type="text"
              className="form-control"
              defaultValue={attributes?.address_city}
              onChange={({ currentTarget: { value: address_city } }) =>
                onChange({
                  address_city,
                })
              }
            />
          </div>
        </Col>
        <Col>
          <div className="form-group">
            <label className="text-xs text-dark">State</label>
            <input
              type="text"
              maxLength={2}
              className="form-control text-uppercase"
              defaultValue={attributes?.address_state}
              onChange={({ currentTarget: { value: address_state } }) =>
                onChange({
                  address_state,
                })
              }
            />
          </div>
        </Col>

        <Col>
          <div className="form-group">
            <label className="text-xs text-dark">Zip Code</label>
            <input
              type="text"
              className="form-control"
              defaultValue={attributes?.address_zip}
              onChange={({ currentTarget: { value: address_zip } }) =>
                onChange({
                  address_zip,
                })
              }
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}
