import React, { useContext } from "react";
import { QuotesTable }       from "~/components/quotes/QuotesTable/index.web";
import { ContactContext }    from "~/contexts/ContactContext";
import { QuotesProvider }    from "~/contexts/QuotesContext";

export function ContactQuotesTab() {
  const { contact, loading } = useContext(ContactContext);
  return (
    <QuotesProvider>
      <div className="contact-quotes-tab py-3">
        <QuotesTable contact_id={contact?.id} />
      </div>
    </QuotesProvider>
  );
}
