import React, { createContext, useState, useCallback, useEffect } from "react";
import { ProcessCRM }                                             from "~/services";

export const PurchaseOrdersContext = createContext({});

export const PurchaseOrdersProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [purchaseOrders, setPurchaseOrders] = useState(false);

  const [pagination, setPagination] = useState({
    per_page: 50,
    page: 1,
    total: 0,
    sort: "updated_at",
    order: "desc",
  });

  const getPurchaseOrders = useCallback(
    async (query) => {
      if (loading) return;
      setLoading(true);
      const res = await ProcessCRM.getPurchaseOrders({
        ...pagination,
        ...query,
      });
      if (res?.data) {
        setPurchaseOrders(res?.data);
        setPagination({
          total: res.total_count,
          per_page: res.per_page,
          page: res.page,
          sort: res.sort,
          order: res.order,
        });
      } else {
        setError(res.error);
      }
      setLoading(false);
      return res;
    },
    [
      loading,
      pagination,
      setError,
      setPagination,
      setPurchaseOrders,
      setLoading,
    ]
  );

  return (
    <PurchaseOrdersContext.Provider
      value={{
        error,
        ...pagination,
        loading,
        getPurchaseOrders,
        purchaseOrders,
      }}
    >
      {children}
    </PurchaseOrdersContext.Provider>
  );
};
