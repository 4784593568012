import React              from "react";

export function SideModal({ width = 340, open, onToggle, children }) {
  return (
    <div
      className={`side-modal chatbox ${open ? "active" : ""}`}
      style={{ maxWidth:width, width:"100%", transition: "all 0.2s" }}
    >
      <div
        className="chatbox-close"
        style={{ right: width, transition: "none" }}
        onClick={onToggle}
      ></div>
      {children}
    </div>
  );
}
