import React, { createContext, useState, useCallback, useEffect } from "react";
import { ProcessCRM }                                             from "~/services";

export const OrdersContext = createContext({});

export const OrdersProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState(false);

  const [pagination, setPagination] = useState({
    per_page: 50,
    page: 1,
    total: 0,
    sort: "id",
    order: "desc",
  });

  const getOrders = useCallback(
    async (query) => {
      if (loading) return;
      setLoading(true);
      const res = await ProcessCRM.getOrders({
        ...pagination,
        ...query,
      });
      if (res?.data) {
        setOrders(res?.data);
        setPagination({
          total: res.total_count,
          per_page: res.per_page,
          page: res.page,
          sort: res.sort,
          order: res.order,
        });
      } else {
        setError(res.error);
      }
      setLoading(false);
      return res;
    },
    [loading, pagination, setError, setPagination, setOrders, setLoading]
  );

  return (
    <OrdersContext.Provider
      value={{
        error,
        ...pagination,
        loading,
        getOrders,
        orders,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};
