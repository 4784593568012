import React, { createContext } from "react";
import { useAuth }              from "~/hooks/useAuth";
import { noopFn }               from "~/helpers/context";

export const AuthContext = createContext({
  isAuthed: false,
  error: false,
  loading: false,
  logout: noopFn,
  login: noopFn,
});

export const AuthProvider = ({ children }) => {
  const auth = useAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
