export const BASE_URI = {
  // development: "https://api-process-crm.ngrok.io",
  development: "http://localhost:3001",
  staging:     "https://api-staging.processindustrial.net",
  production:  "https://api.processindustrial.net",
};

export const LOGIN_URI = "/v1/users/sign_in";
export const LOGOUT_URI = "/v1/users/sign_out";
export const USERS_URI = "/v1/users";
export const CONTACTS_URI = "/v1/contacts";
export const MANUFACTURERS_URI = "/v1/manufacturers";
export const PARTS_URI = "/v1/parts";
export const QUOTES_URI = "/v1/quotes";
export const ORDERS_URI = "/v1/orders";
export const PURCHASE_ORDERS_URI = "/v1/purchase_orders";
export const COMPANIES_URI = "/v1/companies";
