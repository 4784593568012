import React        from "react";
import { Tab, Nav } from "react-bootstrap";

export const TabSwitcher = ({
  tabs,
  pill,
  children,
  spacedTabs = false,
  backgroundColor = "",
  contentPadding = 0,
  mountOnEnter,
  unmountOnExit = false,
  initialKey,
  activeKey,
  onTabChange,
}) => {
  return (
    <div className="tab-switcher">
      <Tab.Container
        onSelect={onTabChange}
        mountOnEnter={mountOnEnter}
        unmountOnExit={unmountOnExit}
        defaultActiveKey={
          initialKey || tabs[0]?.key
        }
        activeKey={activeKey}
      >
        <Nav
          as="ul"
          className={`${pill ? "nav-pills" : "nav-tabs"}`}
          style={{
            backgroundColor,
            justifyContent: spacedTabs ? "space-around" : "inherit",
          }}
        >
          {tabs.map((data, i) => (
            <Nav.Item
              as="li"
              key={i}
              className={spacedTabs ? "col text-center px-0" : ""}
            >
              <Nav.Link
                eventKey={data.key}
                style={{ cursor: "pointer" }}
              >
                {data.name}
              </Nav.Link>
            </Nav.Item>
          ))}
          {children}
        </Nav>
        <Tab.Content
          className={`pt-${contentPadding} px-${contentPadding} flex-grow-1`}
        >
          {tabs.map((data, i) => {
            const Component = data.component;
            return (
              <Tab.Pane
                eventKey={data.key}
                className="h100"
                key={data.key}
              >
                {Component ? <Component /> : data.content}
              </Tab.Pane>
            );
          })}
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};
