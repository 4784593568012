// import Cookies          from "js-cookie";

import axiosClient  from "./axios";
import ApiClient    from "../utils/client";
import { BASE_URI } from "../constants/uri";

import apiMixin     from "./api/index";
import mixin        from "../utils/mixin";

const CONFIG_DEFAULTS = { mode: "production" };

let _instance;
class ProcessCRM extends ApiClient {
  constructor(config) {
    super(config);

    const settings = {
      ...CONFIG_DEFAULTS,
      ...config,
    };

    if (process.env.NODE_ENV !== "production") {
      console.debug(settings.mode, BASE_URI[settings.mode]);
    }
    // Set API Base URI
    this.base_url = BASE_URI[settings.mode];

    // Set Axios HTTP Client
    this._client = axiosClient;

    // Load credentials
    // this.loadCreds()

    if (_instance) {
      return _instance._init(settings);
    }
    _instance = this._init(settings);
  }

  // loadCreds(){
  //   const authHeaders = Cookies.get("authHeaders")
  //   if(authHeaders){
  //     try{
  //       const creds = JSON.parse(authHeaders)
  //       this.setCreds(creds)
  //     }catch(e){
  //       this.resetCreds()
  //     }
  //   }
  // }

  // setCreds(creds = {}) {
  //   if (!creds) return;

  //   if (creds && !creds instanceof Object) {
  //     console.error(
  //       "[ProcessCRM.setCreds]",
  //       "creds argument must be an Object"
  //     );
  //     return;
  //   }
  //   this._creds = creds;
  //   for (const key in creds) {
  //     if (Object.hasOwnProperty.call(creds, key)) {
  //       this._client.defaults.headers.common[key] = creds[key];
  //     }
  //   }
  // }

  // resetCreds() {
  //   Cookies.remove('authHeaders')
  //   this._creds = null;
  //   this._client.defaults.headers.common["access-token"] = null;
  //   this._client.defaults.headers.common["uid"] = null;
  //   this._client.defaults.headers.common["client"] = null;
  //   this._client.defaults.headers.common["token-type"] = null;
  //   this._client.defaults.headers.common["expiry"] = null;
  // }
}

mixin(apiMixin, ProcessCRM.prototype);
export default ProcessCRM;
