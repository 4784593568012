import React, { useContext, useCallback } from "react";
import { faClose }                        from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon }                from "@fortawesome/react-fontawesome";
import ModalHeader                        from "react-bootstrap/esm/ModalHeader";
import { SideModal }                      from "~/components/modals/SideModal/index.web";
import { PartsForm }                      from "../PartsForm/index.web";
import { SaveFooterBar }                  from "~/components/bars/SaveFooterBar/index.web";
import { PartContext }                    from "~/contexts/PartContext";
import { useNavigate }                    from "react-router-dom";

export const PartsModal = ({
  title = "Parts",
  onToggle,
  onSuccess,
  ...props
}) => {
  const navigate = useNavigate();
  const { part, createPart, savePart } = useContext(PartContext);

  const onSubmitHandler = useCallback(async () => {
    const { attributes } = part;
    let res;
    if (part?.id) {
      res = await savePart(attributes);
    } else {
      res = await createPart(attributes);
    }
    if (res?.data && !res.error) {
      if (onSuccess) {
        onSuccess(res);
      } else {
        navigate(`/parts/${res.data?.id}`, {
          replace: true,
          state: {
            part: res.data,
          },
        });
      }
    }
    return res;
  }, [savePart, createPart, part]);

  return (
    <SideModal open={true} width={500} onToggle={onToggle} {...props}>
      <PartsModalHeader title={title} onClose={onToggle} />
      <PartsForm />
      <SaveFooterBar visible fixed={false} onSubmit={onSubmitHandler} />
    </SideModal>
  );
};

const PartsModalHeader = ({ title = "Parts", onClose }) => {
  return (
    <ModalHeader className="bg-primary border-radius-0 px-3">
      <div className="text-white mb-0 ">{title}</div>
      <FontAwesomeIcon
        icon={faClose}
        size="lg"
        color="white"
        className="c-pointer"
        onClick={onClose}
      />
    </ModalHeader>
  );
};
