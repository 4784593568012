import React                  from "react";
import { TabSwitcher }        from "~/components/tabs/TabSwitcher/index.web";
import { ContactActivityTab } from "../tabs/ContactActivityTab/index.web";
import { ContactBillingTab }  from "../tabs/ContactBillingTab/index.web";
import { ContactInfoTab }     from "../tabs/ContactInfoTab/index.web";
import { ContactQuotesTab }   from "../tabs/ContactQuotesTab/index.web";
import { ContactOrdersTab }   from "../tabs/ContactOrdersTab/index.web";
import { SaveFooterBar }      from "~/components/bars/SaveFooterBar/index.web";

const TABS = [
  // {
  //   name: "Activity",
  //   key: "activity",
  //   content: <ContactActivityTab />,
  // },
  {
    name: "Details",
    key: "details",
    content: <ContactInfoTab />,
  },
  {
    name: "Quotes",
    key: "quotes",
    content: <ContactQuotesTab />,
  },
  {
    name: "Orders",
    key: "orders",
    content: <ContactOrdersTab />,
  },
  // {
  //   name: "Parts",
  //   key: "parts",
  //   content: <ContactInvoicesTab />,
  // },
];

export function ContactTabs({ ...props }) {
  return <TabSwitcher tabs={TABS} {...props} />;
}
