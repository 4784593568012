import React, { useCallback, useContext, useEffect } from "react";
import { StyleSheet }                                from "react-native";

import { DataTableBase }                             from "~/components/tables/DataTableBase/index.web";

import { LIGHT }                                     from "~/themes/colors";
import { Link, useNavigate }                         from "react-router-dom";
import { OrdersContext }                             from "~/contexts/OrdersContext";
import { dayjs }                                     from "~/helpers/day";
import { toDollars }                                 from "~/helpers/currency";

export const OrdersTable = ({
  style = {},
  contact_id,
  quote_id,
  product_id,
}) => {
  const navigate = useNavigate();

  const { loading, total, per_page, page, order, sort, orders, getOrders } =
    useContext(OrdersContext);

  useEffect(() => {
    getOrders({
      page,
      per_page,
      sort,
      order,
      contact_id,
      quote_id,
      product_id,
    });
  }, []);

  return (
    <div className=" mb-4">
      <DataTableBase
        heightOffset={150}
        sortServer
        progressPending={loading}
        pagination={total > per_page}
        paginationDefaultPage={page}
        paginationTotalRows={total}
        paginationPerPage={per_page}
        persistTableHead
        defaultSortField={sort}
        defaultSortAsc={order !== "asc" || order !== "ascending"}
        columns={OrdersTableColumns}
        data={orders}
        onSort={(column, order) =>
          getOrders({
            page: 1,
            sort: column.id,
            order,
            contact_id,
            quote_id,
            product_id,
          })
        }
        onChangeRowsPerPage={(per_page, current_page) =>
          getOrders({
            per_page,
            page: current_page,
            order,
            sort,
            quote_id,
            contact_id,
            product_id,
          })
        }
        onChangePage={(current_page) => {
          if (current_page !== page) {
            getOrders({
              per_page,
              page: current_page,
              order,
              sort,
              quote_id,
              contact_id,
              product_id,
            });
          }
        }}
        onRowClicked={(row) => {
          navigate(`/orders/${row.id}`, { state: { order: row } });
        }}
      />
    </div>
  );
};

export const OrdersTableColumns = [
  {
    id: "id",
    name: "Order #",
    selector: (row) => row.attributes?.order_no,
    cell: (row, index, c) => (
      <div>
        <span data-tag="allowRowEvents" className="mb-0">
          {c.selector(row) || "-"}
        </span>
      </div>
    ),
  },
  {
    id: "external_ref_no",
    name: "PO #",
    selector: (row) => row.attributes?.external_ref_no,
    cell: (row, index, c) => (
      <div>
        <span data-tag="allowRowEvents" className="mb-0">
          {c.selector(row) || "-"}
        </span>
      </div>
    ),
  },
  {
    id: "status",
    name: "Status",
    selector: (row) => row.attributes?.status,
    sortable: true,
    cell: (row, index, c) => (
      <div>
        <span data-tag="allowRowEvents" className="mb-0">
          {row.attributes?.status_label || "-"}
        </span>
      </div>
    ),
  },
  {
    id: "total_due",
    name: "Total Due",
    selector: (row) => row.attributes?.total_due,
    sortable: true,
    cell: (row, index, c) => (
      <div>
        <span data-tag="allowRowEvents" className="mb-0 text-bold">
          {toDollars(c.selector(row)) || "-"}
        </span>
      </div>
    ),
  },
  {
    id: "contact.full_name",
    name: "Contact",
    selector: (row) => row.attributes?.contact.full_name,
    sortable: true,
    cell: (row, index, c) => (
      <div>
        <Link
          to={`/contacts/${row.attributes?.contact?.id}`}
          state={{ contact: row?.attributes?.contact }}
          className="link"
        >
          {c.selector(row) || "-"}
        </Link>
      </div>
    ),
  },
  {
    id: "author.full_name",
    name: "Author",
    selector: (row) => row.attributes?.author?.full_name,
    sortable: true,
    cell: (row, index, c) => <div>{c.selector(row) || "-"}</div>,
  },
  {
    id: "updated_at",
    name: "Last Activity",
    selector: (row) => row.attributes?.updated_at,
    sortable: true,
    cell: (row, index, c) => (
      <div>
        <span data-tag="allowRowEvents" className="mb-0">
          {dayjs(c.selector(row)).format("MMM DD, YYYY hh:mma") || "-"}
        </span>
      </div>
    ),
  },
  {
    id: "created_at",
    name: "Date Created",
    selector: (row) => row.attributes?.created_at,
    sortable: true,
    cell: (row, index, c) => (
      <div>
        <span data-tag="allowRowEvents" className="mb-0">
          {dayjs(c.selector(row)).format("MMM DD, YYYY hh:mma") || "-"}
        </span>
      </div>
    ),
  },
];

const styles = StyleSheet.create({
  container: {},
  nameStyledText: {
    color: LIGHT.black,
    fontWeight: 500,
  },
  descriptionStyledText: {
    color: LIGHT.grey,
  },
  cellStyledText: {
    color: LIGHT.black,
  },
});
