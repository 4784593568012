import React, {
  useEffect,
  useContext,
  createContext,
  useState,
  useCallback,
} from "react";
import { toast }      from "react-toastify";

import { ProcessCRM } from "~/services";

const DEFAULT = { id: null, type: "product", attributes: {} };

export const PartContext = createContext({
  part: DEFAULT,
});

export const PartProvider = ({ children, value = DEFAULT }) => {
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [part, setPart] = useState(value);

  const getPart = useCallback(
    async (id) => {
      if (loading) return;
      setLoading(true);
      const res = await ProcessCRM.getPart(id);
      if (!res.error && res.data) {
        setPart({ ...res.data });
      } else if (res?.error && res?.message) {
        toast.error(res.message);
      }
      setLoading(false);
      return res;
    },
    [loading, setLoading, setPart]
  );

  const createPart = useCallback(
    async (data) => {
      if (saving) return;
      setSaving(true);
      const res = await ProcessCRM.createPart(data);
      if (res?.data?.id && !res.error) {
        setPart(res.data);
        toast.success("Successfully created!");
      }
      setSaving(false);
      return res;
    },
    [saving, setSaving, setPart]
  );

  const updatePart = useCallback(
    (data) => {
      setPart({
        ...part,
        attributes: {
          ...part.attributes,
          ...data,
        },
      });
    },
    [part, setPart]
  );

  const savePart = useCallback(
    async (data) => {
      if (saving) return;
      setSaving(true);
      const res = await ProcessCRM.updatePart(part?.id, data);
      if (res?.data?.id && !res.error) {
        setPart(res?.data);
        toast.success("Successfully updated!");
      } else if (res?.error && res?.message) {
        toast.error(res.message);
      }
      setSaving(false);
      return res;
    },
    [saving, setSaving, setPart]
  );

  const deletePart = useCallback(
    async (id) => {
      if (loading) return;
      setLoading(true);
      const res = await ProcessCRM.deletePart(id);
      if (res?.success) {
        setPart(null);
      } else if (res?.error && res?.message) {
        toast.error(res.message);
      }
      setLoading(false);
      return res;
    },
    [loading, setLoading, setPart]
  );

  useEffect(() => {
    if (part?.id) {
      getPart(part?.id);
    }
  }, [part?.id]);

  return (
    <PartContext.Provider
      value={{
        saving,
        loading,
        part,
        getPart,
        setPart,
        createPart,
        updatePart,
        savePart,
        deletePart,
      }}
    >
      {children}
    </PartContext.Provider>
  );
};
