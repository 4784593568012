import React, { useContext } from "react";
import { ContactContext }    from "~/contexts/ContactContext";
import { Col, Row }          from "react-bootstrap";

import { CompanyTypeAhead }  from "~/components/company/CompanyTypeAhead.web";
import { PhoneInput }        from "~/components/inputs/PhoneInput/index.web";

export function ContactForm({ onChange = () => {} }) {
  const { contact } = useContext(ContactContext);
  const { attributes } = contact || {};

  return (
    <div className="px-3 py-3">
      <Row>
        <Col sm={12} md={6}>
          <div className="form-group">
            <label className="text-xs text-dark">First Name*</label>
            <input
              type="text"
              className="form-control"
              defaultValue={attributes?.first_name}
              onChange={({ currentTarget: { value: first_name } }) =>
                onChange({ first_name })
              }
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="form-group">
            <label className="text-xs text-dark">Last Name</label>
            <input
              type="text"
              className="form-control"
              defaultValue={attributes?.last_name}
              onChange={({ currentTarget: { value: last_name } }) =>
                onChange({ last_name })
              }
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="form-group">
            <label className="text-xs text-dark">Email</label>
            <input
              type="text"
              className="form-control"
              defaultValue={attributes?.email}
              onChange={({ currentTarget: { value: email } }) =>
                onChange({ email })
              }
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="form-group">
            <label className="text-xs text-dark">Type</label>
            <select
              className="form-control"
              defaultValue={attributes?.contact_type}
              onChange={({ currentTarget: { value: contact_type } }) =>
                onChange({ contact_type })
              }
            >
              <option value="">Select a type</option>
              <option value="end_user">End User</option>
              <option value="reseller">Reseller</option>
              <option value="vendor">Vendor</option>
            </select>
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="form-group">
            <label className="text-xs text-dark">Phone</label>
            <PhoneInput
              name="phone"
              defaultValue={attributes?.phone || ""}
              onChange={onChange}
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="form-group">
            <label className="text-xs text-dark">Secondary Phone</label>
            <PhoneInput
              name="alt_phone"
              defaultValue={attributes?.alt_phone || ""}
              onChange={onChange}
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="form-group">
            <label className="text-xs text-dark">Company</label>
            <CompanyTypeAhead
              defaultValue={attributes?.company}
              onChange={({ company_name, company, company_id }) => {
                if (company) {
                  // Links to existing company
                  onChange({ company, company_id: company?.id });
                } else if (company_name) {
                  // Creates new company
                  onChange({ company_name, company_id: null });
                }
              }}
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="form-group">
            <label className="text-xs text-dark">Branch</label>
            <input
              type="text"
              className="form-control"
              defaultValue={attributes?.branch_id}
              onChange={({ currentTarget: { value: branch_id } }) =>
                onChange({ branch_id })
              }
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="form-group">
            <label className="text-xs text-dark">Title</label>
            <input
              type="text"
              className="form-control"
              defaultValue={attributes?.title}
              onChange={({ currentTarget: { value: title } }) =>
                onChange({ title })
              }
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}
