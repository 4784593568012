import React                 from "react";
import { ActivityIndicator } from 'react-native';

export default function SplashLoader() {
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <ActivityIndicator size="large" color="orange" />
    </div>
  );
}
