import React                      from "react";
import { Button, Col, Row }       from "react-bootstrap";
import { StyleSheet, View, Text } from "react-native";
import { Outlet, useNavigate }    from "react-router-dom";

import { Screen }                 from "~/components/layouts/Screen/index.web";
import { PartsTable }             from "~/components/parts/PartsTable/index.web";
import { PartsTypeAhead }         from "~/components/parts/PartsTypeAhead/index.web";

export const PartsScreen = ({ style = {} }) => {
  const navigate = useNavigate();
  return (
    <Screen>
      <View style={[styles.container]}>
        <Row className="mb-3">
          <Col sm={12} md={4}>
            <PartsTypeAhead
              icon
              showMenu={false}
              placeholder="Search parts by name"
              onChange={(part) => {
                navigate(`/parts/${part?.id}`, { state: { part } });
              }}
            />
          </Col>
          <Col className="text-right">
            <Button size="sm" onClick={() => navigate("/parts?modal=new-part")}>
              + New Part
            </Button>
          </Col>
        </Row>
        <PartsTable />
      </View>
      <Outlet />
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
});
