import React, { useCallback, useContext, useEffect } from "react";
import { PartsContext }                              from "~/contexts/PartsContext";
import { ScrollView, StyleSheet, View }              from "react-native";
import { DataTableBase }                             from "~/components/tables/DataTableBase/index.web";
import { LIGHT }                                     from "~/themes/colors";
import { useNavigate }                               from "react-router-dom";
import { toDollars }                                 from "~/helpers/currency";

export const PartsTable = () => {
  const navigate = useNavigate();

  const { loading, total, term, per_page, page, order, sort, parts, getParts } =
    useContext(PartsContext);

  useEffect(() => {
    getParts();
  }, []);

  console.log(term);

  return (
    <div className=" mb-4">
      <DataTableBase
        sortServer
        progressPending={loading}
        paginationDefaultPage={page}
        paginationTotalRows={total}
        paginationPerPage={per_page}
        persistTableHead
        defaultSortField={sort}
        defaultSortAsc={false}
        columns={PartsTableColumns}
        data={parts}
        onSort={(column, sortOrder) =>
          getParts({ page: 1, sort: column.id, order: sortOrder, term })
        }
        onChangeRowsPerPage={(per_page, current_page) =>
          getParts({ per_page, page: current_page, order, sort, term })
        }
        onChangePage={(current_page) => {
          if (current_page !== page) {
            getParts({ per_page, page: current_page, order, sort, term });
          }
        }}
        onRowClicked={(row) => {
          navigate(`/parts/${row.id}`, {
            state: { part: row },
          });
        }}
      />
    </div>
  );
};

export const PartsTableColumns = [
  {
    id: "id",
    name: "id",
    selector: (row) => row.id,
    cell: (row, index, c) => (
      <span data-tag="allowRowEvents" className="text-bold">
        {c.selector(row)}
      </span>
    ),
    omit: true,
  },
  {
    id: "part_number",
    name: "Part #",
    selector: (row) => row.part_number,
    sortable: true,
    cell: (row, index, c) => (
      <span data-tag="allowRowEvents" className="link text-bold  mb-0">
        {c.selector(row)}
      </span>
    ),
  },
  {
    id: "manufacturer.searchable",
    name: "Manufacturer",
    selector: (row) => row.manufacturer?.id,
    sortable: true,
    cell: (row, index, c) => (
      <span data-tag="allowRowEvents" className="link mb-0">
        {row.manufacturer?.name}
      </span>
    ),
  },
  {
    id: "product_type",
    name: "Type",
    selector: (row) => row.product_type?.label,
    sortable: true,
    cell: (row, index, c) => (
      <span data-tag="allowRowEvents" className="link mb-0">
        {c.selector(row)}
      </span>
    ),
  },
  {
    id: "av_count",
    name: "Availability",
    selector: (row) => row.av_count,
    sortable: true,
    cell: (row, index, c) => (
      <span data-tag="allowRowEvents" className="mb-0">
        {c.selector(row)}
      </span>
    ),
  },
  {
    id: "qc_count",
    name: "Quotes",
    selector: (row) => row.qc_count,
    sortable: true,
    cell: (row, index, c) => (
      <View>
        <span data-tag="allowRowEvents" className="mb-0">
          {c.selector(row)}
        </span>
      </View>
    ),
  },
  {
    id: "oc_count",
    name: "Orders",
    selector: (row) => row.oc_count,
    sortable: true,
    cell: (row, index, c) => (
      <span data-tag="allowRowEvents" className=" mb-0">
        {c.selector(row)}
      </span>
    ),
  },
  {
    id: "po_count",
    name: "POs",
    selector: (row) => row.po_count,
    sortable: true,
    cell: (row, index, c) => (
      <span data-tag="allowRowEvents" className=" mb-0">
        {c.selector(row)}
      </span>
    ),
  },
  {
    id: "average_price",
    name: "Avg. Quote ($)",
    sortable: true,
    selector: (row) => row.average_price,
    sortable: true,
    cell: (row, index, c) => (
      <View>
        <span data-tag="allowRowEvents" className="mb-0 text-bold">
          {toDollars(parseFloat(row.average_price)) || "-"}
        </span>
      </View>
    ),
  },
  {
    id: "average_cost",
    name: "Avg. Cost ($)",
    sortable: true,
    selector: (row) => row.average_cost,
    sortable: true,
    cell: (row, index, c) => (
      <View>
        <span data-tag="allowRowEvents" className="mb-0 text-bold">
          {toDollars(parseFloat(row.average_cost)) || "-"}
        </span>
      </View>
    ),
  },
];

const styles = StyleSheet.create({
  container: {},
  nameStyledText: {
    color: LIGHT.black,
    fontWeight: 500,
  },
  descriptionStyledText: {
    color: LIGHT.grey,
  },
  cellStyledText: {
    color: LIGHT.black,
  },
});
