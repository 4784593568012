import axios                             from "../axios";
import { responseHandler, errorHandler } from "../../utils/handlers";
import { COMPANIES_URI }                 from "../../constants/uri";


export async function getCompanies() {
  let res = await axios
    .request(`${COMPANIES_URI}`, {
      method: "POST",
      data: { email, password },
    })
    .then(responseHandler)
    .catch(errorHandler);

  return res;
}

export async function searchCompanies(data) {
  const res = await axios
    .request(`${COMPANIES_URI}/search`, {
      method: "GET",
      params: data,
    })
    .then(responseHandler)
    .catch(errorHandler);

  return res;
}
