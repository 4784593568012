const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  // minimumSignificantDigits: 1,
  // maximumSignificantDigits: 4
});

export const toDollars = (value) => {
  value = parseFloat(value)
  if (value || value == 0) {
    return formatter.format(value);
  }
  return value;
};
