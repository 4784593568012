import React, { useCallback, useContext, useEffect } from "react";
import { ScrollView, StyleSheet, View }              from "react-native";

import { ContactsContext }                           from "~/contexts/ContactsContext";
import { DataTableBase }                             from "~/components/tables/DataTableBase/index.web";

import { LIGHT }                                     from "~/themes/colors";
import { useNavigate }                               from "react-router-dom";
import { FontAwesomeIcon }                           from "@fortawesome/react-fontawesome";
import { faBolt }                                    from "@fortawesome/pro-light-svg-icons";

export const ContactsTable = ({ style = {} }) => {
  const navigate = useNavigate();

  const { loading, total, per_page, page, order, sort, contacts, getContacts } =
    useContext(ContactsContext);

  useEffect(() => {
    getContacts();
  }, []);

  return (
    <div className=" mb-4">
      <DataTableBase
        heightOffset={250}
        sortServer
        progressPending={loading}
        paginationDefaultPage={page}
        paginationTotalRows={total}
        paginationPerPage={per_page}
        persistTableHead
        defaultSortField={sort}
        defaultSortAsc={order !== "asc" || order !== "ascending"}
        columns={ContactsTableColumns}
        data={contacts}
        onSort={(column, order) =>
          getContacts({ page: 1, sort: column.id, order })
        }
        onChangeRowsPerPage={(per_page, current_page) =>
          getContacts({ per_page, page: current_page, order, sort })
        }
        onChangePage={(current_page) => {
          if (current_page !== page) {
            getContacts({ per_page, page: current_page, order, sort });
          }
        }}
        onRowClicked={(row) => {
          navigate(`/contacts/${row.id}`, { state: { contact: row } });
        }}
      />
    </div>
  );
};

export const ContactsTableColumns = [
  {
    id: "id",
    name: "id",
    selector: (row) => row.id,
    cell: (row, index, c) => (
      <span data-tag="allowRowEvents" className="text-bold">
        {c.selector(row)}
      </span>
    ),
    omit: true,
  },
  {
    id: "contacts.last_name",
    name: "Name",
    selector: (row) => row.attributes.full_name,
    sortable: true,
    cell: (row, index, c) => (
      <span data-tag="allowRowEvents" className="text-bold link mb-0">
        {c.selector(row)}
        {row.attributes.new_lead && (
          <FontAwesomeIcon icon={faBolt} className="ml-2 text-success" />
        )}
      </span>
    ),
  },
  {
    id: "branch_id",
    name: "Branch",
    selector: (row) => row?.attributes?.branch_id,
    sortable: true,
    cell: (row, index, c) => (
      <span data-tag="allowRowEvents" className=" mb-0">
        {c.selector(row) || "-"}
      </span>
    ),
  },
  {
    id: "company_id",
    name: "Company",
    selector: (row) => row.attributes.company?.name,
    sortable: true,
    cell: (row, index, c) => (
      <span data-tag="allowRowEvents" className=" mb-0">
        {c.selector(row)}
      </span>
    ),
  },
  {
    id: "contact_type",
    name: "Type",
    selector: (row) => row.attributes.contact_type,
    sortable: true,
    cell: (row, index, c) => (
      <span data-tag="allowRowEvents" className=" mb-0">
        {row.attributes.contact_type_label}
      </span>
    ),
  },
  {
    id: "title",
    name: "Title",
    selector: (row) => row.attributes.title,
    sortable: true,
    cell: (row, index, c) => (
      <span data-tag="allowRowEvents" className="mb-0">
        {c.selector(row) || "-"}
      </span>
    ),
  },
  {
    id: "phone",
    name: "Phone",
    selector: (row) => row.attributes.phone,
    cell: (row, index, c) => (
      <span data-tag="allowRowEvents" className=" mb-0">
        {c.selector(row)}
      </span>
    ),
  },
  {
    id: "alt_phone",
    name: "Secondary Phone",
    selector: (row) => row.attributes.alt_phone,
    cell: (row, index, c) => (
      <span data-tag="allowRowEvents" className=" mb-0">
        {c.selector(row)}
      </span>
    ),
  },
  {
    id: "email",
    name: "Email",
    selector: (row) => row.attributes.email,
    cell: (row, index, c) => (
      <span data-tag="allowRowEvents" className=" mb-0">
        {c.selector(row)}
      </span>
    ),
  },
];

const styles = StyleSheet.create({
  container: {},
  nameStyledText: {
    color: LIGHT.black,
    fontWeight: 500,
  },
  descriptionStyledText: {
    color: LIGHT.grey,
  },
  cellStyledText: {
    color: LIGHT.black,
  },
});
