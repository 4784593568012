import React, { useContext } from "react";
import { Col, Row }          from "react-bootstrap";
import styled                from "styled-components";
import { QuoteContext }      from "~/contexts/QuoteContext";
import { toDollars }         from "~/helpers/currency";

const QuoteLedgerContainer = styled.div`
  input.tax-rate {
    width: 100%;
    border: 1px solid #ddd;
    padding: 4px;
    border-radius: 2px;
  }

  .spacer,
  .total {
    max-width: 100px;
  }

  @media print {
    .tax_rate {
      display: none;
    }
    input {
      border: none;
    }
  }
`;

export const QuoteLedger = () => {
  const { quote, ledger, dispatch } = useContext(QuoteContext);

  const onTaxRateChange = ({ currentTarget: { value } }) => {
    dispatch({
      type: "TAX_UPDATED",
      payload: value,
    });
  };

  return (
    <QuoteLedgerContainer>
      <Row className="py-2">
        <Col />
        <Col>
          <Row className="py-1 tax_rate">
            <Col className="text-right">Tax Rate:</Col>
            <Col className="text-left total">
              <input
                onChange={onTaxRateChange}
                className="tax-rate"
                value={quote?.attributes?.tax_rate}
              />
            </Col>
          </Row>
          <Row className="py-1">
            <Col className="text-right">Tax Due:</Col>
            <Col className="text-left total">
              {toDollars(
                (quote?.attributes?.tax_rate / 100) * ledger?.subtotal
              )}
            </Col>
          </Row>
          <Row className="py-1">
            <Col className="text-right">Subtotal:</Col>
            <Col className="text-left total">{toDollars(ledger?.subtotal)}</Col>
          </Row>
          <Row className="py-1">
            <Col className="text-right">Amount Due:</Col>
            <Col className="text-left total">
              {toDollars(ledger?.total_due)}
            </Col>
          </Row>
        </Col>
        <Col className="spacer" />
      </Row>
    </QuoteLedgerContainer>
  );
};
