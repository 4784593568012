import React, { useContext }    from "react";
import { faSearch }             from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon }      from "@fortawesome/react-fontawesome";
import { Typeahead, withAsync } from "react-bootstrap-typeahead";
import { PartsContext }         from "~/contexts/PartsContext";

const AsyncTypeAhead = withAsync(Typeahead);

export const PartsTypeAhead = ({
  onChange,
  showMenu = true,
  icon = false,
  placeholder,
}) => {
  const { parts = [], loading, getParts } = useContext(PartsContext);

  return (
    <div className="parts-type-ahead" style={{ position: "relative" }}>
      <AsyncTypeAhead
        id="parts-search-input"
        isLoading={loading}
        renderMenu={showMenu ? null : () => null}
        labelKey="part_number"
        searchText="Searching..."
        placeholder={placeholder}
        useCache={false}
        onInputChange={(value) => {
          if (!value?.length) {
            getParts({
              page: 1,
              sort: "manufacturer_id",
              per_page: 20,
            });
          }
        }}
        onSearch={(value) =>
          getParts({
            term: value,
            page: 1,
            sort: "manufacturer_id",
            per_page: 20,
          })
        }
        onChange={(selected) => {
          if (selected?.length && selected[0]?.id) {
            onChange && onChange(selected[0]);
          }
        }}
        options={parts}
      />
      {icon && !loading && (
        <FontAwesomeIcon
          icon={faSearch}
          className="text-gray"
          style={{ position: "absolute", top: 11, right: 15 }}
        />
      )}
    </div>
  );
};
