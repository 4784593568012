import React                            from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RootNavigator }                from "./src/navigators/index.web";

import "react-toastify/dist/ReactToastify.css";
import "~/css/style.css";
import "~/css/base.css";
import "~/css/components.css";

import { LoginScreen }                  from "./src/screens/LoginScreen/index.web";
import { SplashScreen }                 from "./src/screens/SplashScreen/index.web";

import { AppProvider }                  from "./src/contexts/AppContext";
import { AuthProvider }                 from "./src/contexts/AuthContext";
import { UserProvider }                 from "./src/contexts/UserContext";
import { AuthRoute }                    from "./src/routers/AuthRoute/index.web";
import { LogoutScreen }                 from "./src/screens/LogoutScreen/index.web";
import { ToastContainer }               from "react-toastify";

export default function App() {
  return (
    <AppProvider>
      <SplashScreen>
        <UserProvider>
          <AuthProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/logout" element={<LogoutScreen />} />
                <Route path="/login" element={<LoginScreen />} />
                <Route
                  path="/*"
                  element={
                    <AuthRoute>
                      <RootNavigator />
                    </AuthRoute>
                  }
                />
              </Routes>
            </BrowserRouter>
          </AuthProvider>
        </UserProvider>
      </SplashScreen>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
      />
    </AppProvider>
  );
}
