import { useContext, useState, useCallback } from "react";
import { UserContext }                       from "../contexts/UserContext";
import { Storage }                           from "~/helpers/storage";
import { ProcessCRM }                        from '~/services/index';

/**
 * useAuth()
 * Hook that handles authorization state
 *
 * @export
 * @return { isAuthed, error, loading, logout, login }
 */
export function useAuth() {
  const { user, resetUser, updateUser } = useContext(UserContext);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const login = useCallback(
    async ({ email, password }) => {
      if (loading) return;
      setLoading(true);
      const res = await ProcessCRM.login({ email, password });
      if (res?.data) {
        updateUser(res.data, true);
        setError(null);
      } else if (res?.error) {
        setError(res.details);
      }
      setLoading(false);
      return res;
    },
    [loading, setLoading, setError, updateUser]
  );

  const logout = useCallback(async () => {
    const res = await ProcessCRM.logout();
    Storage.clear();
    resetUser();
    return res;
  }, [resetUser]);

  return {
    isAuthed: !!user?.id,
    error,
    loading,
    logout,
    login,
  };
}
