import { faSearch }                                        from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon }                                 from "@fortawesome/react-fontawesome";
import debounce                                            from "lodash.debounce";
import React, { useState, useContext, useEffect, useMemo } from "react";
import { Typeahead }                                       from "react-bootstrap-typeahead";
import { ContactsContext }                                 from "~/contexts/ContactsContext";

export const ContactsTypeAhead = ({ onChange, icon = false, placeholder }) => {
  const { contacts, searchContacts } = useContext(ContactsContext);

  const debouncedSearch = useMemo(() => debounce(searchContacts, 500), []);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  return (
    <div className="contacts-type-ahead" style={{ position: "relative" }}>
      <Typeahead
        id="contact-input"
        labelKey="first_name"
        style={{}}
        placeholder={placeholder}
        onKeyDown={(keyPress) => {
          if (keyPress.target.value.length) {
            debouncedSearch({
              term: keyPress.target.value,
              page: 1,
              per_page: 50,
            });
          }
        }}
        onChange={(selected) => {
          if (selected?.length && selected[0]?.id) {
            onChange && onChange(selected[0]);
          }
        }}
        options={contacts.map((c) => ({ ...c, ...c.attributes })) || []}
      />
      {icon && (
        <FontAwesomeIcon
          icon={faSearch}
          className="text-gray"
          style={{ position: "absolute", top: 11, right: 15 }}
        />
      )}
    </div>
  );
};
