import React                       from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import { ContactsNavigator }       from "./ContactsNavigator/index.web";
import { DashboardNavigator }      from "./DashboardNavigator/index.web";
import { Header }                  from "~/components/nav/Header/index.web";
import { VendorsNavigator }        from "./VendorsNavigator/index.web";
import { QuotesNavigator }         from "./QuotesNavigator/index.web";
import { PurchaseOrdersNavigator } from "./PurchaseOrdersNavigator/index.web";
import { PartsNavigator }          from "./PartsNavigator/index.web";
import { OrdersNavigator }         from "./OrdersNavigators/index.web";
import { ToastContainer }          from "react-toastify";

export const RootNavigator = ({ navigation }) => {
  return (
    <div
      data-sidebar-style="full"
      data-layout="vertical"
      className="root-container"
    >
      <div id="main-wrapper" className="menu-toggle show">
        <Header />
        <div className="content-body scroll-y">
          <Routes>
            <Route path="/contacts/*" element={<ContactsNavigator />} />
            <Route path="/vendors/*" element={<VendorsNavigator />} />
            <Route path="/quotes/*" element={<QuotesNavigator />} />
            <Route path="/parts/*" element={<PartsNavigator />} />
            <Route path="/orders/*" element={<OrdersNavigator />} />
            <Route
              path="/purchase-orders/*"
              element={<PurchaseOrdersNavigator />}
            />
            {/* <Route path="/settings/*" element={<SettingsNavigator />} /> */}
            {/* <Route path="/account/*" element={<ContactsNavigator />} /> */}
            <Route path="/dashboard/*" element={<DashboardNavigator />} />
            <Route path="*" element={<Navigate to="/contacts" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};
