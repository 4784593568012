import dayjs              from "dayjs";
import localizedFomat     from "dayjs/plugin/localizedFormat";
import duration           from "dayjs/plugin/duration";
import relativeTime       from "dayjs/plugin/relativeTime";

dayjs.extend(localizedFomat)
dayjs.extend(duration)
dayjs.extend(relativeTime)

export {
  dayjs
}