import axios from "axios";

export function createClient(options = {}) {
  const client = axios.create();

  client.defaults.headers.common["Content-Type"] = "application/json";
  client.defaults.headers.common["Accept"] = "application/json";
  client.defaults.withCredentials = options.withCreds == true ? true : false;
  client.defaults.timeout = 15000;
  client.defaults.baseURL = options.baseUrl;

  return client;
}
