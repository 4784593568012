import React, { useContext }                   from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Screen }                              from "~/components/layouts/Screen/index.web";
import SplashLoader                            from "~/components/loaders/SplashLoader/index.web";
import { PartDetails }                         from "~/components/parts/PartDetails/index.web";
import { PartTabs }                            from "~/components/parts/PartTabs/index.web";
import { PurchaseOrdersModal }                 from "~/components/purchase_orders/PurchaseOrderModal/index.web";
import { PartContext, PartProvider }           from "~/contexts/PartContext";
import { PurchaseOrderProvider }               from "~/contexts/PurchaseOrderContext";
import { QueryRoute }                          from "~/routers/QueryRoute/index.web";

export const PartsEditScreen = ({ style = {} }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { product_id } = useParams();

  return (
    <PartProvider
      value={{
        id: product_id,
        ...state?.part,
      }}
    >
      <Screen fluid>
        <PartsView />
      </Screen>
      <QueryRoute queryKey="modal" queryValue="new-purchase-order">
        <PurchaseOrderProvider>
          <PurchaseOrdersModal
            title="Create Purchase Order"
            onToggle={() => navigate(-1)}
          />
        </PurchaseOrderProvider>
      </QueryRoute>
    </PartProvider>
  );
};

const PartsView = () => {
  const { loading } = useContext(PartContext);
  if (loading) {
    return (
      <div className="h-100 w-100">
        <SplashLoader />
      </div>
    );
  }
  return (
    <div style={{ flex: 1, height: "100%" }}>
      <PartDetails />
      <PartTabs initialKey="history" />
    </div>
  );
};
