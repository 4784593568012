import React, { useContext, useState } from "react";
import { Col, Row }                    from "react-bootstrap";
import styled                          from "styled-components";
import { QuoteContext }                from "~/contexts/QuoteContext";
import logo                            from "~/assets/logo-black.png";
import { dayjs }                       from "~/helpers/day";

const QuoteHeaderContainer = styled.div`
  font-size: 13px;

  .text-sm {
    font-size: 12px;
  }

  @media print {
    .link,
    .btn,
    input {
      display: none;
    }
  }
  .logo {
    height: 40px;
  }
`;

export const QuoteHeader = () => {
  const { quote } = useContext(QuoteContext);

  return (
    <QuoteHeaderContainer>
      <div className="mb-3">
        <div className="py-3">
          <img className="logo" src={logo} />
        </div>
        <Row>
          <Col>
            <div className="py-2">
              
              <div className="text-bold">
                {"processindustrialsupply.com"}
              </div>
              <div className="text-bold mb-2">
                {"(214) 775-0025"}
              </div>

              <div className="text-bold">
                {dayjs(quote?.attributes?.created_at).format("LL")}
              </div>
              <div className="text-bold mb-2">
                Quote #: {quote?.attributes?.quote_no}
              </div>
              
              <div className="mb-2">
                <div className="text-bold">
                  {quote?.attributes?.author?.full_name}
                </div>
                <div className="text-sm">
                  {quote?.attributes?.author?.email}
                </div>
                <div className="text-sm">
                  {quote?.attributes?.author?.phone}
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <QuoteContact />
          </Col>
        </Row>
      </div>
    </QuoteHeaderContainer>
  );
};

const QuoteContact = () => {
  const { quote } = useContext(QuoteContext);
  const [editMode, toggleEdit] = useState(false);
  const { contact, shipping_addr_street } = quote?.attributes || {};

  return (
    <div className="py-2">
      <div className="text-right">
        <div className="mb-2">
          <div>Attn: {contact?.full_name}</div>
          <div className="text-bold">{contact?.company?.name}</div>
        </div>
        <div>
          <div>
            {editMode ? (
              <ShippingAddressEditor onToggle={() => toggleEdit(false)} />
            ) : (
              <>
                {shipping_addr_street ? (
                  <QuoteShippingAddress />
                ) : (
                  <ContactShippingAddress />
                )}
                <div>
                  <span
                    className="ml-2 text-primary link"
                    onClick={() => toggleEdit(true)}
                  >
                    <small>Change Shipping Address</small>
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ShippingAddressContainer = styled.div`
  font-size: 13px;
  @media print {
    .link {
      display: none;
    }
  }
`;

const QuoteShippingAddress = () => {
  const { quote } = useContext(QuoteContext);

  const {
    shipping_addr_city: city = "",
    shipping_addr_state: state = "",
    shipping_addr_street: street = "",
    shipping_addr_suite: unit = "",
    shipping_addr_zip_code: zip = "",
  } = quote?.attributes || {};

  return (
    <ShippingAddressContainer>
      <div>
        <span className="text-bold">Ship to:</span>
      </div>
      <div>
        <span>{`${street || ""} ${unit || ""}`.trim()}</span>
      </div>
      <div>
        {`${city ? `${city},` : ""} ${state || ""} ${zip || ""}`.trim()}
      </div>
    </ShippingAddressContainer>
  );
};

const ContactShippingAddress = () => {
  const { quote } = useContext(QuoteContext);

   const {
    address_street: street = "",
    address_city: city = "",
    address_state: state = "",
    address_unit: unit = "",
    address_zip: zip = "",
  } = quote?.attributes?.contact || {};

  return (
    <ShippingAddressContainer>
      <div>
        <span className="text-bold">Ship to:</span>
      </div>
      <div>
        <span>{`${street || ""} ${unit || ""}`.trim()}</span>
      </div>
      <div>
        {`${city ? `${city},` : ""} ${state || ""} ${zip || ""}`.trim()}
      </div>
    </ShippingAddressContainer>
  );
};

const ShippingAddressEditorContainer = styled.div`
  font-size: 12px;
  label {
    margin-bottom: 0px;
    font-size: 10px;
    padding: 0 4px;
  }
  input {
    width: 100%;
    padding: 4px;
  }

  .btn {
    min-height: 28px;
    padding: 4px 8px;
  }
`;

const ShippingAddressEditor = ({ onToggle }) => {
  const { quote, dispatch } = useContext(QuoteContext);

  const {
    address_street: street,
    address_city: city,
    address_state: state,
    address_unit: unit,
    address_zip: zip,
  } = quote?.attributes?.contact || {};

  const {
    shipping_addr_city,
    shipping_addr_state,
    shipping_addr_street,
    shipping_addr_suite,
    shipping_addr_zip_code,
  } = quote?.attributes || {};

  const [address, setAddress] = useState({
    shipping_addr_city: shipping_addr_city || city,
    shipping_addr_state: shipping_addr_state || state,
    shipping_addr_street: shipping_addr_street || street,
    shipping_addr_suite: shipping_addr_suite || unit,
    shipping_addr_zip_code: shipping_addr_zip_code || zip,
  });

  const onChange = ({ currentTarget: { name, value } }) => {
    setAddress({
      ...address,
      [name]: value,
    });
  };

  const onSubmit = () => {
    dispatch({
      type: "ADDRESS_UPDATED",
      payload: address,
    });
    onToggle();
  };

  const onCancel = () => {
    onToggle();
  };

  return (
    <ShippingAddressEditorContainer>
      <Row>
        <Col>
          <label>street</label>
          <div>
            <input
              className="form-control"
              name="shipping_addr_street"
              defaultValue={address?.shipping_addr_street}
              onChange={onChange}
            />
          </div>
        </Col>
        <Col md={3}>
          <label>suite</label>
          <div>
            <input
              className="form-control"
              name="shipping_addr_suite"
              defaultValue={address?.shipping_addr_suite}
              onChange={onChange}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <label>city</label>
          <div>
            <input
              className="form-control"
              name="shipping_addr_city"
              defaultValue={address?.shipping_addr_city}
              onChange={onChange}
            />
          </div>
        </Col>
        <Col md={3}>
          <label>state</label>
          <div>
            <input
              className="form-control"
              name="shipping_addr_state"
              defaultValue={address?.shipping_addr_state}
              onChange={onChange}
            />
          </div>
        </Col>
        <Col md={3}>
          <label>zip</label>
          <div>
            <input
              className="form-control"
              name="shipping_addr_zip_code"
              defaultValue={address?.shipping_addr_zip_code}
              onChange={onChange}
            />
          </div>
        </Col>
      </Row>
      <button className="btn btn-sm btn-white my-2 mr-2" onClick={onCancel}>
        <small className="text-bold">Cancel</small>
      </button>
      <button className="btn btn-sm btn-primary my-2" onClick={onSubmit}>
        <small className="text-bold">Update</small>
      </button>
    </ShippingAddressEditorContainer>
  );
};
