import { faTrashAlt }                               from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon }                          from "@fortawesome/react-fontawesome";
import React, { useCallback, useContext, useState } from "react";
import { Button, Col, Container, Row }              from "react-bootstrap";
import { Link, Route, Routes, useNavigate }         from "react-router-dom";
import { toast }                                    from "react-toastify";
import styled                                       from "styled-components";
import { SaveFooterBar }                            from "~/components/bars/SaveFooterBar/index.web";
import SplashLoader                                 from "~/components/loaders/SplashLoader/index.web";
import { ShipmentModal }                            from "~/components/shipments/ShipmentModal/index.web";
import { OrderContext }                             from "~/contexts/OrderContext";
import { usePrompt }                                from "~/hooks/usePrompt";
import { ProcessCRM }                               from "~/services";
import { OrderDetails }                             from "../OrderDetails/index.web";
import { OrderHeader }                              from "../OrderHeader/index.web";
import { OrderItems }                               from "../OrderItems/index.web";
import { OrderLedger }                              from "../OrderLedger/index.web";
import { OrderNotes }                               from "../OrderNotes/index.web";
import { OrderStatus }                              from "../OrderStatus/index.web";
import { OrderTerms }                               from "../OrderTerms/index.web";

export const OrderEditor = () => {
  const { order, loading, updateOrder } = useContext(OrderContext);
  const navigate = useNavigate();

  usePrompt(
    "Are you sure? All changes will be lost if you don't save.",
    order?.dirty
  );

  if (loading) {
    return (
      <div className="d-flex flex-align-center">
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            height: "calc(100vh - 75px)",
          }}
        >
          <SplashLoader />
        </div>
      </div>
    );
  }

  return (
    <OrderEditorContainer>
      <Row>
        <Col>
          <OrderNav />
          <OrderToolBar />
          <div className="bg-white px-4 py-4">
            <OrderHeader />
            <OrderItems />
            <OrderLedger />
            <OrderTerms />
            <OrderNotes />
          </div>
          <SaveFooterBar visible={order?.dirty} onSubmit={updateOrder} />
        </Col>
        <Col className="d-sm-none d-md-block right-col  mt-0">
          <OrderDetails />
        </Col>
      </Row>
      <Routes>
        <Route
          path="/new-shipment"
          element={
            <ShipmentModal onClose={() => navigate(`/orders/${order?.id}`)} />
          }
        />
        <Route
          path="/shipments/:shipment_id"
          element={
            <ShipmentModal
              editMode={false}
              onClose={() => navigate(`/orders/${order?.id}`)}
            />
          }
        />
      </Routes>
    </OrderEditorContainer>
  );
};

export const OrderNav = () => {
  const { order } = useContext(OrderContext);

  return (
    <div className="breadcrumb bg-light py-2 px-0 fs-12">
      <Link to="/orders" className="text-primary">
        Orders
      </Link>
      <span className="px-2">{">"}</span>
      <span className="text-bold">{order?.attributes?.order_no}</span>
    </div>
  );
};

const OrderToolBar = () => {
  const { order } = useContext(OrderContext);
  const navigate = useNavigate();
  const [deleting, setDeleting] = useState(false);

  const onDelete = async () => {
    if (deleting || !order?.id) return;
    const confirmed = confirm("Are you sure you want to delete this order?");
    if (confirmed) {
      setDeleting(true);
      const res = await ProcessCRM.deleteOrder(order?.id);

      if (res?.success) {
        navigate("/orders");
      } else if (res?.error) {
        toast.error(res.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
      setDeleting(false);
    }
  };

  return (
    <OrderToolsContainer>
      <Row>
        <Col sm={6} md={3}>
          <Row className="mx-0">
            <Col style={{ maxWidth: 40 }} className="flex-align-center">
              <small className="text-bold">Status:</small>
            </Col>
            <Col>
              <OrderStatus />
            </Col>
          </Row>
        </Col>
        <Col>
          <div className="text-right">
            <Button
              size="sm"
              className="mr-2"
              variant="light border-gray"
              onClick={() => navigate(`/orders/${order.id}/new-shipment`)}
            >
              Create Shipment
            </Button>
            <Button
              size="sm"
              className="mr-2"
              variant="light border-gray"
              onClick={() => window.print()}
            >
              Print
            </Button>
            <Button
              size="sm"
              variant="outline-danger border-0"
              className="mr-2"
              onClick={onDelete}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
              <span className="ml-1">Delete</span>
            </Button>
          </div>
        </Col>
      </Row>
    </OrderToolsContainer>
  );
};

const OrderToolsContainer = styled.div`
  margin-bottom: 10px;
  @media print {
    display: none;
  }
`;

const OrderEditorContainer = styled.div`
  @page {
    margin: 0;
  }

  min-width: 1440px;

  .right-col {
    max-width: 400px;
    border-left: 1px solid #eee;
    padding: 0.5rem;
  }

  @media print {
    min-width: inherit;

    .right-col,
    .breadcrumb {
      display: none !important;
    }
  }
`;
