export const buildParams = (data) => {
  return Object.keys(data)
    .map((key) => data[key] ? key + "=" + data[key] : "")
    .filter(p => !!p)
    .join("&");
};

export function getFormData(object) {
  let formData = new FormData();
  Object.keys(object).forEach(key => {
    if(object[key]){
      formData.append(key, object[key])
    }
  });
  // for (var key of formData.entries()) {
  //   console.log(key[0] + ', ' + key[1])
  // }
  return formData;
}
