import React, { useCallback, useContext, useState } from "react";
import { SaveFooterBar }                            from "~/components/bars/SaveFooterBar/index.web";
import { ContactContext }                           from "~/contexts/ContactContext";
import { ContactForm }                              from "../../ContactForm/index.web";
import { ContactShippingForm }                      from "../../ContactShippingForm/index.web";

export const ContactInfoTab = () => {
  const { loading, saveContact } = useContext(ContactContext);

  const [changes, setChanges] = useState({});

  const onChangeHandler = useCallback(
    (data) => {
      const attributes = { ...changes, ...data };
      setChanges(attributes);
    },
    [changes, setChanges]
  );

  const onSubmitHandler = useCallback(async () => {
    const res = await saveContact(changes);
    if (res && !res.error) {
      setChanges({});
    }
  }, [changes, setChanges]);

  if (loading) {
    return null;
  }

  return (
    <div className="contact-info-tab py-4 mb-5">
      <ContactForm onChange={onChangeHandler} />
      <ContactShippingForm onChange={onChangeHandler} />
      <SaveFooterBar
        onSubmit={onSubmitHandler}
        visible={Object.keys(changes).length > 0}
      />
    </div>
  );
};
