import React                     from "react";
import { TabSwitcher }           from "~/components/tabs/TabSwitcher/index.web";
import { PartQuotesTab }         from "../tabs/PartQuotesTab/index.web";
import { PartOrdersTab }         from "../tabs/PartOrdersTab/index.web";
import { PartInfoTab }           from "../tabs/PartInfoTab/index.web";
import { PartPriceHistoryTab }   from "../tabs/PartPriceHistoryTab/index.web";
import { PartPurchaseOrdersTab } from "../tabs/PartPurchaseOrders/index.web";

const TABS = [
  {
    name: "Pricing",
    key: "history",
    content: <PartPriceHistoryTab />,
  },
  {
    name: "Quotes",
    key: "quotes",
    content: <PartQuotesTab />,
  },
  {
    name: "Orders",
    key: "orders",
    content: <PartOrdersTab />,
  },
  {
    name: "Purchase Orders",
    key: "purchase-orders",
    content: <PartPurchaseOrdersTab />,
  },
  {
    name: "Details",
    key: "details",
    content: <PartInfoTab />,
  },
];

export function PartTabs({ ...props }) {
  return <TabSwitcher tabs={TABS} {...props} />;
}
