import React                      from "react";
import { StyleSheet, View, Text } from "react-native";
import { Screen }                 from "~/components/layouts/Screen/index.web";
import { QuotesTable }            from "~/components/quotes/QuotesTable/index.web";

export const QuotesScreen = ({ style = {} }) => {
  return (
    <Screen fluid noPadding>
      <View style={[styles.container]}>
        {/* <h4 className="mb-3 px-2">Quotes</h4> */}
        <QuotesTable />
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
});
