import React, { useContext } from "react";
import { View }              from "react-native";

export function Screen({ children, fluid, noPadding = false, style }) {
  return (
    <div
      style={{ ...style, height:"100%", flex: 1 }}
      className={`${!!fluid ? `container-fluid ` : " "}${
        !!noPadding ? "px-0 py-0 " : " "
      }`}
    >
      {children}
    </div>
  );
}
