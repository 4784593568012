import React, { createContext, useState, useEffect, useCallback } from "react";
import { RentratoCRM }                                            from "~/services";

import { Storage }                                                from "~/helpers/storage";
import { getCredentials }                                         from "~/helpers/auth/index.web";

export const AppContext = createContext(DEFAULT_STATE);

export const AppProvider = ({ children }) => {
  const [ctx, setCtx] = useState(DEFAULT_STATE);
  const [isReady, setReady] = useState(false);

  const setContext = (context) => {
    setCtx({ ...ctx, ...context });
  };

  useEffect(() => {
    (async () => {
      const prevState = await getPrevState();
      // const creds = await getCredentials();
      // if (creds) {
      //   RentratoCRM.setCreds(creds);
      // }
      setContext({ prevState });
      setReady(true);
    })();
  }, []);

  useEffect(() => {
    (async () => {})();
  }, []);

  // useEffect(() => {
  //   console.log("[AppContext]", ctx);
  // }, [ctx]);

  return (
    <AppContext.Provider
      value={{
        ...ctx,
        isReady,
        setContext,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const getPrevState = async () => {
  const user = await Storage.getData("uu");
  const settings = (await Storage.getData("SETTINGS")) || {};
  const onboardStep = (await Storage.get("OB_STEP")) || 1;
  return {
    user,
    settings,
    onboardStep,
  };
};

const DEFAULT_STATE = {
  prevState: {
    user: null,
    onboardStep: 1,
    settings: {},
  },
};
