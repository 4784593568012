import axios                             from "../axios";
import { responseHandler, errorHandler } from "../../utils/handlers";
import { LOGIN_URI, LOGOUT_URI }         from "../../constants/uri";


export async function login({ email, password }) {
  let res = await axios
    .request(`${LOGIN_URI}`, {
      method: "POST",
      data: { email, password },
    })
    .then(responseHandler)
    .catch(errorHandler);

  return res;
}

export async function logout() {
  const res = await axios
    .request(`${LOGOUT_URI}`, {
      method: "DELETE",
      data: {},
    })
    .then(responseHandler)
    .catch(errorHandler);

  return res;
}
