import React, { Component }        from "react";
import { Outlet, useSearchParams } from "react-router-dom";

export const QueryRoute = ({ queryKey, queryValue, children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  if (!queryKey) {
    throw "queryKey was not defined on QueryRoute";
  }
  if (!queryValue) {
    throw "queryValue was not defined on QueryRoute";
  }

  if (searchParams.has(queryKey) && searchParams.get(queryKey) == queryValue) {
    return children ? children : <Outlet />;
  }

  return <Outlet />;
};
