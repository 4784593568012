import { faClose }                      from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon }              from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import ModalHeader                      from "react-bootstrap/esm/ModalHeader";
import { useNavigate }                  from "react-router-dom";
import { toast }                        from "react-toastify";
import { SaveFooterBar }                from "~/components/bars/SaveFooterBar/index.web";
import { SideModal }                    from "~/components/modals/SideModal/index.web";
import { QueryRoute }                   from "~/routers/QueryRoute/index.web";
import { ProcessCRM }                   from "~/services";
import { ContactForm }                  from "../ContactForm/index.web";

export function ContactModal({ onClose, ...props }) {
  const navigate = useNavigate();
  const [contact, setContact] = useState({});

  const onChange = useCallback(
    (data) => {
      setContact({
        ...contact,
        ...data,
      });
    },
    [contact, setContact]
  );

  const onSubmit = useCallback(async () => {
    if (!contact?.first_name) {
      toast.error("First name is required!");
      return;
    }
    if (!contact?.contact_type) {
      toast.error("Contact type is required!");
      return;
    }

    const res = await ProcessCRM.createContact(contact);

    if (res?.data?.id) {
      toast.success("Contact successfully created!");
      navigate(`/contacts/${res?.data?.id}`, {
        replace: true,
        state: res?.data,
      });
    }
  }, [contact]);

  return (
    <SideModal {...props} width={500} onToggle={onClose}>
      <ModalHeader className="bg-primary border-radius-0 px-3">
        <div className="text-white mb-0 ">New Contact</div>
        <FontAwesomeIcon
          icon={faClose}
          size="lg"
          color="white"
          className="c-pointer float-right"
          onClick={onClose}
        />
      </ModalHeader>
      <ContactForm createMode onChange={onChange} />
      <SaveFooterBar visible fixed={false} onSubmit={onSubmit} />
    </SideModal>
  );
}

export const ContactCreateModal = (props) => {
  return (
    <QueryRoute queryKey="modal" queryValue="new-contact">
      <ContactModal title="Create Contact" {...props} />
    </QueryRoute>
  );
};
