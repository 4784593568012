import React, { useContext } from "react";
import styled                from "styled-components";
import { QuoteContext }      from "~/contexts/QuoteContext";

const QuoteNotesContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  textarea {
    width: 100%;
    min-height: 200px;
    padding: 8px;
    font-size: 12px;
  }
  p {
    display: none;
  }

  @media print {
    display: none;
  }
`;

export const QuoteNotes = () => {
  const { quote, dispatch } = useContext(QuoteContext);

  const onChange = ({ currentTarget: { value } }) => {
    dispatch({
      type: "NOTES_UPDATED",
      payload: value,
    });
  };

  return (
    <QuoteNotesContainer>
      <div className="mb-4">Notes</div>
      <textarea onChange={onChange} value={quote?.attributes?.notes || ""} />
      <p
        dangerouslySetInnerHTML={{
          __html: quote?.attributes?.notes?.replace(/\n/g, "<br>"),
        }}
      />
    </QuoteNotesContainer>
  );
};
