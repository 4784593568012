import { faEdit, faUserEdit } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon }    from "@fortawesome/react-fontawesome";
import React, { useContext }  from "react";
import { Button, Col, Row }   from "react-bootstrap";
import { useNavigate }        from "react-router-dom";
import { toast }              from "react-toastify";
import { ContactContext }     from "~/contexts/ContactContext";
import { dayjs }              from "~/helpers/day";

export const ContactDetails = ({ onEdit }) => {
  const { contact, createQuote } = useContext(ContactContext);
  const { attributes } = contact || {};
  const navigate = useNavigate();

  const createQuoteHandler = async () => {
    const res = await createQuote();
    console.log(res)
    if (res?.data?.id) {
      navigate(`/quotes/${res?.data?.id}`, { state: { quote: res?.data } });
    }
  };

  return (
    <div className="contact-details mb-4">
      <Row>
        <Col>
          <div className="name h4  mb-0 text-bold">
            <span>{attributes?.first_name} </span>
            <span>{attributes?.last_name}</span>
          </div>
          <div className="title mb-1">
            {attributes?.title && <span>{attributes?.title}</span>}
            {attributes?.company?.name && (
              <>
                {attributes?.title && " - "}
                <span className="company text-primary link">
                  {attributes?.company?.name}
                </span>
              </>
            )}
          </div>

          <div className="email text-sm">
            <span>{attributes?.email}</span>
          </div>

          {attributes?.phone && (
            <div className="phone text-sm">
              <span className="mr-2">{attributes?.phone}</span>
              <small>main</small>
            </div>
          )}

          {attributes?.alt_phone && (
            <div className="alt_phone text-sm">
              <span className="mr-2">{attributes?.alt_phone}</span>
              <small>secondary</small>
            </div>
          )}
        </Col>
        <Col className="text-right fs-12">
          <div>
            <button
              className="btn btn-xs btn-light border-gray fs-12"
              onClick={createQuoteHandler}
            >
              Create Quote
            </button>
          </div>
          {/* <div className="created_at text-sm text-dark">
            <span>
              created {dayjs(attributes?.created_at).format("LL hh:mm a")}
            </span>
          </div>
          <div className="created_at text-sm text-dark">
            <span>
              updated {dayjs(attributes?.updated_at).format("LL hh:mm a")}
            </span>
          </div> */}
        </Col>
        {/* <Col className="text-right">
          <h6 className="text-bold text-primary">Billing Information</h6>
          <div className="addr_street">
            <span>{attributes?.address?.street}</span>
            {attributes?.address?.unit && <span>#{attributes?.address?.unit}</span>}
          </div>
          {attributes?.address?.city && attributes?.address?.state && (
            <div className="addr_city_state">
              <span>{attributes?.address?.city}, </span>
              <span>{attributes?.address?.state} </span>
              <span>{attributes?.address?.zip_code}</span>
            </div>
          )}
        </Col> */}
      </Row>
    </div>
  );
};
