import React, { useContext, useState } from "react";
import styled                          from "styled-components";
import { PartContext }                 from "~/contexts/PartContext";
import { SaveFooterBar }               from "~/components/bars/SaveFooterBar/index.web";
import { ManufacturerTypeAhead }       from "~/components/manufacturers/ManufacturerTypeAhead/index.web";
import { usePrompt }                   from "~/hooks/usePrompt";

export const PartInfoTab = () => {
  const { part, loading, savePart, updatePart } = useContext(PartContext);
  const [dirty, setDirty] = useState(false);

  const onChangeHandler = ({ currentTarget: { name, value } }) => {
    updatePart({
      [name]: value,
    });
    setDirty(true);
  };

  const onManufacturerChange = async (manufacturer) => {
    const { id, name } = manufacturer;

    if (id) {
      updatePart({
        manufacturer_id: id,
        manufacturer_name: null,
        manufacturer,
      });
    } else {
      updatePart({
        manufacturer_id: null,
        manufacturer_name: name,
        manufacturer,
      });
    }
    setDirty(true);
  };

  const onSubmit = async () => {
    const { description, part_number, manufacturer_id, manufacturer_name } =
      part?.attributes || {};
    const res = await savePart({
      description,
      part_number,
      manufacturer_id,
      manufacturer_name,
    });
    if (res?.data && !res.error) {
      setDirty(false);
    }
    return res;
  };

  usePrompt("Are you sure? All changes will be lost if you don't save.", dirty);

  if (loading) return null;

  return (
    <PartInfoContainer className="part-info-tab py-4 px-2">
      <div className="mb-3">
        <h6>Manufacturer</h6>
        <ManufacturerTypeAhead
          defaultValue={part?.attributes?.manufacturer}
          onChange={onManufacturerChange}
        />
      </div>
      <div className="mb-3">
        <h6>Part Number</h6>
        <input
          className="form-control"
          name="part_number"
          value={part?.attributes?.part_number}
          onChange={onChangeHandler}
        />
      </div>
      <div className="mb-3">
        <h6>Description</h6>
        <textarea
          className="form-control"
          name="description"
          value={part?.attributes?.description}
          onChange={onChangeHandler}
        />
      </div>
      <SaveFooterBar visible={dirty} onSubmit={onSubmit} />
    </PartInfoContainer>
  );
};

const PartInfoContainer = styled.div`
  textarea {
    width: 100%;
    min-height: 200px;
    padding: 8px;
    font-size: 12px;
  }

  input {
    padding: 8px;
    min-width: 200px;
  }
`;
