import React, { useContext, useEffect }   from "react";
import { StyleSheet }                     from "react-native";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { LIGHT }                          from "~/themes/colors";
import { DataTableBase }                  from "~/components/tables/DataTableBase/index.web";

import { dayjs }                          from "~/helpers/day";
import { toDollars }                      from "~/helpers/currency";

export const PartPriceHistoryTable = ({ data, part, loading = false }) => {
  return (
    <div className=" mb-4">
      <DataTableBase
        loading={loading}
        heightOffset={150}
        pagination={false}
        persistTableHead
        columns={HistoryTableColumns}
        data={data?.map((d) => ({ ...d, part: part?.attributes })) || []}
      />
    </div>
  );
};

export const HistoryTableColumns = [
  {
    id: "display_name",
    name: "Line Item",
    selector: (row) => row.display_name,
    cell: (row, index, c) => (
      <div>
        <span data-tag="allowRowEvents" className="mb-0">
          {row.display_name || row.part?.part_number || "-"}
        </span>
      </div>
    ),
  },
  {
    id: "price",
    name: "Unit Price ($)",
    selector: (row) => row.price,
    cell: (row, index, c) => (
      <div>
        <span data-tag="allowRowEvents" className="mb-0">
          {toDollars(row.price) || "-"}
        </span>
      </div>
    ),
  },
  {
    id: "quantity",
    name: "Qty",
    selector: (row) => row.quantity,
    cell: (row, index, c) => (
      <div>
        <span data-tag="allowRowEvents" className="mb-0">
          {row.quantity || "-"}
        </span>
      </div>
    ),
  },
  {
    id: "total",
    name: "Total ($)",
    selector: (row) => row.total,
    cell: (row, index, c) => (
      <div>
        <span data-tag="allowRowEvents" className="mb-0">
          {toDollars(row.total) || "-"}
        </span>
      </div>
    ),
  },
  {
    id: "date",
    name: "Date Quoted",
    selector: (row) => row.created_at,
    cell: (row, index, c) => (
      <div>
        <span data-tag="allowRowEvents" className="mb-0">
          {dayjs(c.selector(row)).format("LL") || "-"}
        </span>
      </div>
    ),
  },
  {
    id: "quote_id",
    selector: (row) => row.quote_id,
    cell: (row, index, c) => (
      <div className="w-100 text-right">
        <Link to={`/quotes/${row.quote_id}`} className="mb-0 link text-right">
          View Quote
        </Link>
      </div>
    ),
  },
];

const styles = StyleSheet.create({
  container: {},
  nameStyledText: {
    color: LIGHT.black,
    fontWeight: 500,
  },
  descriptionStyledText: {
    color: LIGHT.grey,
  },
  cellStyledText: {
    color: LIGHT.black,
  },
});
