import AsyncStorage from "@react-native-async-storage/async-storage";

const setData = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value);
    await AsyncStorage.setItem(key, jsonValue);
  } catch (e) {
    console.error(e)
  }
};

const getData = async (key) => {
  try {
    const jsonValue = await AsyncStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    console.error(e)
    return null
  }
};

const set = async (key, value) => {
  try {
    return await AsyncStorage.setItem(key, value);
  } catch (e) {
    console.error(e)
  }
};

const get = async (key) => {
  try {
    return await AsyncStorage.getItem(key);
  } catch (e) {
    console.error(e)
    return null
  }
};

const remove = async (key) => {
  try {
    return await AsyncStorage.removeItem(key);
  } catch (e) {
    console.error(e)
  }
};

const clear = async (key) => {
  try {
    return await AsyncStorage.clear();
  } catch (e) {
    console.error(e)
  }
};

export const Storage = {
  getData,
  setData,
  get,
  set,
  remove,
  clear
}