import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row }                         from "react-bootstrap";
import { ActivityIndicator }                                   from "react-native";
import { Link, useNavigate }                                   from "react-router-dom";
import { toast }                                               from "react-toastify";
import styled                                                  from "styled-components";
import { SaveFooterBar }                                       from "~/components/bars/SaveFooterBar/index.web";
import SplashLoader                                            from "~/components/loaders/SplashLoader/index.web";
import { QuoteContext }                                        from "~/contexts/QuoteContext";
import { usePrompt }                                           from "~/hooks/usePrompt";
import { ProcessCRM }                                          from "~/services";
import { QuoteDetails }                                        from "../QuoteDetails/index.web";
import { QuoteHeader }                                         from "../QuoteHeader/index.web";
import { QuoteItems }                                          from "../QuoteItems/index.web";
import { QuoteLedger }                                         from "../QuoteLedger/index.web";
import { QuoteNotes }                                          from "../QuoteNotes/index.web";
import { QuoteStatus }                                         from "../QuoteStatus/index.web";
import { QuoteTerms }                                          from "../QuoteTerms/index.web";

export const QuoteEditor = () => {
  const { quote, loading, updateQuote } = useContext(QuoteContext);

  usePrompt(
    "Are you sure? All changes will be lost if you don't save.",
    quote?.dirty
  );

  if (loading) {
    return (
      <div className="d-flex flex-align-center">
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            height: "calc(100vh - 75px)",
          }}
        >
          <SplashLoader />
        </div>
      </div>
    );
  }

  return (
    <QuoteEditorContainer>
      <Row>
        <Col>
          <QuoteNav />
          <QuoteToolBar />
          <div className="bg-white px-4 py-4">
            <QuoteHeader />
            <QuoteItems />
            <QuoteLedger />
            <QuoteTerms />
            <QuoteNotes />
          </div>
          <SaveFooterBar visible={quote?.dirty} onSubmit={updateQuote} />
        </Col>
        {quote?.id && (
          <Col className="d-sm-none d-md-block right-col p-0 mt-0">
            <QuoteDetails />
          </Col>
        )}
      </Row>
    </QuoteEditorContainer>
  );
};

export const QuoteNav = () => {
  const { quote } = useContext(QuoteContext);

  return (
    <div className="breadcrumb bg-light py-2 px-0 fs-12">
      <Link to="/quotes" className="text-primary">
        Quotes
      </Link>
      <span className="px-2">{">"}</span>
      <span className="text-bold">{quote?.attributes?.quote_no}</span>
    </div>
  );
};

const QuoteToolBar = () => {
  return (
    <QuoteToolsContainer>
      <Row>
        <Col sm={6} md={4}>
          <Row className="mx-0">
            <Col style={{ maxWidth: 40 }} className="flex-align-center">
              <small className="text-bold">Status:</small>
            </Col>
            <Col>
              <QuoteStatus />
            </Col>
          </Row>
        </Col>
        <Col>
          <div className="text-right">
            <CreatePartButton />
            <CreateOrderButton />
            <Button size="sm" onClick={() => window.print()}>
              Print
            </Button>
          </div>
        </Col>
      </Row>
    </QuoteToolsContainer>
  );
};

const CreatePartButton = () => {
  const navigate = useNavigate();
  const { quote } = useContext(QuoteContext);

  const onClickHandler = useCallback(async () => {
    navigate(`/quotes/${quote?.id}/edit?modal=new-part`);
  }, [navigate]);

  return (
    <Button
      className="mr-2 btn-light border-gray text-dark"
      size="sm"
      onClick={onClickHandler}
    >
      + Create New Part
    </Button>
  );
};

const CreateOrderButton = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { quote } = useContext(QuoteContext);

  const createOrder = useCallback(async () => {
    setLoading(true);
    const res = await ProcessCRM.createOrderFromQuote(quote?.id);
    if (res?.data) {
      navigate(`/orders/${res?.data.id}`, {
        state: {
          order: res?.data,
        },
      });
    } else if (res?.error) {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
    setLoading(false);
    return res;
  }, [navigate, quote, setLoading]);

  return (
    <Button
      className="mr-2 btn-light border-gray text-dark"
      size="sm"
      onClick={createOrder}
    >
      {loading ? <ActivityIndicator color="white" /> : "Create an Order"}
    </Button>
  );
};

const QuoteToolsContainer = styled.div`
  margin-bottom: 10px;
  @media print {
    display: none;
  }
`;

const QuoteEditorContainer = styled.div`
  @page {
    margin: 0;
  }

  min-width: 1440px;

  .right-col {
    max-width: 400px;
  }

  @media print {
    min-width: inherit;
    .right-col,
    .breadcrumb {
      display: none !important;
    }
  }
`;
