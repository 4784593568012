import { createClient } from "./axios"
import { BASE_URI }     from "../constants/uri";

const CONFIG_DEFAULTS = { mode: "production", token: "" };

class ApiClient {
  constructor(config = CONFIG_DEFAULTS) {
    const settings = {
      ...CONFIG_DEFAULTS,
      ...config,
    };
    
    this.base_url = BASE_URI[settings.mode];
    this._init(settings)
  }

  _init(config) {
    this._setEnv(config.mode);

    this._token = config.token;

    if (!this._client) { this._client = createClient(); } 

    this._client.defaults.baseURL = this.base_url

    if (this._token) {
      this._client.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this._token}`;
    } else {
      delete this._client.defaults.headers.common["Authorization"];
    }

    return this;
  }

  _setEnv(env) {
    switch (env) {
      case "production":
        this._mode = env;
        break;
      case "staging":
        this._mode = env;
        break;
      default:
        this._mode = "production";
        break;
    }

    return this._mode;
  }

  token(token) {
    if (token) {
      this._token = token;
      this._client.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this._token}`;
    }
    return this._token;
  }

  resetToken() {
    this._token = null;
    delete this._client.defaults.headers.common["Authorization"];
  }

  client() {
    return this._client;
  }

  mode(mode) {
    if (mode) {
      this._setEnv(mode);
    }
    return this._mode;
  }
}

export default ApiClient;
