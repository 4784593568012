import React, { useContext, useEffect, useState } from "react";
import SplashLoader                               from "../../components/loaders/SplashLoader/index.web";
import { AppContext }                             from "~/contexts/AppContext";

export function SplashScreen({ children }) {
  const { isReady } = useContext(AppContext);
  const [hideSplash, setHidden] = useState(false);

  useEffect(() => {
    if (isReady && !hideSplash) {
      setTimeout(() => {
        setHidden(true);
      }, 1500);
    }
  }, [isReady]);

  if (!hideSplash) {
    return <SplashLoader />;
  }

  return children;
}
