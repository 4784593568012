const initialState = {
  id: null,
  type: "order",
  attributes: {
    id: null,
    order_no: "",
    external_ref_no: "",
    date: null,
    notes: "",
    status: "draft",
    freight_account: "",
    terms: "",
    contact: null,
    quote: null,
    shipping_fees: 0,
    shipping_method: "",
    order_items: [],
    shipments: [],
    subtotal: 0,
    tax_rate: 0,
    total_due: 0,
    shipping_fees: 0,
    shipping_addr_state: "",
    shipping_addr_city: "",
    shipping_addr_street: "",
    shipping_addr_suite: "",
    shipping_addr_zip_code: "",
    created_at: null,
    updated_at: null,
  },
};

export const orderReducer = (state = initialState, action = {}) => {
  const { type, payload, internalId } = action || {};

  if (type) {
    console.log(`[orderReducer:${type}]`, payload);
  }

  switch (type) {
    case "ORDER_FETCH_SUCCESS": {
      return {
        ...state,
        ...payload,
        dirty: false,
      };
    }

    case "ORDER_SAVE_SUCCESS": {
      return {
        ...state,
        ...payload,
        dirty: false,
      };
    }

    case "ORDER_SAVE_FAILED": {
      return {
        ...state,
        ...payload,
        dirty: true,
      };
    }

    case "ORDER_NUMBER_UPDATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          order_no: payload,
        },
        dirty: true,
      };
    }

    case "PO_NUMBER_UPDATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          external_ref_no: payload,
        },
        dirty: true,
      };
    }

    case "RECIPIENT_UPDATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          recipient_name: payload,
        },
        dirty: true,
      };
    }

    case "DATE_UPDATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          date: payload,
        },
        dirty: true,
      };
    }

    case "STATUS_UPDATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          status: payload,
        },
        dirty: true,
      };
    }

    case "DATE_UPDATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          date: payload,
        },
        dirty: true,
      };
    }

    case "NOTES_UPDATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          notes: payload,
        },
        dirty: true,
      };
    }

    case "TERMS_UPDATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          terms: payload,
        },
        dirty: true,
      };
    }

    case "FREIGHT_UPDATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          freight_account: payload,
        },
        dirty: true,
      };
    }

    case "TERMS_UPDATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          terms: payload,
        },
        dirty: true,
      };
    }

    case "ADDRESS_UPDATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          ...payload,
        },
        dirty: true,
      };
    }

    case "TAX_UPDATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          tax_rate: payload || 0,
        },
        dirty: true,
      };
    }

    case "SHIPMENT_CREATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          shipments: [payload, ...(state?.attributes?.shipments || [])],
        },
      };
    }

    case "SHIPMENT_DELETED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          shipments: state?.attributes?.shipments.filter((shipment) => {
            if (shipment?.id == payload.id) {
              return false;
            }
            return true;
          }),
        },
      };
    }

    case "ITEM_DELETED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          order_items: state?.attributes.order_items.filter(
            (item) => item.id !== payload.id
          ),
        },
      };
    }

    case "ITEM_UPDATED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          order_items: state?.attributes.order_items.map((item) => {
            if (item.id === payload.id) {
              return {
                ...item,
                ...payload,
              };
            } else {
              return item;
            }
          }),
        },
        dirty: true,
      };
    }

    case "ITEM_ADD_SUCCESS": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          order_items: state?.attributes.order_items.map((item) => {
            if (item.id === internalId) {
              const { pending, saving, id, ...itemData } = item;
              return {
                ...itemData,
                ...payload,
              };
            } else {
              return item;
            }
          }),
        },
      };
    }

    case "ITEM_UPDATE_SUCCESS": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          order_items: state?.attributes.order_items.map((item) => {
            if (item.id === payload.id) {
              const { saving, id, ...itemData } = item;
              return {
                ...itemData,
                ...payload,
              };
            } else {
              return item;
            }
          }),
        },
      };
    }

    case "ITEM_UPDATE_FAILED": {
      return {
        ...state,
        attributes: {
          ...state?.attributes,
          order_items: state?.attributes.order_items.map((item) => {
            if (item.id === payload.id) {
              const { saving, id, ...itemData } = item;
              return {
                ...itemData,
                ...payload,
              };
            } else {
              return item;
            }
          }),
        },
      };
    }

    default: {
      return state;
    }
  }
};
