import * as auth            from "./auth";
import * as users           from "./users";
import * as contacts        from "./contacts";
import * as parts           from "./parts";
import * as manufacturers   from "./manufacturers";
import * as companies       from "./companies";
import * as quotes          from "./quotes";
import * as orders          from "./orders";
import * as purchase_orders from "./purchase_orders";

export default {
  ...auth,
  ...users,
  ...contacts,
  ...parts,
  ...companies,
  ...manufacturers,
  ...quotes,
  ...orders,
  ...purchase_orders,
};
