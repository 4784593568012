import {
  faChevronDown,
  faChevronUp,
  faGlobe,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon }             from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Link }                        from "react-router-dom";
import styled                          from "styled-components";
import { QuoteContext }                from "~/contexts/QuoteContext";

export const QuoteDetails = () => {
  return (
    <QuoteDetailsContainer>
      <QuoteContact />
      <QuoteOrders />
      <QuoteProducts />
      <QuoteAuthor />
    </QuoteDetailsContainer>
  );
};

const DetailSection = ({ children, label = "" }) => {
  const [open, setOpen] = useState(true);
  return (
    <QuoteDetailsSection>
      <div
        className={`detail-header  ${open ? "open" : ""}`}
        onClick={() => setOpen(!open)}
      >
        <div className="row">
          <div className="col">{label}</div>
          <div className="col text-right">
            {open ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </div>
        </div>
      </div>
      {open && <>{children}</>}
    </QuoteDetailsSection>
  );
};

const QuoteContact = () => {
  const { quote } = useContext(QuoteContext);
  const { attributes } = quote || {};

  const { title, full_name, company, email, alt_phone, phone, id } =
    attributes?.contact || {};

  return (
    <DetailSection label="Contact">
      <div className="inner">
        <div className="text-bold">
          <Link className="link" to={`/contacts/${id}`}>
            {full_name}
          </Link>
        </div>
        <div className="fs-12 mb-1">
          <div>{title}</div>
          <div className="text-bold">{company?.name}</div>
        </div>
        <div className="fs-12">
          <div>{email}</div>
          <div>{phone}</div>
          <div>{alt_phone}</div>
        </div>
      </div>
    </DetailSection>
  );
};

const QuoteProducts = () => {
  const { quote } = useContext(QuoteContext);
  const { attributes } = quote || {};

  const { quote_items } = attributes || {};

  console.log(quote_items);

  return (
    <DetailSection label="Parts">
      {quote_items?.map((item) => (
        <div className="part" key={item?.id}>
          <Link to={`/parts/${item?.product?.id}`}>
            {item?.product?.part_number}
          </Link>
          {item?.product?.url && (
            <a className="float-right" target="blank" href={item?.product?.url}>
              <FontAwesomeIcon icon={faGlobe} />
            </a>
          )}
        </div>
      ))}
    </DetailSection>
  );
};

const QuoteOrders = () => {
  const { quote } = useContext(QuoteContext);
  const { attributes } = quote || {};
  const { orders } = attributes || {};

  if (!orders?.length) return null;

  return (
    <DetailSection className="fs-12" label="Orders">
      {orders?.map((order) => (
        <div key={order?.id}>
          <div className="py-2 px-3 row">
            <div className="col">
              <Link to={`/orders/${order?.id}`} className="link text-secondary">
                {order?.order_no}
              </Link>
            </div>
            <div className="col text-right">
              <span className="badge badge-light badge-sm text-bold">
                {order?.status_label}
              </span>
            </div>
          </div>
        </div>
      ))}
    </DetailSection>
  );
};

const QuoteAuthor = () => {
  const { quote } = useContext(QuoteContext);
  return <div></div>;
};

const QuoteDetailsContainer = styled.div`
  border-top: 1px solid #ddd;
  position: sticky;
  top: 0.5rem;
  @media print {
    display: none;
  }
`;

const QuoteDetailsSection = styled.div`
  border: 1px solid #ddd;
  border-top: none;
  background: white;

  .inner {
    padding: 8px 12px;
  }

  .detail-header {
    padding: 8px 12px;
    background: white;
    background-color: #fafafa;

    font-weight: 500;
  }

  .detail-header.open {
    border-bottom: 1px solid #ddd;
  }

  .part {
    padding: 8px 12px;
    border-bottom: 1px solid #ddd;
  }

  .part:last-child {
    border-bottom: none;
  }
`;
