import React, { useState }               from "react";
import { AsYouType, isValidPhoneNumber } from "libphonenumber-js";

export const PhoneInput = ({ defaultValue, name = "phone", onChange }) => {
  const [val, setValue] = useState(defaultValue);
  return (
    <input
      type="text"
      name="name"
      className="form-control"
      value={val}
      onChange={({ currentTarget: { value } }) => {
        const isValid = isValidPhoneNumber(value, "US");
        const formatted = new AsYouType("US").input(value);
        setValue(formatted);
        onChange && onChange({ [name]: formatted });
      }}
    />
  );
};
