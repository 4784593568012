import React                      from "react";
import { StyleSheet, View, Text } from "react-native";
import { Screen }                 from "~/components/layouts/Screen/index.web";
import { OrdersTable }            from "~/components/orders/OrdersTable/index.web";

export const OrdersScreen = ({ style = {} }) => {
  return (
    <Screen fluid noPadding>
      <View style={[styles.container]}>
        <OrdersTable />
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
});
