import React, { useContext }    from "react";

import { Badge, Col, Dropdown } from "react-bootstrap";
import { useNavigate, Link }    from "react-router-dom";
import { FontAwesomeIcon }      from "@fortawesome/react-fontawesome";
import { faSignOut }            from "@fortawesome/pro-regular-svg-icons";
import { useNavTitle }          from "~/hooks/useNavTitle";
import { UserContext }          from "~/contexts/UserContext";
import { ROOT_NAV }             from "~/constants/navigation";
import logo                     from "~/images/logo.png";


export const Header = () => {
  const { title, path } = useNavTitle();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <div className="header app-header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse ">
            <div className="logo-container">
              <Link to="/" className="brand-logo">
                <img className="logo" src={logo} alt="" />
              </Link>
            </div>

            <ul className="header-left">
              {ROOT_NAV.map((item, x) => {
                const match = location?.pathname.includes(item.path);
                return (
                  <li
                    key={x}
                    className={`mr-4 text-sm nav-item ${match ? "active" : ""}`}
                  >
                    <Link to={item.path} aria-expanded="false">
                      <span className="nav-text">{item.label}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
            <Col className="flex-align-right">
              <Dropdown>
                <Dropdown.Toggle className="text-black" variant="" size="sm">
                  {user.full_name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => navigate("/logout")}
                    className="text-bold"
                    style={{ fontSize: 14, fontWeight: "500", color: "black" }}
                  >
                    <FontAwesomeIcon icon={faSignOut} className="mr-2" />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </div>
        </nav>
      </div>
    </div>
  );
};
