import React, { useContext } from "react";
import { OrdersList }        from "~/components/orders/OrdersList/index.web";
import { OrdersTable }       from "~/components/orders/OrdersTable/index.web";
import { ContactContext }    from "~/contexts/ContactContext";
import { OrdersProvider }    from "~/contexts/OrdersContext";

export function ContactOrdersTab() {
  const { contact } = useContext(ContactContext);
  return (
    <OrdersProvider>
      <div className="contact-orders-tab py-3">
        <OrdersTable contact_id={contact?.id} />
      </div>
    </OrdersProvider>
  );
}
