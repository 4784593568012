import React, { useCallback, useContext, useState } from "react";
import { Col, Row }                                 from "react-bootstrap";
import { faClose }                                  from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon }                          from "@fortawesome/react-fontawesome";
import ModalHeader                                  from "react-bootstrap/esm/ModalHeader";
import { SideModal }                                from "~/components/modals/SideModal/index.web";
import { dayjs }                                    from "~/helpers/day";
import { SaveFooterBar }                            from "~/components/bars/SaveFooterBar/index.web";
import { OrderContext }                             from "~/contexts/OrderContext";
import { toast }                                    from "react-toastify";
import { useLocation }                              from "react-router-dom";

const SHIPMENT_DEFAULTS = {
  id: "",
  priority: "",
  shipped_at: dayjs().format("YYYY-MM-DD"),
  shipping_cost: 0,
  tracking_number: "",
};

export const ShipmentModal = ({ onClose, editMode = true }) => {
  const { state } = useLocation();
  const [shipment, setShipment] = useState(
    state?.shipment || SHIPMENT_DEFAULTS
  );
  const { createShipment } = useContext(OrderContext);

  const onChange = (data) => {
    setShipment({
      ...shipment,
      ...data,
    });
  };

  const onSubmit = async () => {
    let error = null;
    if (!shipment?.items?.length)
      error = "No items have been added any to this shipment.";
    // if (!shipment?.tracking_number) error = "Tracking Number is required.";
    if (!shipment?.priority) error = "Priority is required.";
    if (!shipment?.shipped_at) error = "Shipping date is required.";

    if (error) {
      toast.error(error);
      return;
    }
    const res = await createShipment(shipment);

    if (!res.error) {
      onClose && onClose();
    }
  };

  return (
    <SideModal open={true} width={500} onToggle={onClose}>
      <div className="h-100 scroll-y  pb-5">
        <ShipmentModalHeader onClose={onClose} />
        <ShipmentForm
          data={shipment}
          onChange={onChange}
          disabled={!editMode}
        />
        <SaveFooterBar fixed={false} visible={editMode} onSubmit={onSubmit} />
      </div>
    </SideModal>
  );
};

const ShipmentModalHeader = ({ onClose }) => {
  return (
    <ModalHeader className="bg-primary border-radius-0 px-3">
      <div className="text-white mb-0 ">Shipment</div>
      <FontAwesomeIcon
        icon={faClose}
        size="lg"
        color="white"
        className="c-pointer"
        onClick={onClose}
      />
    </ModalHeader>
  );
};

const ShipmentForm = ({ data = {}, onChange, disabled = false }) => {
  const onInputChangeHandler = ({ currentTarget: { name, value } }) => {
    onChange &&
      onChange({
        [name]: value,
      });
  };

  const onItemChangeHander = (items) => {
    onChange &&
      onChange({
        items,
      });
  };

  return (
    <div className="px-3 py-2">
      <div className="py-2">
        <label className="fs-12 text-dark text-bold">Ship Date</label>
        <input
          className="form-control"
          name="shipped_at"
          type="date"
          disabled={disabled}
          value={data?.shipped_at}
          onChange={onInputChangeHandler}
        />
      </div>
      <div className="py-2">
        <label className="fs-12 text-dark text-bold">Priority</label>
        <ShipmentPriorityInput
          value={data?.priority}
          onChange={onInputChangeHandler}
          disabled={disabled}
        />
      </div>
      <div className="py-2">
        <label className="fs-12 text-dark text-bold">Tracking Number</label>
        <input
          className="form-control"
          name="tracking_number"
          type="text"
          disabled={disabled}
          value={data?.tracking_number}
          onChange={onInputChangeHandler}
        />
      </div>
      <div className="py-2">
        <label className="fs-12 text-dark text-bold">Shipping Cost</label>
        <input
          className="form-control"
          name="shipping_cost"
          type="number"
          disabled={disabled}
          value={data?.shipping_cost}
          onChange={onInputChangeHandler}
        />
      </div>
      <div className="py-2">
        <div className="row">
          <div className="col col-8">
            <label className="fs-12 text-dark text-bold">
              Shipment Manifest
            </label>
          </div>
          <div className="col">
            <label className="fs-12 text-dark text-bold">Quantity</label>
          </div>
        </div>
        {disabled ? (
          <ShipmentItems data={data?.items} onChange={onChange} />
        ) : (
          <OrderItems onChange={onChange} />
        )}
      </div>
    </div>
  );
};

const ShipmentPriorityInput = ({ onChange, value, disabled = false }) => {
  return (
    <select
      name="priority"
      className="form-control"
      onChange={onChange}
      value={value}
      disabled={disabled}
    >
      <option>Select a priority</option>
      <option value="dhl_standard">DHL Standard</option>
      <option value="dhl_priority">DHL Priority</option>
      <option value="fedex_ground">FedEx Ground</option>
      <option value="fedex_second_day">FedEx 2nd Day</option>
      <option value="fedex_standard_overnight">FedEx Standard Overnight</option>
      <option value="fedex_priority_overnight">FedEx Priority Overnight</option>
      <option value="fedex_intl_saver">FedEx Intl Saver</option>
      <option value="fedex_intl_priority">FedEx Intl Priority</option>
      <option value="ups_ground">UPS Ground</option>
      <option value="ups_second_day">UPS 2nd Day</option>
      <option value="ups_standard_overnight">UPS Standard Overnight</option>
      <option value="ups_early_am_overnight">UPS Early AM Overnight</option>
      <option value="will_call">Will Call</option>
    </select>
  );
};

const OrderItems = ({ onChange, disabled = false }) => {
  const { order } = useContext(OrderContext);

  const [items, setItems] = useState(
    order?.attributes?.order_items.map((item) => {
      return {
        order_item_id: item?.id,
        quantity: 0,
        order_item: item,
      };
    }) || []
  );

  const updateQuantity = useCallback(
    (order_item) =>
      ({ currentTarget: { value: quantity } }) => {
        const qty = Math.min(parseInt(quantity || 0), order_item.quantity);

        const updated = items?.map((item) => {
          if (order_item.id == item?.order_item_id) {
            return {
              ...item,
              quantity: qty,
            };
          } else {
            return item;
          }
        });

        setItems(updated);

        onChange &&
          onChange({
            items: updated.map(({ order_item_id, quantity }) => ({
              order_item_id,
              quantity,
            })),
          });
      },
    [items, setItems, onChange]
  );

  return (
    <div>
      {items?.map((item) => {
        return (
          <div className="py-2" key={item?.order_item_id}>
            <div className="row">
              <div className="col py-1 col-8">
                <small className="d-block text-bold">
                  {item?.order_item?.display_name ||
                    item?.order_item?.product?.part_number}
                </small>
                <small className="text-gray">{`Order Qty: ${item?.order_item?.quantity}`}</small>
              </div>
              <div className="col">
                <input
                  className="form-control"
                  value={`${item.quantity}`}
                  disabled={disabled}
                  type="number"
                  min={0}
                  max={item?.order_item?.quantity}
                  onChange={updateQuantity(item?.order_item)}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ShipmentItems = ({ data = [] }) => {
  return (
    <div>
      {data?.map((item) => {
        console.log(item);
        return (
          <div className="py-2" key={item?.order_item_id}>
            <div className="row">
              <div className="col py-1 col-8">
                <small className="d-block text-bold">
                  {item?.order_item?.display_name ||
                    item?.order_item?.product?.part_number}
                </small>
                <small className="text-gray">{`Order Qty: ${item?.order_item?.quantity}`}</small>
              </div>
              <div className="col">
                <input
                  className="form-control"
                  defaultValue={`${item.quantity}`}
                  disabled
                  type="number"
                  min={0}
                  max={item?.order_item?.quantity}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
