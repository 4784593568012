import React, { createContext, useState, useCallback, useEffect } from "react";
import { ProcessCRM }                                             from "~/services";

export const QuotesContext = createContext({});

export const QuotesProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [quotes, setQuotes] = useState(false);

  const [pagination, setPagination] = useState({
    per_page: 50,
    page: 1,
    total: 0,
    sort: "updated_at",
    order: "desc",
  });

  const getQuotes = useCallback(
    async (query) => {
      if (loading) return;
      setLoading(true);
      const res = await ProcessCRM.getQuotes({
        ...pagination,
        ...query,
      });
      if (res?.data) {
        setQuotes(res?.data);
        setPagination({
          total: res.total_count,
          per_page: res.per_page,
          page: res.page,
          sort: res.sort,
          order: res.order,
        });
      } else {
        setError(res.error);
      }
      setLoading(false);
      return res;
    },
    [loading, pagination, setError, setPagination, setQuotes, setLoading]
  );

  return (
    <QuotesContext.Provider
      value={{
        error,
        ...pagination,
        loading,
        getQuotes,
        quotes,
      }}
    >
      {children}
    </QuotesContext.Provider>
  );
};
