import React                  from "react";
import { Route, Routes }      from "react-router-dom";
import { ContactsEditScreen } from "~/screens/ContactsEditScreen/index.web";
import { ContactsScreen }     from "~/screens/ContactsScreen/index.web";
import { ContactsProvider }   from "~/contexts/ContactsContext";
import { ContactCreateModal } from "~/components/contacts/ContactCreateModal/index.web";

export const ContactsNavigator = () => {
  return (
    <ContactsProvider>
      <Routes>
        <Route index element={<ContactsScreen />} />
        <Route path="/:contact_id" element={<ContactsEditScreen />} />
      </Routes>
    </ContactsProvider>
  );
};
