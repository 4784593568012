export const responseHandler = (res) => {
  try {
    return res.data;
  } catch (e) {
    return {
      error: e,
      message: "Invalid JSON Response",
    };
  }
};

export const errorHandler = (res) => {
  if(res?.response?.status){
    return Promise.resolve({
      status: res.response.status,
      ...res?.response?.data,
    });
  }else{
    return Promise.reject(res)
  }
};
