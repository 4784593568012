import axios                             from "../axios";
import { responseHandler, errorHandler } from "../../utils/handlers";

import { QUOTES_URI }                    from "../../constants/uri";
import { PAGINATION }                    from "../../constants/query";
import { buildParams }                   from "../../utils/params";

export async function getQuotes(params) {
  const {
    page = 1,
    per_page = 50,
    sort = "created_at",
    order = "desc",
    filter = "",
    contact_id = "",
    product_id = "",
  } = params || PAGINATION;

  const res = await axios
    .request(
      `${QUOTES_URI}?${buildParams({
        page,
        per_page,
        sort,
        order,
        contact_id,
        product_id,
        filter,
      })}`,
      {
        method: "GET",
      }
    )
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function getQuote(id) {
  const res = await axios
    .request(`${QUOTES_URI}/${id}`, {
      method: "GET",
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function createQuote(data) {
  const res = await axios
    .request(`${QUOTES_URI}`, {
      method: "POST",
      data: { quote: data },
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function createQuoteForContact(contact_id) {
  const res = await axios
    .request(`${QUOTES_URI}`, {
      method: "POST",
      data: { contact_id },
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function updateQuote(id, data) {
  const res = await axios
    .request(`${QUOTES_URI}/${id}`, {
      method: "PUT",
      data: { quote: data },
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function deleteQuote(id) {
  const res = await axios
    .request(`${QUOTES_URI}/${id}`, {
      method: "DELETE",
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function addQuoteItem(id, data) {
  const res = await axios
    .request(`${QUOTES_URI}/${id}/items`, {
      method: "POST",
      data: { item: data },
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function updateQuoteItem(id, data) {
  const res = await axios
    .request(`${QUOTES_URI}/${id}/items/${data?.id}`, {
      method: "PUT",
      data,
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}

export async function deleteQuoteItem(id, item_id) {
  const res = await axios
    .request(`${QUOTES_URI}/${id}/items/${item_id}`, {
      method: "DELETE",
    })
    .then(responseHandler)
    .catch(errorHandler);
  return res;
}
